var _ = require('underscore');
module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<p class="CDB-Text CDB-Size-large is-semibold u-upperCase">'+
((__t=( letter ))==null?'':_.escape(__t))+
' <svg width="12px" height="12px" viewBox="589 382 12 12" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">\n  <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd" transform="translate(589.000000, 382.000000)">\n    <circle fill="#FFFFFF" cx="2" cy="2" r="2"></circle>\n    <circle fill="#FFFFFF" cx="2" cy="10" r="2"></circle>\n    <circle fill="#FFFFFF" cx="10" cy="10" r="2"></circle>\n  </g>\n</svg></p>';
}
return __p;
};
