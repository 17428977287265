var Backbone = require('backbone');

/*
 *  Infobox item model
 *
 */

module.exports = Backbone.Model.extend({
  defaults: {
    selected: false
  }
});
