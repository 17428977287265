var _ = require('underscore');
module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='';
 if (val === 'order') { 
__p+='\n  <div class="u-flex u-justifySpace CDB-ListDecoration-itemLink js-order">\n    <p class="CDB-Text CDB-Size-medium">'+
((__t=( name ))==null?'':_.escape(__t))+
'</p>\n    <ul class="u-flex">\n      <li class="js-asc">\n        <i class="CDB-Text CDB-IconFont\n          CDB-IconFont-arrowNext u-actionTextColor\n          Table-columnSorted\n          Table-columnSorted--asc\n           ';
 if (isOrderBy && sortBy === 'asc') { 
__p+='is-disabled';
 } 
__p+='\n           ';
 if (isOrderBy && sortBy === 'desc') { 
__p+='is-semibold';
 } 
__p+='\n        "></i>\n      </li>\n      <li class="js-desc u-lSpace--xl">\n        <i class="CDB-Text CDB-IconFont\n          CDB-IconFont-arrowNext u-actionTextColor\n          Table-columnSorted\n          Table-columnSorted--desc\n           ';
 if (isOrderBy && sortBy === 'desc') { 
__p+='is-disabled';
 } 
__p+='\n           ';
 if (isOrderBy && sortBy === 'asc') { 
__p+='is-semibold';
 } 
__p+='\n        "></i>\n      </li>\n    </ul>\n  </div>\n';
 } else if (val === 'change') { 
__p+='\n  <div class="CDB-ListDecoration-itemLink u-actionTextColor u-flex u-justifySpace u-alignCenter" title="'+
((__t=( name ))==null?'':_.escape(__t))+
'">\n    <span>'+
((__t=( name ))==null?'':_.escape(__t))+
'</span>\n    <i class="CDB-Text CDB-Size-small is-semibold CDB-IconFont CDB-IconFont-rArrowLight"></i>\n  </div>\n';
 } else { 
__p+='\n  <div class="CDB-ListDecoration-itemLink\n    ';
 if (isDestructive) { 
__p+='  u-errorTextColor ';
 } else { 
__p+=' u-actionTextColor ';
 } 
__p+='\n    ';
 if (val === 'change') { 
__p+=' u-flex ';
 } 
__p+='\n    " title="'+
((__t=( name ))==null?'':_.escape(__t))+
'">\n    '+
((__t=( name ))==null?'':_.escape(__t))+
'\n    ';
 if (val === 'change') { 
__p+='\n      <span>\n        <i class="CDB-Text CDB-Size-small is-semibold CDB-IconFont CDB-IconFont-rArrowLight"></i>\n      </span>\n    ';
 } 
__p+='\n  </div>\n';
 } 
__p+='\n';
}
return __p;
};
