var _ = require('underscore');
module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<div class="CDB-Box-modalHeader">\n  <ul class="CDB-Box-modalHeaderItem CDB-Box-modalHeaderItem--block CDB-Box-modalHeaderItem--paddingHorizontal">\n    <li class="CDB-ListDecoration-item CDB-ListDecoration-itemPadding--vertical">\n      <button class="u-actionTextColor js-back CDB-Size-medium u-rSpace">\n        <i class="CDB-IconFont CDB-IconFont-arrowPrev"></i>\n      </button>\n      <span class="CDB-Text CDB-Size-medium">\n        '+
((__t=( _t('components.modals.publish.privacy.password.title') ))==null?'':_.escape(__t))+
'\n      </span>\n    </li>\n  </ul>\n</div>\n<ul class="CustomList-list js-customList">\n  <li class="Privacy-passwordField CustomList-item">\n    <input type="password" class="CDB-Text CDB-InputText js-input" placeholder="'+
((__t=( _t('components.modals.publish.privacy.password.placeholder') ))==null?'':_.escape(__t))+
'">\n  </li>\n</ul>\n';
}
return __p;
};
