var _ = require('underscore');
module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<li class="Modal-listActionsitem">\n  <button class="CDB-Button CDB-Button--secondary CDB-Button--big js-close">\n    <span class="CDB-Button-Text CDB-Text is-semibold CDB-Size-medium u-upperCase">\n      '+
((__t=( _t('components.modals.maps-metadata.cancel-btn') ))==null?'':_.escape(__t))+
'\n    </span>\n  </button>\n</li>\n<li class="Modal-listActionsitem">\n  <button class="CDB-Button CDB-Button--primary CDB-Button--big js-save '+
((__t=( canFinish ? '' : 'is-disabled' ))==null?'':_.escape(__t))+
'">\n    <span class="CDB-Button-Text CDB-Text is-semibold CDB-Size-medium u-upperCase ">\n      '+
((__t=( _t('components.modals.maps-metadata.save-btn') ))==null?'':_.escape(__t))+
'\n    </span>\n  </button>\n</li>\n\n';
}
return __p;
};
