var _ = require('underscore');
module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='';
 if (state !== 'list' ) { 
__p+='\n  <h3 class="CDB-Text CDB-Size-large u-mainTextColor u-secondaryTextColor u-bSpace--m">\n    ';
 if (state === 'selected') { 
__p+='\n      ';
 if (service_name === 'instagram') { 
__p+='\n        '+
((__t=( _t('components.modals.add-layer.imports.service-import.account-connected') ))==null?'':_.escape(__t))+
'\n        ';
 } else { 
__p+='\n        '+
((__t=( _t('components.modals.add-layer.imports.service-import.item-selected') ))==null?'':_.escape(__t))+
'\n      ';
 } 
__p+='\n    ';
 } else { 
__p+='\n      '+
((__t=( _t('components.modals.add-layer.imports.service-import.connect-with', { title: title }) ))==null?'':_.escape(__t))+
'\n    ';
 } 
__p+='\n  </h3>\n  <p class="CDB-Text CDB-Size-medium u-altTextColor ';
 if (state === "error") { 
__p+='ImportPanel-headerDescription--negative';
 } 
__p+='">\n    ';
 if (state === "idle") { 
__p+='\n      ';
 if (fileExtensions.length > 0) { 
__p+='\n        ';
 formatsLink = _t('components.modals.add-layer.imports.service-import.and') + ' <a target="_blank" href="https://docs.carto.com/cartodb-editor/datasets/#supported-file-formats" class="ImportPanel-headerDescriptionLink">' + _t('components.modals.add-layer.imports.service-import.many-more-formats') + '</a>' 
__p+='\n        '+
((__t=( fileExtensions.join(', ') ))==null?'':_.escape(__t))+
'';
 if (showAvailableFormats) { 
__p+=' '+
((__t=( formatsLink ))==null?'':_.escape(__t))+
' ';
 } 
__p+=' '+
((__t=( _t('components.modals.add-layer.imports.service-import.supported') ))==null?'':_.escape(__t))+
'.\n      ';
 } else { 
__p+='\n        '+
((__t=( _t('components.modals.add-layer.imports.service-import.state-idle-login') ))==null?'':_.escape(__t))+
'\n      ';
 } 
__p+='\n    ';
 } 
__p+='\n    ';
 if (state === "error") { 
__p+='\n      '+
((__t=( _t('components.modals.add-layer.imports.service-import.state-error') ))==null?'':_.escape(__t))+
'\n    ';
 } 
__p+='\n    ';
 if (state === "token") { 
__p+='\n      '+
((__t=( _t('components.modals.add-layer.imports.service-import.state-token') ))==null?'':_.escape(__t))+
'\n    ';
 } 
__p+='\n    ';
 if (state === "oauth") { 
__p+='\n      '+
((__t=( _t('components.modals.add-layer.imports.service-import.state-oauth') ))==null?'':_.escape(__t))+
'\n    ';
 } 
__p+='\n    ';
 if (state === "retrieving") { 
__p+='\n      '+
((__t=( _t('components.modals.add-layer.imports.service-import.state-retrieving', { title: title }) ))==null?'':_.escape(__t))+
'\n    ';
 } 
__p+='\n    ';
 if (state === "selected") { 
__p+='\n      ';
 if (acceptSync) { 
__p+='\n        '+
((__t=( _t('components.modals.add-layer.imports.service-import.state-selected-sync') ))==null?'':_.escape(__t))+
'\n      ';
 } else { 
__p+='\n        ';
 if (service_name === 'instagram') { 
__p+='\n          '+
((__t=( _t('components.modals.add-layer.imports.service-import.state-selected-instagram') ))==null?'':_.escape(__t))+
'\n        ';
 } else { 
__p+='\n          '+
((__t=( _t('components.modals.add-layer.imports.service-import.state-selected-no-sync') ))==null?'':_.escape(__t))+
'\n        ';
 } 
__p+='\n      ';
 } 
__p+='\n    ';
 } 
__p+='\n  </p>\n  ';
 if (state === "selected" && items > 1) { 
__p+='\n    <button class="CDB-Size-large ImportPanel-headerButton js-back">\n      <i class="CDB-IconFont CDB-IconFont-arrowPrev u-actionTextColor"></i>\n    </button>\n  ';
 } 
__p+='\n';
 } 
__p+='\n';
}
return __p;
};
