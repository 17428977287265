var _ = require('underscore');
module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<div class="CustomList-message">\n  <p class="CustomList-messageText CDB-Text CDB-Size-medium u-secondaryTextColor">\n    ';
 if (query && query.length) { 
__p+='\n      '+
((__t=( _t('components.custom-list.no-results', { typeLabel: typeLabel, query: query }) ))==null?'':_.escape(__t))+
'\n    ';
 } else { 
__p+='\n      '+
((__t=( _t('components.custom-list.no-items', { typeLabel: typeLabel }) ))==null?'':_.escape(__t))+
'\n    ';
 } 
__p+='\n  </p>\n</div>\n';
}
return __p;
};
