var _ = require('underscore');
module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<div class="CDB-ListDecoration-item CustomList-item js-listItem">\n  <button type="button" class="CDB-ListDecoration-itemLink CustomList-itemLink js-add-custom-value u-ellipsis" data-val="'+
((__t=( query ))==null?'':_.escape(__t))+
'" title="'+
((__t=( query ))==null?'':_.escape(__t))+
'">\n    <p class="CDB-Text CDB-FontSize-small u-altTextColor">'+
((__t=( _t('components.custom-list.add-custom-result') ))==null?'':_.escape(__t))+
'</p>\n    “'+
((__t=( query ))==null?'':_.escape(__t))+
'”\n  </button>\n</div>\n';
}
return __p;
};
