var _ = require('underscore');
module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<div class="CreateDialog-footerShadow"></div>\n<div class="CreateDialog-footerLine"></div>\n<div class="CreateDialog-footerInner u-flex u-justifySpace u-alignCenter">\n  <div class="js-footer-info CreateDialog-footerInfo u-secondaryTextColor">\n  </div>\n  <div class="CreateDialog-footerActions js-footerActions">\n    <button class="js-ok CDB-Button CDB-Button--primary '+
((__t=( canFinish ? '' : 'is-disabled' ))==null?'':_.escape(__t))+
'">\n      <span class="CDB-Button-Text CDB-Text is-semibold CDB-Size-small u-upperCase">'+
((__t=( _t('components.modals.add-layer.footer.add-layer') ))==null?'':_.escape(__t))+
'</span>\n    </button>\n  </div>\n</div>\n';
}
return __p;
};
