var _ = require('underscore');
module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<button class="Mosaic-inner">\n  '+
((__t=( template ))==null?'':__t)+
'\n</button>\n';
}
return __p;
};
