var _ = require('underscore');
module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<div class="Editor-HeaderInfoEditor">\n  <div class="u-rSpace--xl u-actionTextColor js-back Editor-HeaderInfoEditorShape">\n    <button>\n      <i class="CDB-IconFont CDB-IconFont-arrowPrev Size-large"></i>\n    </button>\n  </div>\n\n  <div class="Editor-HeaderInfo-inner u-ellipsis">\n    <div class="Editor-HeaderInfo-title u-bSpace">\n      <h2 class="Inline-editor">\n        <div class="CDB-Text CDB-Size-huge is-light u-ellipsis">'+
((__t=( _t('editor.edit-feature.edit', { featureType: featureType }) ))==null?'':_.escape(__t))+
'</div>\n      </h2>\n    </div>\n    <div class="u-flex u-ellipsis">\n      <p class="Editor-headerLayerName CDB-Text CDB-Size-medium u-ellipsis">\n        <a href="'+
((__t=( url ))==null?'':_.escape(__t))+
'" target="_blank" title="'+
((__t=( tableName ))==null?'':_.escape(__t))+
'" class="Editor-headerLayerName">'+
((__t=( tableName ))==null?'':_.escape(__t))+
'</a>\n      </p>\n    </div>\n  </div>\n  <div class="u-flex u-tSpace-xl js-context-menu"></div>\n</div>\n';
}
return __p;
};
