var _ = require('underscore');
module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<ul>\n  ';
 for(var i in items) { 
__p+='\n    <li class="Legend-categoryListItem u-flex u-justifySpace u-alignCenter">\n      <p class="Legend-categoryTitle CDB-Text CDB-Size-small u-upperCase u-ellipsis" title="'+
((__t=( items[i].title ))==null?'':__t)+
'">'+
((__t=( items[i].title ))==null?'':__t)+
'</p>\n      ';
 if (items[i].color) { 
__p+='\n        <span class="Legend-categoryCircle" style="opacity:1; background: '+
((__t=( items[i].color ))==null?'':__t)+
';"></span>\n      ';
 } else if (items[i].icon) { 
__p+='\n        <span class="Legend-categoryIcon js-image-container" data-icon="'+
((__t=( items[i].icon ))==null?'':__t)+
'" ';
 if (items[i].color) { 
__p+='data-color="'+
((__t=( items[i].color ))==null?'':__t)+
'"';
 } 
__p+='></span>\n      ';
 } 
__p+='\n    </li>\n  ';
 } 
__p+='\n</ul>\n';
}
return __p;
};
