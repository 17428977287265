var _ = require('underscore');
module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<div class="Dialog-header Dialog-header--expanded CreateDialog-header with-separator">\n  <div class="Dialog-headerIcon Dialog-headerIcon--neutral">\n    <i class="CDB-IconFont CDB-IconFont-add"></i>\n  </div>\n  <h2 class="CDB-Text CDB-Size-large u-mainTextColor u-bSpace">'+
((__t=( _t('components.modals.add-layer.modal-title') ))==null?'':_.escape(__t))+
'</h2>\n  <h3 class="CDB-Text CDB-Size-medium u-altTextColor">'+
((__t=( _t('components.modals.add-layer.modal-desc') ))==null?'':_.escape(__t))+
'</h3>\n</div>\n<div class="Filters Filters--navListing Filters--static js-navigation"></div>\n<div class="js-content-container Dialog-body Dialog-body--expanded Dialog-body--create Dialog-body--noPaddingTop Dialog-body--withoutBorder"></div>\n<div class="Dialog-footer Dialog-footer--expanded CreateDialog-footer js-footer"></div>\n';
}
return __p;
};
