var _ = require('underscore');
module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<td class="Table-cellItem" data-attribute="'+
((__t=( columnName ))==null?'':_.escape(__t))+
'" title="'+
((__t=( value ))==null?'':_.escape(__t))+
'" data-clipboard-text=\''+
((__t=( value ))==null?'':_.escape(__t))+
'\'>\n  <div class="\n      Table-cell u-flex u-justifySpace\n      '+
((__t=( columnName === 'cartodb_id' || (type === 'geometry' && geometry !== 'point') ? 'Table-cell--short' : '' ))==null?'':_.escape(__t))+
'\n    ">\n    <p class="\n      CDB-Text CDB-Size-medium\n      u-ellipsis u-rSpace--xl\n      '+
((__t=( type === 'number' && columnName !== 'cartodb_id' ? 'is-number' : '' ))==null?'':_.escape(__t))+
'\n      '+
((__t=( value === null ? 'is-null' : '' ))==null?'':_.escape(__t))+
'\n      '+
((__t=( columnName === 'cartodb_id' ? 'is-cartodbId' : '' ))==null?'':_.escape(__t))+
'\n      js-value\n    ">\n      '+
((__t=( value === null ? 'null' : formattedValue ))==null?'':_.escape(__t))+
'\n    </p>\n\n    ';
 if (columnName !== 'cartodb_id') { 
__p+='\n      <button class="CDB-Shape-threePoints is-blue is-small js-options">\n        <div class="CDB-Shape-threePointsItem"></div>\n        <div class="CDB-Shape-threePointsItem"></div>\n        <div class="CDB-Shape-threePointsItem"></div>\n      </button>\n    ';
 } 
__p+='\n  </div>\n</td>\n';
}
return __p;
};
