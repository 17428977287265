var _ = require('underscore');
module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='';
 if (!escapeOptionsDisabled) { 
__p+='\n  <button class="CDB-Shape js-close Dialog-closeBtn">\n    <div class="CDB-Shape-close is-blue is-huge"></div>\n  </button>\n';
 } 
__p+='\n<div class="Dialog-contentWrapper js-content"></div>\n';
}
return __p;
};
