var _ = require('underscore');
module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<div class="Card-icon u-bSpace--xl js-icon"></div>\n\n<div class="Card-body u-bSpace--xl">\n  ';
 if (isPrivate) { 
__p+='\n    <h3 class="CDB-Text CDB-Size-large u-altTextColor u-bSpace--m">'+
((__t=( title ))==null?'':_.escape(__t))+
'</h3>\n  ';
 } else { 
__p+='\n    <h3 class="CDB-Text CDB-Size-large u-mainTextColor u-bSpace--m">'+
((__t=( title ))==null?'':_.escape(__t))+
'</h3>\n  ';
 } 
__p+='\n  <div class="CDB-Text CDB-Size-medium u-altTextColor">\n    '+
((__t=( body ))==null?'':__t)+
'\n    <br/>\n    ';
 if (url && !isPrivate) { 
__p+='\n    <a href="'+
((__t=( url ))==null?'':_.escape(__t))+
'" class="Share-link js-link">'+
((__t=( link ))==null?'':_.escape(__t))+
'</a>\n    ';
 } 
__p+='\n  </div>\n</div>\n\n';
 if (!isPrivate && isPublished) { 
__p+='\n  <div class="Share-input">\n    <input type="text" id="'+
((__t=( id ))==null?'':_.escape(__t))+
'" value="'+
((__t=( content ))==null?'':_.escape(__t))+
'" class="Share-input-field CDB-InputText is-disabled CDB-Text CDB-Size-medium u-ellipsis js-input" readonly>\n    <button class="Share-copy CDB-Button CDB-Button--small js-copy" data-clipboard-target="#'+
((__t=( id ))==null?'':_.escape(__t))+
'">\n      <span class="CDB-Button-Text CDB-Text CDB-Size-small u-actionTextColor">'+
((__t=( copy ))==null?'':_.escape(__t))+
'</span>\n    </button>\n  </div>\n';
 } 
__p+='\n';
}
return __p;
};
