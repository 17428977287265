var _ = require('underscore');
module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<div class="Editor-boxModalContent">\n  <div class="ColorPicker-pickerWrapper js-colorPicker"></div>\n  <div class="ColorPicker-inputs">\n    <div class="ColorPicker-inputWrapper">\n      <input type="text" class="CDB-InputText ColorPicker-input is-color js-hex" value="'+
((__t=( hex ))==null?'':_.escape(__t))+
'"/>\n      <span class="ColorPicker-inputLabel u-upperCase">HEX</span>\n    </div>\n    <div class="ColorPicker-inputWrapper">\n      <input type="text" class="CDB-InputText ColorPicker-input js-inputColor js-r" value="'+
((__t=( r ))==null?'':_.escape(__t))+
'"/>\n      <span class="ColorPicker-inputLabel u-upperCase">R</span>\n    </div>\n    <div class="ColorPicker-inputWrapper">\n      <input type="text" class="CDB-InputText ColorPicker-input js-inputColor js-g" value="'+
((__t=( g ))==null?'':_.escape(__t))+
'" />\n      <span class="ColorPicker-inputLabel u-upperCase">G</span>\n    </div>\n    <div class="ColorPicker-inputWrapper">\n      <input type="text" class="CDB-InputText ColorPicker-input js-inputColor js-b" value="'+
((__t=( b ))==null?'':_.escape(__t))+
'" />\n      <span class="ColorPicker-inputLabel u-upperCase">B</span>\n    </div>\n    <div class="ColorPicker-inputWrapper">\n      <input type="text" class="CDB-InputText ColorPicker-input js-a';
 if (opacityDisabled) { 
__p+=' is-disabled';
 } 
__p+='" value="'+
((__t=( opacity ))==null?'':_.escape(__t))+
'" ';
 if (opacityDisabled) { 
__p+='disabled';
 } 
__p+=' />\n      <span class="ColorPicker-inputLabel u-upperCase">A</span>\n    </div>\n  </div>\n</div>\n';
}
return __p;
};
