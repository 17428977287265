var _ = require('underscore');
module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<ul class="u-flex u-alignCenter Editor-contextSwitcher js-mapTableView\n  ';
 if (isThereOtherWidgets) { 
__p+='is-moved';
 } 
__p+='\n  ';
 if (isThereTimeSeries) { 
__p+='has-timeSeries ';
 if (isThereAnimatedTimeSeries) { 
__p+='has-timeSeries--animated';
 } 
__p+='';
 } 
__p+='\n  ">\n  <li class="Editor-contextSwitcherItem">\n    <div class="Editor-contextSwitcherButton js-showTable">\n      <svg width="11px" height="10px" viewBox="505 436 11 10" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">\n        <g id="Group" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd" transform="translate(505.000000, 436.000000)">\n          <path d="M3,0 L3,10 L0,10 L0,0 L3,0 Z M1,1 L1,9 L2,9 L2,1 L1,1 Z" id="Combined-Shape" class="Editor-contextSwitcherMedia" ></path>\n          <path d="M7,0 L7,10 L4,10 L4,0 L7,0 Z M5,1 L5,9 L6,9 L6,1 L5,1 Z" id="Combined-Shape-Copy" class="Editor-contextSwitcherMedia" ></path>\n          <path d="M11,0 L11,10 L8,10 L8,0 L11,0 Z M9,1 L9,9 L10,9 L10,1 L9,1 Z" id="Combined-Shape-Copy-2" class="Editor-contextSwitcherMedia" ></path>\n        </g>\n      </svg>\n    </div>\n  </li>\n  <li class="Editor-contextSwitcherItem">\n    <div class="Editor-contextSwitcherButton js-showMap is-selected">\n      <svg width="9px" height="12px" viewBox="538 435 9 12" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">\n        <path d="M547,438.913043 C547,441.07513 542.5,447 542.5,447 C542.5,447 538,441.07513 538,438.913043 C538,436.752 540.0142,435 542.5,435 C544.9852,435 547,436.752 547,438.913043 Z M543.908614,443.22687 C544.16716,442.824347 544.408524,442.43401 544.629457,442.059548 C545.498835,440.586034 546,439.426635 546,438.913043 C546,437.337768 544.461567,436 542.5,436 C540.538058,436 539,437.337565 539,438.913043 C539,439.426635 539.501165,440.586034 540.370543,442.059548 C540.591476,442.43401 540.83284,442.824347 541.091386,443.22687 C541.539951,443.925227 542.019537,444.628807 542.501367,445.306148 C542.537557,445.357022 543.426358,443.97768 543.908614,443.22687 Z" id="Combined-Shape" stroke="none" class="Editor-contextSwitcherMedia" fill-rule="evenodd"></path>\n      </svg>\n    </div>\n  </li>\n</ul>\n\n';
 if (!isReadOnly) { 
__p+='\n  <div class="EditOverlay js-editOverlay is-hidden"><p class="EditOverlay-inner CDB-Text CDB-Size-medium u-whiteTextColor js-editOverlay-text"></p></div>\n\n  <ul class="u-flex u-alignRight Editor-contextSwitcher Editor-contextSwitcher--geom js-mapTableView js-newGeometryView\n  ';
 if (isThereOtherWidgets) { 
__p+='is-moved';
 } 
__p+='\n  ';
 if (isThereTimeSeries) { 
__p+='has-timeSeries ';
 if (isThereAnimatedTimeSeries) { 
__p+='has-timeSeries--animated';
 } 
__p+='';
 } 
__p+='\n  ">\n    ';
 if (queryGeometryModel === 'point' || !queryGeometryModel) { 
__p+='\n      <li class="Editor-contextSwitcherItem js-newGeometryItem ';
 if (!isVisible) { 
__p+='is-disabled';
 } 
__p+='">\n        <div class="Editor-contextSwitcherButton js-newGeometry" data-feature-type=\'point\'>\n          <svg width="12" height="12" viewBox="0 0 12 12" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">\n            <defs>\n              <path d="M0 2h3v1H0V2zm0-2h3v1H0V0zm2 1h1v1H2V1zM0 1h1v1H0V1z" id="a"/>\n              <path d="M0 2h3v1H0V2zm0-2h3v1H0V0zm2 1h1v1H2V1zM0 1h1v1H0V1z" id="c"/>\n              <path d="M0 2h3v1H0V2zm0-2h3v1H0V0zm2 1h1v1H2V1zM0 1h1v1H0V1z" id="e"/>\n            </defs>\n            <g fill="none" fill-rule="evenodd">\n              <path fill="#FFF" d="M4 3h3v1H4v3H3V4H0V3h3V0h1"/>\n              <g transform="rotate(180 2.5 6)">\n                <mask id="b" fill="#fff">\n                  <use xlink:href="#a"/>\n                </mask>\n                <path d="M0 2h3v1H0V2zm0-2h3v1H0V0zm2 1h1v1H2V1zM0 1h1v1H0V1z" mask="url(#b)" stroke="#FFF" stroke-width="2"/>\n              </g>\n              <g transform="rotate(180 6 6)">\n                <mask id="d" fill="#fff">\n                  <use xlink:href="#c"/>\n                </mask>\n                <path d="M0 2h3v1H0V2zm0-2h3v1H0V0zm2 1h1v1H2V1zM0 1h1v1H0V1z" mask="url(#d)" stroke="#FFF" stroke-width="2"/>\n              </g>\n              <g transform="rotate(180 6 2.5)">\n                <mask id="f" fill="#fff">\n                  <use xlink:href="#e"/>\n                </mask>\n                <path d="M0 2h3v1H0V2zm0-2h3v1H0V0zm2 1h1v1H2V1zM0 1h1v1H0V1z" mask="url(#f)" stroke="#FFF" stroke-width="2"/>\n              </g>\n            </g>\n          </svg>\n        </div>\n      </li>\n    ';
 } 
__p+='\n    ';
 if (queryGeometryModel === 'line' || !queryGeometryModel) { 
__p+='\n      <li class="Editor-contextSwitcherItem js-newGeometryItem ';
 if (!isVisible) { 
__p+='is-disabled';
 } 
__p+='">\n        <div class="Editor-contextSwitcherButton js-newGeometry" data-feature-type=\'line\'>\n          <svg width="14" height="14" viewBox="0 0 14 14" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">\n            <defs>\n              <path d="M0 2h3v1H0V2zm0-2h3v1H0V0zm2 1h1v1H2V1zM0 1h1v1H0V1z" id="a"/>\n              <path d="M0 2h3v1H0V2zm0-2h3v1H0V0zm2 1h1v1H2V1zM0 1h1v1H0V1z" id="c"/>\n            </defs>\n            <g fill="none" fill-rule="evenodd">\n              <path fill="#FFF" d="M11.5 5l-.707-.707-6.354 6.853.705.708"/>\n              <g transform="translate(11 2)">\n                <mask id="b" fill="#fff">\n                  <use xlink:href="#a"/>\n                </mask>\n                <path d="M0 2h3v1H0V2zm0-2h3v1H0V0zm2 1h1v1H2V1zM0 1h1v1H0V1z" mask="url(#b)" stroke="#FFF" stroke-width="2"/>\n              </g>\n              <g transform="translate(2 11)">\n                <mask id="d" fill="#fff">\n                  <use xlink:href="#c"/>\n                </mask>\n                <path d="M0 2h3v1H0V2zm0-2h3v1H0V0zm2 1h1v1H2V1zM0 1h1v1H0V1z" mask="url(#d)" stroke="#FFF" stroke-width="2"/>\n              </g>\n              <path fill="#FFF" d="M3 4H0V3h3V0h1v3h3v1H4v3H3"/>\n            </g>\n          </svg>\n        </div>\n      </li>\n    ';
 } 
__p+='\n    ';
 if (queryGeometryModel === 'polygon' || !queryGeometryModel) { 
__p+='\n      <li class="Editor-contextSwitcherItem js-newGeometryItem ';
 if (!isVisible) { 
__p+='is-disabled';
 } 
__p+='">\n        <div class="Editor-contextSwitcherButton js-newGeometry" data-feature-type=\'polygon\'>\n          <svg width="14" height="14" viewBox="0 0 14 14" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">\n            <defs>\n              <path d="M0 2h3v1H0V2zm0-2h3v1H0V0zm2 1h1v1H2V1zM0 1h1v1H0V1z" id="a"/>\n              <path d="M0 2h3v1H0V2zm0-2h3v1H0V0zm2 1h1v1H2V1zM0 1h1v1H0V1z" id="c"/>\n              <path d="M0 2h3v1H0V2zm0-2h3v1H0V0zm2 1h1v1H2V1zM0 1h1v1H0V1z" id="e"/>\n            </defs>\n            <g fill="none" fill-rule="evenodd">\n              <path d="M13 11.5V5h-1v7h1m-.5-7.5l-.707-.707-7.647 7.353-.353.354.707.707.354-.353M4 3h3v1H4v3H3V4H0V3h3V0h1" fill="#FFF"/>\n              <path fill="#FFF" d="M4.5 13H12v-1H4v1"/>\n              <g transform="rotate(180 7 7)">\n                <mask id="b" fill="#fff">\n                  <use xlink:href="#a"/>\n                </mask>\n                <path d="M0 2h3v1H0V2zm0-2h3v1H0V0zm2 1h1v1H2V1zM0 1h1v1H0V1z" stroke="#FFF" stroke-width="2" mask="url(#b)"/>\n              </g>\n              <g transform="rotate(180 7 2.5)">\n                <mask id="d" fill="#fff">\n                  <use xlink:href="#c"/>\n                </mask>\n                <path d="M0 2h3v1H0V2zm0-2h3v1H0V0zm2 1h1v1H2V1zM0 1h1v1H0V1z" stroke="#FFF" stroke-width="2" mask="url(#d)"/>\n              </g>\n              <g transform="rotate(180 2.5 7)">\n                <mask id="f" fill="#fff">\n                  <use xlink:href="#e"/>\n                </mask>\n                <path d="M0 2h3v1H0V2zm0-2h3v1H0V0zm2 1h1v1H2V1zM0 1h1v1H0V1z" stroke="#FFF" stroke-width="2" mask="url(#f)"/>\n              </g>\n            </g>\n          </svg>\n        </div>\n      </li>\n    ';
 } 
__p+='\n  </ul>\n';
 } 
__p+='\n';
}
return __p;
};
