var _ = require('underscore');
module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<div class="CDB-Box-modalHeaderItem">\n  <input type="text" name="text" autocomplete="off" placeholder="'+
((__t=( _t('components.custom-list.placeholder', { typeLabel: typeLabel }) ))==null?'':_.escape(__t))+
'" class="CDB-InputTextPlain CDB-Text js-search">\n  <button type="button" style="display:none" class="u-lSpace--xl js-clear">\n    <div class="CDB-Shape">\n      <div class="CDB-Shape-close is-blue is-large"></div>\n    </div>\n  </button>\n</div>\n';
}
return __p;
};
