var _ = require('underscore');
module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<p class="CDB-Text Onboarding-headerDescription">\n  '+
((__t=( _t('analyses-onboarding.new-column-included') ))==null?'':_.escape(__t))+
'\n</p>\n\n<ul class="Onboarding-list">\n  ';
 if (aggregate_function === 'count') { 
__p+='\n  <li class="Onboarding-listItem">\n    <div class="Onboarding-listItemValue">count_vals</div>\n  </li>\n  <li class="Onboarding-listItem">\n    <div class="Onboarding-listItemValue">count_vals_density</div>\n  </li>\n  ';
 } else {
__p+='\n  <li class="Onboarding-listItem">\n    <div class="Onboarding-listItemValue">'+
((__t=( aggregate_function ))==null?'':_.escape(__t))+
'_'+
((__t=( aggregate_column ))==null?'':_.escape(__t))+
'</div>\n  </li>\n  ';
 } 
__p+='\n</ul>\n\n<p class="CDB-Text Onboarding-description">\n  '+
((__t=( _t('analyses-onboarding.aggregate-intersection.description') ))==null?'':_.escape(__t))+
'\n</p>\n';
}
return __p;
};
