var _ = require('underscore');
module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<div class="IntermediateInfo">\n  <div class="LayoutIcon ';
 (q || tag) ? 'LayoutIcon--negative' : '' 
__p+='">\n    <i class="CDB-IconFont\n      ';
 if (shared) { 
__p+=' CDB-IconFont-defaultUser\n      ';
 } else if (locked) { 
__p+=' CDB-IconFont-lock\n      ';
 } else if (library) { 
__p+=' CDB-IconFont-dribbble\n      ';
 } else { 
__p+=' CDB-IconFont-lens ';
 } 
__p+='" />\n  </div>\n  <h4 class="CDB-Text CDB-Size-large u-mainTextColor u-bSpace u-secondaryTextColor u-tSpace-xl">\n    ';
 if (page > 1 || totalItems === 0 && totalEntries > 0) { 
__p+='\n      '+
((__t=( _t('components.modals.add-layer.datasets.no-results.desc') ))==null?'':_.escape(__t))+
'\n    ';
 } 
__p+='\n\n    ';
 if (( tag || q ) && totalItems === 0 && totalEntries === 0) { 
__p+='\n      0 '+
((__t=( tag || q ))==null?'':_.escape(__t))+
' '+
((__t=( type ))==null?'':_.escape(__t))+
' '+
((__t=( _t('components.modals.add-layer.datasets.no-results.found') ))==null?'':_.escape(__t))+
'\n    ';
 } 
__p+='\n\n    ';
 if (page === 1 && !tag && !q && totalItems === 0 && totalEntries === 0) { 
__p+='\n      '+
((__t=( _t('components.modals.add-layer.datasets.no-results.there-are-no') ))==null?'':_.escape(__t))+
' '+
((__t=( shared === "only" ? 'shared' : '' ))==null?'':_.escape(__t))+
' '+
((__t=( locked ? 'locked' : '' ))==null?'':_.escape(__t))+
' '+
((__t=( library ? 'library' : '' ))==null?'':_.escape(__t))+
' '+
((__t=( type ))==null?'':_.escape(__t))+
'\n    ';
 } 
__p+='\n  </h4>\n  <p class="CDB-Text CDB-Size-medium u-altTextColor">\n    ';
 if (!tag && !q && totalItems === 0 && totalEntries === 0) { 
__p+='\n      '+
((__t=( _t('components.modals.add-layer.datasets.no-results.no-fun', { type: type }) ))==null?'':_.escape(__t))+
'\n    ';
 } 
__p+='\n  </p>\n</div>\n';
}
return __p;
};
