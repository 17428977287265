var _ = require('underscore');
module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<p class="CDB-Text Onboarding-description">\n  '+
((__t=( _t('analyses-onboarding.filter-by-node-column.description', {
    name_of_source_node: source.get('id').toUpperCase(),
    name_of_filter_source_node: filter_source.get('id').toUpperCase()
  }) ))==null?'':_.escape(__t))+
'\n</p>\n';
}
return __p;
};
