var _ = require('underscore');
module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<div class="List-rowItem">\n  <div class="DefaultTitle '+
((__t=( canSave ? '': 'is-disabled' ))==null?'':_.escape(__t))+
'">'+
((__t=( model.get('title') || model.get('name') ))==null?'':_.escape(__t))+
'</div>\n  <button class="js-add Button Button--secondary Button--secondaryTransparentBkg '+
((__t=( canSave ? '' : 'is-disabled' ))==null?'':_.escape(__t))+
'">\n    <span>Add this</span>\n  </button>\n</div>\n';
}
return __p;
};
