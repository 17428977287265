var _ = require('underscore');
module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<h2 class="CDB-Text CDB-Size-huge is-light u-secondaryTextColor">'+
((__t=( _t('editor.layers.analysis-form.placeholder-text') ))==null?'':_.escape(__t))+
'</h2>\n<div class="BlockList-item is-dashed no-hover is-space u-tSpace-xl">\n  <ul class="HorizontalBlockList">\n    <li class="HorizontalBlockList-item"></li>\n    <li class="HorizontalBlockList-item"></li>\n  </ul>\n\n  <button class="CDB-Button CDB-Button--primary js-add-analysis" data-layer-id="'+
((__t=( layerId ))==null?'':_.escape(__t))+
'">\n    <span class="CDB-Button-Text CDB-Text is-semibold CDB-Size-medium u-upperCase">'+
((__t=( _t('editor.layers.layer.add-analysis') ))==null?'':_.escape(__t))+
'</span>\n  </button>\n</div>\n';
}
return __p;
};
