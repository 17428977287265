var _ = require('underscore');
module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<button class="CDB-Attribution-button js-edit-feature\n  ';
 if (!isEditable) { 
__p+='is-disabled';
 } 
__p+='\n  ';
 if (hasAnalyses) { 
__p+='t-hasAnalyses';
 } 
__p+='\n  ';
 if (isCustomQueryApplied) { 
__p+='t-isCustomQueryApplied';
 } 
__p+='\n  ';
 if (isReadOnly) { 
__p+='t-isReadOnly';
 } 
__p+='" data-tooltip="'+
((__t=( disabled ))==null?'':_.escape(__t))+
'">\n  <svg width="32" height="32" viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg"><path d="M10 22.91l1.217-3.65 8.924-8.924c.448-.448 1.177-.448 1.624 0l.81.81c.448.448.448 1.175 0 1.623l-8.923 8.924L10 22.91zm10.952-8.518l-2.434-2.434 2.434 2.434zm-7.3 7.302l-2.435-2.434 2.434 2.434z" stroke="#636D72" fill="none" fill-rule="evenodd"/></svg>\n</button>\n';
}
return __p;
};
