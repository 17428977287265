var _ = require('underscore');
module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<nav class="CDB-NavMenu js-theme">\n  <ul class="CDB-NavMenu-inner CDB-Text is-semibold CDB-Size-medium js-menu"></ul>\n  <button class="Editor-buttonNavigation CDB-Button CDB-Button--small CDB-Button--primary js-add">\n    <span class="CDB-Button-Text CDB-Text is-semibold CDB-Size-small u-upperCase">\n      '+
((__t=( _t('editor.button_add') ))==null?'':_.escape(__t))+
'\n    </span>\n  </button>\n</nav>\n<div class="Editor-content js-content"></div>\n';
}
return __p;
};
