var _ = require('underscore');
module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<div class="Modal-header">\n  <div class="Modal-headerContainer">\n    <div class="CDB-HeaderInfo">\n      <button class="u-rSpace--xl CDB-HeaderInfo-back u-actionTextColor js-back">\n        <i class="CDB-IconFont CDB-IconFont-arrowPrev Size-large"></i>\n      </button>\n      <div class="CDB-HeaderInfo-inner">\n        <h2 class="CDB-Text CDB-Size-huge is-light u-bSpace">'+
((__t=( _t('components.modals.add-analysis.modal-title') ))==null?'':_.escape(__t))+
'</h2>\n        <h3 class="CDB-Text CDB-Size-medium u-altTextColor">'+
((__t=( _t('components.modals.add-analysis.modal-desc') ))==null?'':_.escape(__t))+
'</h3>\n      </div>\n    </div>\n  </div>\n</div>\n\n<div class="Modal-inner Modal-inner--with-navigation js-content">\n  <div class="Dialog-content Dialog-content--expanded">\n    <div class="ScrollView">\n      <div class="ScrollView-content">\n        <div class="Modal-analysisContainer">\n          <ul class="CDB-NavMenu-inner CDB-Text is-semibold CDB-Size-small SubmenuModal u-flex u-alignCenter">\n            <li class="CDB-NavMenu-item SubmenuModal-item u-flex u-alignCenter">\n              <button class="js-back u-mainTextColor u-upperCase">'+
((__t=( _t('analysis-category.all') ))==null?'':_.escape(__t))+
'</button>\n            </li>\n            <li class="CDB-NavMenu-item SubmenuModal-item u-flex u-alignCenter">\n              <button class="js-backToCategory u-mainTextColor u-upperCase">'+
((__t=( _t('analysis-category.' + category) ))==null?'':_.escape(__t))+
'</button>\n            </li>\n            <li class="CDB-NavMenu-item u-upperCase SubmenuModal-item u-flex u-alignCenter">\n              '+
((__t=( title ))==null?'':_.escape(__t))+
'\n            </li>\n          </ul>\n          <div class="Modal-inner u-justifyCenter">\n            <div class="Analysis-moreInfo">\n              <div class="Analysis-animation ';
 if (genericType) { 
__p+='is-'+
((__t=( genericType ))==null?'':_.escape(__t))+
'';
 } 
__p+=' has-autoplay js-animation is-rounded"></div>\n              <h2 class="CDB-Text CDB-Size-huge is-light Analysis-moreInfoTitle">'+
((__t=( title ))==null?'':_.escape(__t))+
'</h2>\n              <p class="CDB-Text CDB-Size-medium u-secondaryTextColor">\n                '+
((__t=( _t('analyses-onboarding.' + genericType + '.description', analysisParams) ))==null?'':_.escape(__t))+
'\n              </p>\n            </div>\n          </div>\n        </div>\n      </div>\n    </div>\n  </div>\n</div>\n<div class="Modal-footer">\n  <div class="Modal-footerContainer u-flex u-justifyEnd">\n    <button class="CDB-Button CDB-Button--primary js-add">\n      <span class="CDB-Button-Text CDB-Text is-semibold CDB-Size-medium u-upperCase">'+
((__t=( _t('components.modals.add-analysis.add-btn') ))==null?'':_.escape(__t))+
'</span>\n    </button>\n  </div>\n</div>\n\n';
}
return __p;
};
