var _ = require('underscore');
module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<div class="IntermediateInfo">\n  <div class="CDB-LoaderIcon CDB-LoaderIcon--big is-dark js-loader">\n    <svg class="CDB-LoaderIcon-spinner" viewbox="0 0 50 50">\n      <circle class="CDB-LoaderIcon-path" cx="25" cy="25" r="20" fill="none"/>\n    </svg>\n  </div>\n  <h4 class="CDB-Text CDB-Size-large u-mainTextColor u-bSpace u-secondaryTextColor u-tSpace-xl">\n    '+
((__t=( _t('components.modals.add-layer.datasets.' + (q || tag  ? 'searching' : 'loading')) ))==null?'':_.escape(__t))+
'...\n  </h4>\n  <div class="CDB-Text CDB-Size-medium u-altTextColor">'+
((__t=( quote ))==null?'':__t)+
'</div>\n</div>\n';
}
return __p;
};
