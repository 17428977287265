var _ = require('underscore');
module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<button class="DatePicker-dates js-dates has-icon CDB-Text CDB-Size-medium">\n  '+
((__t=( _t('components.datepicker.from') ))==null?'':_.escape(__t))+
' <strong>'+
((__t=( fromDate ))==null?'':_.escape(__t))+
' '+
((__t=( pad(fromHour,2) ))==null?'':_.escape(__t))+
':'+
((__t=( pad(fromMin,2) ))==null?'':_.escape(__t))+
'</strong> '+
((__t=( _t('components.datepicker.to') ))==null?'':_.escape(__t))+
' <strong>'+
((__t=( toDate ))==null?'':_.escape(__t))+
' '+
((__t=( pad(toHour,2) ))==null?'':_.escape(__t))+
':'+
((__t=( pad(toMin,2) ))==null?'':_.escape(__t))+
'</strong>\n  <i class="CDB-IconFont CDB-IconFont-calendar DatePicker-datesIcon"></i>\n</button>\n<div class="DatePicker-dropdown CDB-Text">\n  <div class="DatePicker-calendar"></div>\n  <div class="DatePicker-timers js-timers"></div>\n  <div class="DatePicker-shortcuts">\n    <p class="DatePicker-shortcutsText">\n      '+
((__t=( _t('components.datepicker.get-last') ))==null?'':_.escape(__t))+
' <button type="button" class="Button--link js-fourHours">'+
((__t=( _t('components.datepicker.hours-pluralize', { smart_count: 4 }) ))==null?'':_.escape(__t))+
'</button>,\n      <button type="button" class="Button--link js-oneDay">'+
((__t=( _t('components.datepicker.days-pluralize', { smart_count: 1 }) ))==null?'':_.escape(__t))+
'</button> '+
((__t=( _t('components.datepicker.or') ))==null?'':_.escape(__t))+
'\n      <button type="button" class="Button--link js-oneWeek">'+
((__t=( _t('components.datepicker.weeks-pluralize', { smart_count: 1 }) ))==null?'':_.escape(__t))+
'</button>\n    </p>\n    <p class="DatePicker-shortcutsText">'+
((__t=( _t('components.datepicker.gmt-convertion') ))==null?'':_.escape(__t))+
'</p>\n  </div>\n</div>\n';
}
return __p;
};
