var _ = require('underscore');
module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='';
 if (canUpgrade) { 
__p+='\n  <a href="'+
((__t=( upgradeUrl ))==null?'':_.escape(__t))+
'" class="PrivacyToggler js-toggler PrivacyToggler--'+
((__t=( privacy ))==null?'':_.escape(__t))+
' is-disabled"\n    data-title="'+
((__t=( _t('components.modals.add-layer.footer.privacy-change-not-allowed') ))==null?'':_.escape(__t))+
'">\n    <i class="CDB-IconFont CDB-IconFont-'+
((__t=( icon ))==null?'':_.escape(__t))+
'"></i>\n  </a>\n';
 } else {
__p+='\n  <button type="button" class="PrivacyToggler js-toggler PrivacyToggler--'+
((__t=( privacy ))==null?'':_.escape(__t))+
' '+
((__t=( isDisabled ? 'is-disabled' : '' ))==null?'':_.escape(__t))+
'"\n    data-title="\n      ';
 if (!isDisabled) { 
__p+='\n        '+
((__t=( _t('components.modals.add-layer.footer.privacy-change', { privacy: privacy.toLowerCase() }) ))==null?'':_.escape(__t))+
'.<br />'+
((__t=( _t('components.modals.add-layer.footer.privacy-click', { nextPrivacy: nextPrivacy.toLowerCase() }) ))==null?'':_.escape(__t))+
'.\n      ';
 } else { 
__p+='\n        '+
((__t=( _t('components.modals.add-layer.footer.privacy-change-banned') ))==null?'':_.escape(__t))+
'\n      ';
 } 
__p+='\n    ">\n    <i class="CDB-IconFont CDB-IconFont-'+
((__t=( icon ))==null?'':_.escape(__t))+
'"></i>\n  </button>\n';
 } 
__p+='\n';
}
return __p;
};
