var _ = require('underscore');
module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<div class="WidgetList-option">\n  <input class="CDB-Radio u-iBlock js-radio" type="radio" ';
 if (isSelected) { 
__p+='checked="checked"';
 } 
__p+=' />\n  <span class="u-iBlock CDB-Radio-face"></span>\n</div>\n\n<div class="WidgetList-inner js-inner">\n  <h3 class="u-ellipsis CDB-Text CDB-Size-large u-bSpace--m">'+
((__t=( columnName ))==null?'':_.escape(__t))+
'</h3>\n</div>\n';
}
return __p;
};
