var _ = require('underscore');
module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<div class="u-flex u-alignCenter u-justifySpace">\n  <div class="u-flex u-alignCenter CDB-Text CDB-Size-small u-altTextColor SyncInfo-message--'+
((__t=( state ))==null?'':_.escape(__t))+
' js-state">\n    <i class="CDB-IconFont CDB-IconFont-wifi"></i>\n    <p class="u-upperCase is-semibold u-lSpace">\n      ';
 if (errorCode || errorMessage) { 
__p+='\n        '+
((__t=( _t('dataset.sync.sync-failed') ))==null?'':_.escape(__t))+
'\n      ';
 } else { 
__p+='\n        '+
((__t=( ranAt ))==null?'':_.escape(__t))+
'\n      ';
 } 
__p+='\n    </p>\n  </div>\n  ';
 if (isOwner) { 
__p+='\n    <button class="CDB-Text CDB-Size-small u-upperCase js-options SyncInfo-viewOptions">'+
((__t=( _t('dataset.sync.view-options') ))==null?'':_.escape(__t))+
'</button>\n  ';
 } 
__p+='\n</div>\n';
}
return __p;
};
