var _ = require('underscore');
module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<button class="u-lSpace--xl CDB-Button CDB-Button--primary CDB-Button--small js-apply">\n  <span class="CDB-Text is-semibold CDB-Size-small">'+
((__t=( _t("editor.infowindow.apply") ))==null?'':_.escape(__t))+
'</span>\n</button>\n';
}
return __p;
};
