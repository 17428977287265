var _ = require('underscore');
module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<p class="CDB-Text CDB-Size-large is-semibold u-upperCase">'+
((__t=( letter ))==null?'':_.escape(__t))+
' <svg width="12px" height="12px" viewBox="0 0 12 12" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">\n  <path d="M8.30379432,3.06016671 C8.11128812,2.75281724 8,2.38940498 8,2 C8,0.8954305 8.8954305,0 10,0 C11.1045695,0 12,0.8954305 12,2 C12,3.1045695 11.1045695,4 10,4 C9.70653076,4 9.42782451,3.93679216 9.17675238,3.82324762 L3.82324762,9.17675238 C3.93679216,9.42782451 4,9.70653076 4,10 C4,11.1045695 3.1045695,12 2,12 C0.8954305,12 0,11.1045695 0,10 C0,8.8954305 0.8954305,8 2,8 C2.38940498,8 2.75281724,8.11128812 3.06016671,8.30379432 L8.30379432,3.06016671 Z" stroke="none" fill="#FFFFFF" fill-rule="evenodd"></path>\n</svg></p>';
}
return __p;
};
