var _ = require('underscore');
module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<form>\n  <div class="Editor-HeaderInfo">\n    <div class="Editor-HeaderNumeration CDB-Text is-semibold u-rSpace--m">2</div>\n    <div class="Editor-HeaderInfo-inner CDB-Text" data-fields="source">\n      <div class="Editor-HeaderInfo-title u-bSpace--m">\n        <h2 class="CDB-Text CDB-HeaderInfo-titleText CDB-Size-large">'+
((__t=( _t('analyses.kmeans.title') ))==null?'':_.escape(__t))+
'</h2>\n      </div>\n      <p class="CDB-Text u-upperCase CDB-FontSize-small u-altTextColor u-bSpace--m">'+
((__t=( _t('editor.layers.analysis-form.input-layer') ))==null?'':_.escape(__t))+
'</p>\n    </div>\n  </div>\n  <div class="Editor-HeaderInfo">\n    <div class="Editor-HeaderNumeration CDB-Text is-semibold u-rSpace--m">3</div>\n    <div class="Editor-HeaderInfo-inner CDB-Text" data-fields="clusters">\n      <div class="Editor-HeaderInfo-title u-bSpace--m">\n        <h2 class="CDB-Text CDB-HeaderInfo-titleText CDB-Size-large">'+
((__t=( _t('editor.layers.analysis-form.parameters') ))==null?'':_.escape(__t))+
'</h2>\n      </div>\n      <p class="CDB-Text u-upperCase CDB-FontSize-small u-altTextColor u-bSpace--xl">'+
((__t=( _t('editor.layers.analysis-form.tune-clusters') ))==null?'':_.escape(__t))+
'</p>\n    </div>\n  </div>\n</form>\n';
}
return __p;
};
