var _ = require('underscore');
module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<div class="DatasetsList-itemCategory is--'+
((__t=( isRaster ? 'raster' : geometryType ))==null?'':_.escape(__t))+
'Dataset">\n  ';
 if (syncStatus) { 
__p+='\n    <i\n    ';
 if (syncStatus === "failure") { 
__p+='\n      data-title="'+
((__t=( _t('components.modals.add-layer.datasets.item.sync-failed') ))==null?'':_.escape(__t))+
' '+
((__t=( syncRanAt ))==null?'':_.escape(__t))+
'"\n    ';
 } else if (syncStatus === "syncing") { 
__p+='\n      data-title="'+
((__t=( _t('components.modals.add-layer.datasets.item.syncing') ))==null?'':_.escape(__t))+
'"\n    ';
 } else { 
__p+='\n      data-title="'+
((__t=( _t('components.modals.add-layer.datasets.item.synced') ))==null?'':_.escape(__t))+
' '+
((__t=( syncRanAt ))==null?'':_.escape(__t))+
'"\n    ';
 } 
__p+='\n    class="CDB-IconFont CDB-IconFont-wifi DatasetsList-itemStatus is-'+
((__t=( syncStatus ))==null?'':_.escape(__t))+
'"></i>\n  ';
 } 
__p+='\n</div>\n<div class="ModalDataset-itemInfo">\n  <div class="ModalDataset-itemInfoTitle">\n    <h3 class="CDB-Text CDB-Size-large u-bSpace u-ellipsis">\n      '+
((__t=( title ))==null?'':_.escape(__t))+
'\n      ';
 if (showPermissionIndicator) { 
__p+='\n        <span class="Tag Tag--outline Tag-outline--grey CDB-Text CDB-Size-small u-upperCase">\n          '+
((__t=( _t('components.modals.add-layer.datasets.item.read') ))==null?'':_.escape(__t))+
'\n        </span>\n      ';
 } 
__p+='\n    </h3>\n    ';
 if (description && description.length > 0) { 
__p+='\n      <p class="u-ellipsis CDB-Text CDB-Size-medium u-altTextColor" title="'+
((__t=( description ))==null?'':_.escape(__t))+
'">'+
((__t=( description ))==null?'':_.escape(__t))+
'</p>\n    ';
 } else { 
__p+='\n      <span class="NoResults CDB-Text CDB-Size-medium">'+
((__t=( _t('components.modals.add-layer.datasets.item.no-description') ))==null?'':_.escape(__t))+
'</span>\n    ';
 } 
__p+='\n  </div>\n  <div>\n    <div class="DatasetsList-itemMeta">\n\n      <span class="CDB-Tag is-'+
((__t=( privacy ))==null?'':_.escape(__t))+
' CDB-Text is-semibold CDB-Size-small u-upperCase">\n        '+
((__t=( privacy ))==null?'':_.escape(__t))+
'\n      </span>\n      ';
 if (rowCount) { 
__p+='\n        <span class="RowsIndicator">\n          <span class="CDB-Text CDB-Size-small u-altTextColor">'+
((__t=( rowCountFormatted ))==null?'':_.escape(__t))+
' '+
((__t=( _t('components.modals.add-layer.datasets.item.rows-pluralize', { smart_count: rowCount }) ))==null?'':_.escape(__t))+
'</span>\n        </span>\n      ';
 } 
__p+='\n      ';
 if (datasetSize) { 
__p+='\n        <span class="SizeIndicator">\n          <span class="CDB-Text CDB-Size-small u-altTextColor">'+
((__t=( datasetSize ))==null?'':_.escape(__t))+
'</span>\n        </span>\n      ';
 } 
__p+='\n      <span class="DatasetsList-itemTimeDiff DefaultTimeDiff">\n        <span class="CDB-Text CDB-Size-small u-altTextColor">'+
((__t=( timeDiff ))==null?'':_.escape(__t))+
'</span>\n        ';
 if (!isOwner) { 
__p+='\n          <span class="CDB-Text CDB-Size-small u-altTextColor u-lSpace--xl u-rSpace">\n            '+
((__t=( _t('components.modals.add-layer.datasets.item.by') ))==null?'':_.escape(__t))+
'\n          </span>\n          <span class="DatasetsList-avatar">\n            <img class="DatasetsList-avatarImg" src="'+
((__t=( owner.avatar_url ))==null?'':_.escape(__t))+
'" alt="'+
((__t=( owner.name || owner.username  ))==null?'':_.escape(__t))+
'" title="'+
((__t=( owner.name || owner.username  ))==null?'':_.escape(__t))+
'" />\n          </span>\n        ';
 } 
__p+='\n      </span>\n    </div>\n    <div class="DatasetsList-itemMeta DatasetsList-itemTags">\n      ';
 if (tagsCount > 0) { 
__p+='\n        <div class="DefaultTags CDB-Text CDB-Size-small">\n          ';
 for (var i = 0, l = Math.min(maxTagsToShow, tags.length); i < l; ++i) { 
__p+='\n            <button class="CDB-Text CDB-Size-small u-upperCase DefaultTags-item js-tag-link u-actionTextColor" value="'+
((__t=( tags[i] ))==null?'':_.escape(__t))+
'">'+
((__t=( tags[i] ))==null?'':_.escape(__t))+
'</button>';
 if (i !== (l-1)) { 
__p+='';
 } 
__p+='\n          ';
 } 
__p+='\n          ';
 if (tagsCount > maxTagsToShow) { 
__p+='\n            '+
((__t=( _t('components.modals.add-layer.datasets.item.tags-more', { tagsCount: tagsCount - maxTagsToShow }) ))==null?'':_.escape(__t))+
'\n          ';
 } 
__p+='\n        </div>\n      ';
 } else { 
__p+='\n        <span class="NoResults CDB-Text CDB-Size-small u-altTextColor">'+
((__t=( _t('components.modals.add-layer.datasets.item.no-tags') ))==null?'':_.escape(__t))+
'</span>\n      ';
 } 
__p+='\n    </div>\n  </div>\n</div>\n';
}
return __p;
};
