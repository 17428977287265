var _ = require('underscore');
module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<div class="CDB-InputText CDB-Text is-cursor js-button u-ellipsis\n  ';
 if (isDisabled) { 
__p+=' is-disabled ';
 } 
__p+='\n  ';
 if (!label) { 
__p+=' is-empty ';
 } 
__p+='\n  ';
 if (isNull) { 
__p+=' is-empty ';
 } 
__p+='"\n  tabindex="0">\n  ';
 if (isLoading) { 
__p+='\n    <div class="CDB-LoaderIcon CDB-LoaderIcon--small is-dark">\n      <svg class="CDB-LoaderIcon-spinner" viewBox="0 0 50 50">\n        <circle class="CDB-LoaderIcon-path" cx="25" cy="25" r="20" fill="none"></circle>\n      </svg>\n    </div>\n    <span class="u-lSpace u-secondaryTextColor">'+
((__t=( _t('components.backbone-forms.select.loading') ))==null?'':_.escape(__t))+
'</span>\n  ';
 } else { 
__p+='\n    ';
 if (isEmpty) { 
__p+='\n      '+
((__t=( _t('components.backbone-forms.select.empty') ))==null?'':_.escape(__t))+
'\n    ';
 } else { 
__p+='\n      '+
((__t=( label || _t('components.backbone-forms.select.placeholder', { keyAttr: keyAttr }) ))==null?'':_.escape(__t))+
'\n    ';
 } 
__p+='\n  ';
 } 
__p+='\n</div>\n';
}
return __p;
};
