var _ = require('underscore');
module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<form class="Form js-form">\n  <div class="Form-row Form-row--centered">\n    ';
 if (fileEnabled) { 
__p+='\n      <div class="Form-rowData Form-rowData--med Form-rowData--noMargin js-dropzone">\n        <div class="Form-upload">\n          <label class="Form-fileLabel js-fileLabel CDB-Text CDB-Size-medium">'+
((__t=( _t('components.modals.add-layer.imports.form-import.drag-and-drop') ))==null?'':_.escape(__t))+
'</label>\n          <label class="Form-fileLabel Form-fileLabel--error CDB-Text CDB-Size-small js-fileError"></label>\n          <div class="Form-file">\n            <input type="file" class="js-fileInput" />\n            <span class="CDB-Button CDB-Button--primary Form-fileButton CDB-Button-Text CDB-Text is-semibold CDB-Size-small u-upperCase js-fileButton">\n              '+
((__t=( _t('components.modals.add-layer.imports.form-import.browse') ))==null?'':_.escape(__t))+
'\n            </span>\n          </div>\n        </div>\n      </div>\n      <span class="u-lSpace--xl u-rSpace--xl u-flex u-alignCenter CDB-Text CDB-Size-medium u-altTextColor">'+
((__t=( _t('components.modals.add-layer.imports.form-import.or') ))==null?'':_.escape(__t))+
'</span>\n    ';
 } 
__p+='\n    <div class="Form-rowData Form-rowData--noMargin Form-rowData--med">\n      <input type="text" class="Form-input Form-input--med has-submit js-textInput CDB-Text CDB-Size-medium" value="" placeholder="https://carto.com/data-library" />\n      <button type="submit" class="CDB-Text CDB-Size-small Form-inputSubmit u-upperCase u-actionTextColor Form-inputSubmit">\n        <span>'+
((__t=( _t('components.modals.add-layer.imports.form-import.submit') ))==null?'':_.escape(__t))+
'</span>\n      </button>\n      <div class="Form-inputError CDB-Text">'+
((__t=( _t('components.modals.add-layer.imports.form-import.error-desc') ))==null?'':_.escape(__t))+
'</div>\n    </div>\n  </div>\n</form>\n';
}
return __p;
};
