var _ = require('underscore');
module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<img src="'+
((__t=( imgURL ))==null?'':__t)+
'" class="Mosaic-image js-thumbnailImg" />\n';
}
return __p;
};
