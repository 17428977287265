var _ = require('underscore');
module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<button type="button" class="CDB-OptionInput-content js-value">'+
((__t=( value ))==null?'':_.escape(__t))+
'</button>\n';
}
return __p;
};
