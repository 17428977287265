var _ = require('underscore');
module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<button type="button" class="CDB-ListDecoration-itemLink u-ellipsis\n    ';
 if (isSelected) { 
__p+=' is-selected ';
 } 
__p+='\n    ';
 if (isDestructive) { 
__p+='\n      u-errorTextColor\n    ';
 } else if (isDisabled) { 
__p+='\n      u-hintTextColor\n    ';
 } else { 
__p+='\n      u-actionTextColor\n    ';
 } 
__p+='\n  " title="'+
((__t=( name ))==null?'':_.escape(__t))+
'">\n  '+
((__t=( name ))==null?'':_.escape(__t))+
'\n</button>\n';
}
return __p;
};
