var _ = require('underscore');
module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<div class="Share-permissionInfo">\n  <div class="Share-permissionIcon">\n    ';
 if (avatar) { 
__p+='\n      <div class="Share-user Share-user--huge" style="background-image: url('+
((__t=( avatar ))==null?'':_.escape(__t))+
')"></div>\n    ';
 } else { 
__p+='\n        <i class="CDB-IconFont CDB-IconFont-people"></i>\n    ';
 } 
__p+='\n  </div>\n  <div>\n    <div class="CDB-Text u-mainTextColor CDB-Size-medium is-semibold u-ellipsis">\n      '+
((__t=( name ))==null?'':_.escape(__t))+
'\n    </div>\n\n\n    <div class="CDB-Text u-mainTextColor u-tSpace CDB-Size-medium u-ellipsis">\n      ';
 if (role) { 
__p+='\n        <i class="Tag Tag--outline Tag--'+
((__t=( role ))==null?'':_.escape(__t))+
' CDB-Text CDB-Size-small u-upperCase">'+
((__t=( role ))==null?'':_.escape(__t))+
'</i>\n      ';
 } 
__p+='\n\n      ';
 if (users) { 
__p+='\n        <div class="js-users"></div>\n      ';
 } 
__p+='\n    </div>\n\n\n    ';
 if (description) { 
__p+='\n    <div class="CDB-Text u-mainTextColor u-tSpace CDB-Size-medium u-ellipsis">\n      '+
((__t=( description ))==null?'':_.escape(__t))+
'\n    </div>\n    ';
 } 
__p+='\n  </div>\n</div>\n<div class="Share-togglers">\n  ';
 if (hasWriteAccessAvailable) { 
__p+='\n    <div class="CDB-Text CDB-Size-medium u-rSpace--xl Share-toggler js-toggler ';
 if (!canChangeWriteAccess) { 
__p+='is-disabled';
 } 
__p+='">\n      <input class="CDB-Toggle u-iBlock js-write" type="checkbox"\n        ';
 if (!canChangeWriteAccess) { 
__p+='disabled="disabled"';
 } 
__p+='\n        ';
 if (hasWriteAccess) { 
__p+=' checked ';
 } 
__p+='\n      />\n      <span class="u-iBlock CDB-ToggleFace"></span>\n      <label class="u-iBlock u-altTextColor ">'+
((__t=( _t('components.modals.publish.share.toggle.write') ))==null?'':_.escape(__t))+
'</label>\n    </div>\n  ';
 } 
__p+='\n  <div class="CDB-Text CDB-Size-medium Share-toggler js-toggler ';
 if (!canChangeReadAccess) { 
__p+='is-disabled';
 } 
__p+='">\n    <input class="CDB-Toggle u-iBlock js-read" type="checkbox"\n      ';
 if (hasReadAccess) { 
__p+=' checked ';
 } 
__p+='\n      ';
 if (!canChangeReadAccess) { 
__p+='disabled="disabled"';
 } 
__p+='\n    />\n    <span class="u-iBlock CDB-ToggleFace"></span>\n    <label class="u-iBlock u-altTextColor ">'+
((__t=( _t('components.modals.publish.share.toggle.read') ))==null?'':_.escape(__t))+
'</label>\n  </div>\n</div>';
}
return __p;
};
