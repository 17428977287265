var _ = require('underscore');
module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<form>\n  <div class="Editor-HeaderInfo">\n    <div class="Editor-HeaderNumeration CDB-Text is-semibold u-rSpace--m">2</div>\n    <div class="Editor-HeaderInfo-inner CDB-Text">\n      <div class="Editor-HeaderInfo-title u-bSpace--m">\n        <h2 class="CDB-Text CDB-HeaderInfo-titleText CDB-Size-large">'+
((__t=( _t('analyses.filter.title') ))==null?'':_.escape(__t))+
'</h2>\n      </div>\n      <p class="CDB-Text u-upperCase CDB-FontSize-small u-altTextColor u-bSpace--m">'+
((__t=( _t('editor.layers.analysis-form.select-column') ))==null?'':_.escape(__t))+
'</p>\n      <div class="u-tSpace-xl CDB-Text CDB-Fieldset">\n        <p class="CDB-Legend u-upperCase u-iBlock CDB-Text is-semibold CDB-Size-small u-rSpace--m">'+
((__t=( _t('editor.layers.analysis-form.input') ))==null?'':_.escape(__t))+
'</p>\n        <div class="Editor-formInput u-ellipsis" data-editors="source"></div>\n      </div>\n      <div class="u-tSpace-xl CDB-Text CDB-Fieldset">\n        <p class="CDB-Legend u-upperCase u-iBlock CDB-Text is-semibold CDB-Size-small u-rSpace--m">'+
((__t=( _t('editor.layers.analysis-form.column') ))==null?'':_.escape(__t))+
'</p>\n        <div class="Editor-formInput" data-editors="column"></div>\n      </div>\n      ';
 if (histogram_stats) { 
__p+='\n      <div class="u-tSpace-xl CDB-Text CDB-Fieldset ">\n        <p class="CDB-Legend u-upperCase u-iBlock CDB-Text is-semibold CDB-Size-small u-rSpace--m"></p>\n        <div class="Analysis-Histogram js-histogram">\n          <ul class="Analysis-HistogramInfo u-flex CDB-Text CDB-Size-small u-secondaryTextColor u-upperCase">\n            <li class="u-rSpace"><span class="js-min"></span> '+
((__t=( _t('editor.layers.analysis-form.min') ))==null?'':_.escape(__t))+
'</li>\n            <li><span class="js-max"></span> '+
((__t=( _t('editor.layers.analysis-form.max') ))==null?'':_.escape(__t))+
'</li>\n          </ul>\n          <div class="Analysis-HistogramChart js-histogramChart"></div>\n        </div>\n      </div>\n      ';
 } 
__p+='\n    </div>\n  </div>\n  ';
 if (column) { 
__p+='\n  <div class="Editor-HeaderInfo">\n    <div class="Editor-HeaderNumeration CDB-Text is-semibold u-rSpace--m">3</div>\n    <div class="Editor-HeaderInfo-inner CDB-Text" data-fields="'+
((__t=( parametersDataFields ))==null?'':_.escape(__t))+
'">\n      <div class="Editor-HeaderInfo-title u-bSpace--m">\n        <h2 class="CDB-Text CDB-HeaderInfo-titleText CDB-Size-large">'+
((__t=( _t('editor.layers.analysis-form.parameters') ))==null?'':_.escape(__t))+
'</h2>\n      </div>\n      <p class="CDB-Text u-upperCase CDB-FontSize-small u-altTextColor u-bSpace--xl">'+
((__t=( _t('editor.layers.analysis-form.parameters-description') ))==null?'':_.escape(__t))+
'</p>\n    </div>\n  </div>\n  ';
 } 
__p+='\n</form>\n';
}
return __p;
};
