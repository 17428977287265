var _ = require('underscore');
module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<div class="IntermediateInfo">\n  <div class="Dialog-header Modal-header js-header">\n    <div class="Dialog-headerIcon Dialog-headerIcon--negative">\n      <i class="CDB-IconFont CDB-IconFont-cockroach"></i>\n    </div>\n    <h2 class="CDB-Text CDB-Size-large u-bSpace u-errorTextColor">'+
((__t=( _t('components.modals.maps-metadata.error.title') ))==null?'':_.escape(__t))+
'</h2>\n    <h3 class="CDB-Text CDB-Size-medium u-secondaryTextColor">'+
((__t=( error ))==null?'':__t)+
'</h3>\n  </div>\n\n  <div class="Modal-body">\n    <div class="Modal-body-inner">\n\n      <div class="js-footer">\n        <ul class="Modal-actions is-narrow">\n          <li class="Modal-actions-button">\n            <button class="CDB-Button CDB-Button--primary js-back">\n              <span class="CDB-Button-Text CDB-Text is-semibold CDB-Size-medium u-upperCase">'+
((__t=( _t('components.modals.maps-metadata.back-btn') ))==null?'':_.escape(__t))+
'</span>\n            </button>\n          </li>\n        </ul>\n      </div>\n    </div>\n  </div>\n</div>';
}
return __p;
};
