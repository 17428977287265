var _ = require('underscore');
module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<div class="IntermediateInfo">\n  <div class="LayoutIcon">\n    <i class="CDB-IconFont CDB-IconFont-rows"></i>\n  </div>\n  <h3 class="CDB-Text CDB-Size-large u-mainTextColor u-secondaryTextColor u-bSpace--m u-tSpace-xl">\n    ';
 if (page !== 0) { 
__p+='\n      '+
((__t=( _t('components.table.rows.result.no-page-title', { page: page }) ))==null?'':_.escape(__t))+
'\n    ';
 } else { 
__p+='\n      '+
((__t=( _t('components.table.rows.result.no-results-title') ))==null?'':_.escape(__t))+
'\n    ';
 } 
__p+='\n  </h3>\n  <p class="CDB-Text CDB-Size-medium u-altTextColor">\n    ';
 if (page !== 0) { 
__p+='\n      '+
((__t=( _t('components.table.rows.result.no-page-desc', { page: page }) ))==null?'':_.escape(__t))+
'\n    ';
 } else { 
__p+='\n      '+
((__t=( _t('components.table.rows.result.no-results-desc', {
        addRow: _t('components.table.rows.result.no-results-button')
      }) ))==null?'':_.escape(__t))+
'\n    ';
 } 
__p+='\n  </p>\n</div>\n';
}
return __p;
};
