var _ = require('underscore');
module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<div class="u-flex u-justifySpace u-alignCenter">\n  <p class="CDB-Text is-semibold CDB-Size-small u-upperCase js-textInfo">\n    ';
 if (noneSelected) { 
__p+='\n      None selected\n    ';
 } else { 
__p+='\n      '+
((__t=( allSelected ? "All selected" : selectedFields + " selected" ))==null?'':_.escape(__t))+
'\n    ';
 }
__p+='\n  </p>\n \n  <button class="CDB-Text CDB-Size-small u-upperCase u-actionTextColor js-toggle">';
 if (allSelected) { 
__p+='none';
 } else { 
__p+='all';
 } 
__p+='</button>\n</div>';
}
return __p;
};
