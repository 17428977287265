var _ = require('underscore');
module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='';
 if (showCategories) { 
__p+='\n  <ul class="ColorsBar">\n    ';
 _.each(value, function (color) { 
__p+='\n      <li class="ColorBar '+
((__t=( categoryImagesPresent ? 'ColorBar--spaceSmall' : 'ColorBar--spaceMedium' ))==null?'':_.escape(__t))+
'" style="background-color: '+
((__t=( color ))==null?'':_.escape(__t))+
'"></li>\n    ';
 }); 
__p+='\n  </ul>\n';
 } else { 
__p+='\n  <button type="button" class="Editor-fillContainer">\n    <ul class="ColorBarContainer">\n      ';
 if (_.isArray(value)) { 
__p+='\n        <li class="ColorBar ColorBar-gradient" style="background: linear-gradient(90deg,'+
((__t=( value.join(',') ))==null?'':_.escape(__t))+
')"></li>\n      ';
 } else { 
__p+='\n        <li class="ColorBar" style="background-color: '+
((__t=( value ))==null?'':_.escape(__t))+
'"></li>\n      ';
 } 
__p+='\n    </ul>\n  </button>\n';
 } 
__p+='\n\n';
 if (imageURL && kind !== 'custom-marker') { 
__p+='\n<button type="button" class="Editor-fillImage">\n  <div class="js-image-container"></div>\n</button>\n';
 } 
__p+='\n\n';
 if ((!imageURL && categoryImagesPresent) || (imageURL && kind === 'custom-marker')) { 
__p+='\n<button type="button" class="Editor-fillImage">\n  <div class=\'Editor-categoryImagesTag CDB-Text CDB-FontSize-small u-altTextColor is-semibold u-upperCase\'>'+
((__t=( _t('form-components.editors.fill.input-color.img') ))==null?'':__t)+
'</div>\n</button>\n';
 } 
__p+='\n';
}
return __p;
};
