var _ = require('underscore');
module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<div class="WidgetList-option">\n  <input class="CDB-Checkbox js-checkbox" type="checkbox" ';
 if (isSelected) { 
__p+='checked="checked"';
 } 
__p+=' />\n  <span class="u-iBlock CDB-Checkbox-face"></span>\n</div>\n\n<div class="WidgetList-inner js-inner">\n  <h3 class="u-ellipsis CDB-Text CDB-Size-large u-bSpace--m">'+
((__t=( columnName ))==null?'':_.escape(__t))+
'</h3>\n  <div class="js-formulastats" style="display: none;">\n    <ul class="u-flex CDB-Text CDB-Size-small u-secondaryTextColor u-upperCase">\n      <li class=\'u-rSpace\'></li>\n    </ul>\n  	<h4 class="CDB-Text CDB-Size-huge u-bSpace--m"></h4>\n  </div>\n</div>\n';
}
return __p;
};
