var _ = require('underscore');
module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<div class="Filters is-relative">\n  <div class="Filters-inner">\n    <div class="Filters-row Share-filtersRow js-filters">\n      <div class="Filters-typeItem Filters-typeItem--searchEnabler">\n        <p class="Filters-searchLink js-search-link u-alignCenter CDB-Text CDB-Size-medium u-upperCase">\n          <i class="Filters-searchLinkIcon CDB-IconFont CDB-IconFont-lens u-rSpace--xl"></i> '+
((__t=( _t('components.pagination-search.filter.search') ))==null?'':_.escape(__t))+
'\n        </p>\n      </div>\n      <div class="Filters-typeItem Filters-typeItem--searchField">\n        <input class="Filters-searchInput CDB-Text CDB-Size-medium js-search-input" type="text" value="'+
((__t=( q ))==null?'':_.escape(__t))+
'" placeholder="'+
((__t=( _t('components.pagination-search.filter.placeholder') ))==null?'':_.escape(__t))+
'" />\n        ';
 if (q !== '') { 
__p+='\n        <button type="button" class="CDB-Shape Filters-cleanSearch js-clean-search u-actionTextColor">\n          <div class="CDB-Shape-close is-blue is-large"></div>\n        </button>\n        ';
 } 
__p+='\n      </div>\n    </div>\n  </div>\n</div>\n<div class="js-content"></div>';
}
return __p;
};
