var _ = require('underscore');
module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<p class="CDB-Text CDB-Size-large is-semibold u-upperCase">'+
((__t=( letter ))==null?'':_.escape(__t))+
'</p>';
}
return __p;
};
