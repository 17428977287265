var _ = require('underscore');
module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<li class="Editor-newLayerContainer" data-analysis-node-id="'+
((__t=( nodeId ))==null?'':_.escape(__t))+
'" style="width: 294px;">\n  <div class="Editor-ListLayer-item">\n    <div class="Editor-ListLayer-itemHeader">\n      <div class="Editor-ListLayer-media u-rSpace--m" style="background: '+
((__t=( nextBgColor ))==null?'':_.escape(__t))+
'; color: #fff">\n        <p class="CDB-Text CDB-Size-large is-semibold u-upperCase">'+
((__t=( nextLetter ))==null?'':_.escape(__t))+
'</p>\n      </div>\n      <div class="Editor-ListLayer-inner">\n        <div class="Editor-ListLayer-title">\n          <h2 class="Editor-ListLayer-titleText CDB-Text CDB-Size-large u-ellipsis">'+
((__t=( title ))==null?'':_.escape(__t))+
'</h2>\n        </div>\n        <button class="CDB-Text CDB-Size-small u-actionTextColor u-upperCase">'+
((__t=( _t('editor.layers.layer.add-analysis') ))==null?'':_.escape(__t))+
'</button>\n      </div>\n    </div>\n  </div>\n  <div class="Editor-layerInfo CDB-Text CDB-Size-small u-whiteTextColor">\n    Created new layer based on ['+
((__t=( nodeId ))==null?'':_.escape(__t))+
']\n  </div>\n</li>\n';
}
return __p;
};
