var _ = require('underscore');
module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<ul>\n  <li class="CDB-NavSubmenu-item">\n    <h2 class="CDB-Text CDB-Size-medium u-altTextColor">'+
((__t=( title ))==null?'':_.escape(__t))+
'</h2>\n  </li>\n  ';
 if (editable && assetsCount > 0) { 
__p+='\n    ';
 if (allSelected) { 
__p+='\n      <li class="CDB-NavSubmenu-item">\n        <button class="CDB-NavSubmenu-itemLink CDB-Text CDB-Size-medium u-actionTextColor js-deselect-all">'+
((__t=( _t('components.modals.assets-picker.deselect-all') ))==null?'':_.escape(__t))+
'</button>\n      </li>\n      ';
 } else if (selectedCount > 0) { 
__p+='\n      <li class="CDB-NavSubmenu-item">\n        <button class="CDB-NavSubmenu-itemLink CDB-Text CDB-Size-medium u-actionTextColor js-select-all">'+
((__t=( _t('components.modals.assets-picker.select-all') ))==null?'':_.escape(__t))+
'</button>\n      </li>\n    ';
 } 
__p+='\n\n    ';
 if (selectedCount > 0) { 
__p+='\n      <li class="CDB-NavSubmenu-item">\n          ';
 if (selectedCount > 1) { 
__p+='\n        <button class="CDB-NavSubmenu-itemLink CDB-Text CDB-Size-medium u-errorTextColor js-remove">'+
((__t=( _t('components.modals.assets-picker.delete-images') ))==null?'':_.escape(__t))+
'</button>\n        ';
 } else { 
__p+='\n        <button class="CDB-NavSubmenu-itemLink CDB-Text CDB-Size-medium u-errorTextColor js-remove">'+
((__t=( _t('components.modals.assets-picker.delete-image') ))==null?'':_.escape(__t))+
'</button>\n        ';
 } 
__p+='\n      </li>\n    ';
 } 
__p+='\n  ';
 } 
__p+='\n</ul>\n';
}
return __p;
};
