var _ = require('underscore');
module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<div class="Editor-HeaderInfo u-alignStart">\n  <button class="u-rSpace--xl u-actionTextColor js-back Editor-HeaderInfoEditorShape">\n    <i class="CDB-IconFont CDB-IconFont-arrowPrev Size-large"></i>\n  </button>\n  <div class="Editor-HeaderInfo-inner">\n    <div class="Editor-HeaderInfo-title u-bSpace">\n      <h2 class="Editor-HeaderInfo-titleText CDB-Text CDB-Size-huge is-light u-ellipsis">'+
((__t=( title ))==null?'':_.escape(__t))+
'</h2>\n    </div>\n    <p class="Editor-HeaderInfo-description CDB-Text CDB-Size-medium u-altTextColor u-iBlock">'+
((__t=( description ))==null?'':_.escape(__t))+
'</p>\n  </div>\n</div>\n';
}
return __p;
};
