var _ = require('underscore');
module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<div class="CDB-Box-modalHeader">\n  <ul class="CDB-Box-modalHeaderItem CDB-Box-modalHeaderItem--block CDB-Box-modalHeaderItem--paddingHorizontal">\n    <li class="CDB-ListDecoration-item CDB-ListDecoration-itemPadding--vertical CDB-Text CDB-Size-medium u-secondaryTextColor">\n      <ul class="u-flex u-justifySpace">\n        <li class="u-flex">\n          <button class="u-rSpace u-actionTextColor js-back">\n            <i class="CDB-IconFont CDB-IconFont-arrowPrev Size-large"></i>\n          </button>\n          <span class="label js-label">'+
((__t=( attribute ))==null?'':_.escape(__t))+
'</span>\n        </li>\n        ';
 if (columnType === 'number') { 
__p+='\n        <li class="u-flex">\n          '+
((__t=( _t('form-components.editors.fill.quantification.methods.' + quantification) ))==null?'':_.escape(__t))+
'\n          <button class="CDB-Shape u-lSpace js-quantification">\n            <div class="CDB-Shape-threePoints is-horizontal is-blue is-small">\n              <div class="CDB-Shape-threePointsItem"></div>\n              <div class="CDB-Shape-threePointsItem"></div>\n              <div class="CDB-Shape-threePointsItem"></div>\n            </div>\n          </button>\n        </li>\n        ';
 } 
__p+='\n      </ul>\n    </li>\n  </ul>\n</div>\n<div class="InputColorCategory-loader js-loader is-hidden">\n  <div class="CDB-LoaderIcon is-dark">\n    <svg class="CDB-LoaderIcon-spinner" viewBox="0 0 50 50">\n      <circle class="CDB-LoaderIcon-path" cx="25" cy="25" r="20" fill="none"></circle>\n    </svg>\n  </div>\n</div>\n\n<div class="InputColorCategory-content js-content"></div>\n';
}
return __p;
};
