var _ = require('underscore');
module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<div class="BlockList-media u-rSpace--xl js-thumbnail" style="background-color: '+
((__t=( color ))==null?'':_.escape(__t))+
'">\n</div>\n<div class="BlockList-inner">\n  <div class="BlockList-title u-bSpace">\n    <h2 class="BlockList-titleText CDB-Text CDB-Size-large u-ellipsis js-title">'+
((__t=( title ))==null?'':_.escape(__t))+
'</h2>\n  </div>\n  <p class="CDB-Text CDB-Size-medium u-secondaryTextColor">'+
((__t=( desc ))==null?'':_.escape(__t))+
'</p>\n</div>\n';
}
return __p;
};
