var _ = require('underscore');
module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<div class="ImportPanel-actions">\n  ';
 if (state === "idle") { 
__p+='\n    <button class="CDB-Button CDB-Button--primary js-connect">\n      <span class="CDB-Button-Text CDB-Text is-semibold CDB-Size-small u-upperCase">\n        '+
((__t=( _t('components.modals.add-layer.imports.service-import.connect') ))==null?'':_.escape(__t))+
'\n      </span>\n    </button>\n  ';
 } else if (state === "error") { 
__p+='\n    <button class="CDB-Button CDB-Button--primary js-connect">\n      <span class="CDB-Button-Text CDB-Text is-semibold CDB-Size-small u-upperCase">\n        '+
((__t=( _t('components.modals.add-layer.imports.service-import.try-again') ))==null?'':_.escape(__t))+
'\n      </span>\n    </button>\n  ';
 } else { 
__p+='\n    <div class="Spinner ImportPanel-actionsLoader"></div>\n  ';
 } 
__p+='\n</div>\n';
}
return __p;
};
