var _ = require('underscore');
module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<h2 class="Inline-editor">\n  <div class="CDB-Text CDB-Size-large u-ellipsis js-title Inline-editor-text">'+
((__t=( title ))==null?'':_.escape(__t))+
'</div>\n  <input type="text" name="text" class="Inline-editor-input Inline-editor-input--small CDB-Text CDB-InputText js-input" value="'+
((__t=( title ))==null?'':_.escape(__t))+
'" readonly>\n</h2>';
}
return __p;
};
