var _ = require('underscore');
module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<div class="Editor-HeaderInfo">\n  <div class="Editor-HeaderNumeration CDB-Text is-semibold u-rSpace--m">2</div>\n\n  <div class="Editor-HeaderInfo-inner CDB-Text">\n    <div class="Editor-HeaderInfo-title u-bSpace--m">\n      <h2 class="CDB-Text CDB-HeaderInfo-titleText CDB-Size-large">'+
((__t=( _t('editor.widgets.widgets-form.data.title-label') ))==null?'':_.escape(__t))+
'</h2>\n    </div>\n    <p class="CDB-Text u-upperCase CDB-FontSize-small u-altTextColor u-bSpace--m">'+
((__t=( _t('editor.widgets.widgets-form.data.description') ))==null?'':_.escape(__t))+
'</p>\n\n    <div data-fields="'+
((__t=( dataFields ))==null?'':_.escape(__t))+
'"></div>\n\n  </div>\n</div>\n\n<div class="CDB-HeaderInfo">\n  <div class="CDB-HeaderNumeration CDB-Text is-semibold u-rSpace--m">3</div>\n\n  <div class="CDB-HeaderInfo-Inner CDB-Text">\n    <div class="CDB-HeaderInfo-Title u-bSpace--m">\n      <h2 class="CDB-Text CDB-HeaderInfo-TitleText CDB-Size-large">'+
((__t=( _t('editor.widgets.widgets-form.style.title-label') ))==null?'':_.escape(__t))+
'</h2>\n    </div>\n    <p class="CDB-Text u-upperCase CDB-FontSize-small u-altTextColor u-bSpace--m">'+
((__t=( _t('editor.widgets.widgets-form.style.description') ))==null?'':_.escape(__t))+
'</p>\n\n    <div data-fields="'+
((__t=( styleFields ))==null?'':_.escape(__t))+
'"></div>\n\n  </div>\n</div>\n';
}
return __p;
};
