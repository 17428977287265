var _ = require('underscore');
module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<div class="ImportPanel-header">\n  <h3 class="CDB-Text CDB-Size-large u-mainTextColor u-secondaryTextColor u-bSpace--m">\n    '+
((__t=( _t('components.modals.add-layer.imports.twitter.title') ))==null?'':_.escape(__t))+
'\n  </h3>\n  <p class="CDB-Text CDB-Size-medium u-altTextColor">\n    '+
((__t=( _t('components.modals.add-layer.imports.twitter.terms-desc') ))==null?'':_.escape(__t))+
'\n  </p>\n</div>\n<div class="ImportPanel-body">\n  <form class="Form">\n    <div class="ImportTwitterPanel-cagetories"></div>\n    <div class="ImportTwitterPanel-datePicker">\n      <div class="Form-row">\n        <div class="Form-rowLabel">\n          <label class="CDB-Text CDB-Size-medium js-category">'+
((__t=( _t('components.modals.add-layer.imports.twitter.from-to') ))==null?'':_.escape(__t))+
'</label>\n        </div>\n        <div class="Form-rowData Form-rowData--longer js-picker CDB-Text CDB-Size-medium"></div>\n        <div class="Form-rowInfo DatePicker-info">\n          <p class="CDB-Text CDB-Size-small u-altTextColor">'+
((__t=( _t('components.modals.add-layer.imports.twitter.twitter-gmt') ))==null?'':_.escape(__t))+
' <br/>('+
((__t=( _t('components.modals.add-layer.imports.twitter.your-gmt') ))==null?'':_.escape(__t))+
''+
((__t=( currentGMT ))==null?'':_.escape(__t))+
')</p>\n        </div>\n      </div>\n    </div>\n    <div class="ImportTwitterPanel-creditsUsage">\n      <div class="Form-row">\n        <div class="Form-rowLabel">\n          <label class="CDB-Text CDB-Size-medium js-category">'+
((__t=( _t('components.modals.add-layer.imports.twitter.use') ))==null?'':_.escape(__t))+
'</label>\n        </div>\n        <div class="Form-rowData Form-rowData--longer CreditsUsage">\n          <div class="UISlider CreditsUsage-slider js-slider"></div>\n          <div class="CreditsUsage-info CDB-Text CDB-Size-medium js-info"></div>\n        </div>\n      </div>\n    </div>\n  </form>\n</div>\n';
}
return __p;
};
