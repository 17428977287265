var _ = require('underscore');
module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<form class="Form js-form">\n  <div class="Form-row">\n    <div class="Form-rowLabel">\n      <label class="CDB-Text CDB-Size-medium"><span>'+
((__t=( _t('components.modals.add-layer.imports.form-import.title') ))==null?'':_.escape(__t))+
'</span></label>\n    </div>\n    <div class="Form-rowData Form-rowData--longer">\n      <input type="text" class="CDB-Text CDB-Size-medium Form-input Form-input--longer has-submit js-textInput" value="" placeholder="'+
((__t=( _t('components.modals.add-layer.imports.salesforce.salesforce.input-placeholder', { brand: 'Salesforce' }) ))==null?'':_.escape(__t))+
'" />\n      <button type="submit" class="CDB-Text CDB-Size-small Form-inputSubmit u-upperCase u-actionTextColor Form-inputSubmit">\n        <span>'+
((__t=( _t('components.modals.add-layer.imports.form-import.submit') ))==null?'':_.escape(__t))+
'</span>\n      </button>\n      <div class="Form-inputError CDB-Text">\n        <span>'+
((__t=( _t('components.modals.add-layer.imports.form-import.error-desc') ))==null?'':_.escape(__t))+
'</span>\n      </div>\n    </div>\n  </div>\n</form>\n';
}
return __p;
};
