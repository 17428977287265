var _ = require('underscore');
module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<div class="CDB-InputText CDB-Text is-cursor js-button u-ellipsis\n  ';
 if (disabled) { 
__p+=' is-disabled ';
 } 
__p+='\n  ';
 if (!name) { 
__p+=' is-empty ';
 } 
__p+='"\n  tabindex="0">\n  '+
((__t=( name || _t('components.backbone-forms.select.placeholder', { keyAttr: keyAttr }) ))==null?'':_.escape(__t))+
'\n</div>\n';
}
return __p;
};
