var _ = require('underscore');
module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<div class="Share-user u-rSpace js-avatar" style="background-image: url('+
((__t=( avatar ))==null?'':_.escape(__t))+
')" data-title="'+
((__t=( username ))==null?'':_.escape(__t))+
'"></div>\n';
}
return __p;
};
