var _ = require('underscore');
module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<div class="Editor-HeaderInfo">\n  <div class="Editor-HeaderNumeration CDB-Text is-semibold u-rSpace--m">2</div>\n\n  <div class="Editor-HeaderInfo-Inner CDB-Text">\n    <div class="Editor-HeaderInfo-Title u-bSpace--m">\n      <h2 class="Editor-HeaderInfo-TitleText CDB-Text CDB-Size-large">'+
((__t=( _t('editor.layers.infowindow.items.title-label') ))==null?'':_.escape(__t))+
'</h2>\n    </div>\n\n    <div class="js-content"></div>\n  </div>\n</div>\n';
}
return __p;
};
