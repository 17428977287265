var _ = require('underscore');
module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<div class="Form-row">\n  <div class="Form-rowLabel">\n    <label class="CDB-Text CDB-Size-medium js-category">'+
((__t=( _t('components.modals.add-layer.imports.twitter.category') ))==null?'':_.escape(__t))+
' '+
((__t=( category ))==null?'':_.escape(__t))+
'</label>\n  </div>\n  <div class="Form-rowData Form-rowData--longer">\n    <input type="text" class="CDB-Text CDB-Size-medium Form-input Form-input--longer has-icon js-terms" value="'+
((__t=( terms.join(",") ))==null?'':_.escape(__t))+
'" placeholder="'+
((__t=( _t('components.modals.add-layer.imports.twitter.terms-placeholder') ))==null?'':_.escape(__t))+
'" />\n    <i class="CDB-IconFont CDB-IconFont-twitter Form-inputIcon"></i>\n  </div>\n</div>\n';
}
return __p;
};
