var _ = require('underscore');
module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<p class="CDB-Text Onboarding-headerDescription">\n  '+
((__t=( _t('analyses-onboarding.new-column-included') ))==null?'':_.escape(__t))+
'\n</p>\n\n<ul class="Onboarding-list">\n  <li class="Onboarding-listItem">\n    <div class="Onboarding-listItemValue">source_cartodb_id</div>\n    <p class="CDB-Text Onboarding-description">'+
((__t=( _t('analyses-onboarding.intersection.source-cartodb-id') ))==null?'':_.escape(__t))+
'</p>\n  </li>\n</ul>\n\n<p class="CDB-Text Onboarding-description">\n  '+
((__t=( _t('analyses-onboarding.intersection.description') ))==null?'':_.escape(__t))+
'\n</p>\n\n<p class="CDB-Text Onboarding-description">\n  <a href="http://postgis.net/docs/ST_Intersects.html" class="Onboarding-readMore" target="_blank">'+
((__t=( _t('analyses-onboarding.read-more') ))==null?'':_.escape(__t))+
'</a>\n</p>\n';
}
return __p;
};
