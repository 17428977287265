var _ = require('underscore');
module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<p class="CDB-Text Onboarding-headerDescription">\n  '+
((__t=( _t('analyses-onboarding.no-new-columns') ))==null?'':_.escape(__t))+
'\n</p>\n\n<p class="CDB-Text Onboarding-description">\n  '+
((__t=( _t('analyses-onboarding.sampling.description', { percentage: Math.round(sampling * 100) }) ))==null?'':_.escape(__t))+
'\n</p>\n';
}
return __p;
};
