var _ = require('underscore');
module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<div class="Editor-formInner CDB-Text CDB-Size-small u-upperCase ';
 if (disabled) { 
__p+='u-altTextColor';
 } 
__p+=' u-upperCase">\n  <div class="u-flex u-alignCenter">\n    <div class="u-iBlock u-rSpace--m">\n      <input class="CDB-Checkbox js-input" type="checkbox" name="" value="" ';
 if (checked) { 
__p+='checked';
 } 
__p+=' ';
 if (disabled) { 
__p+='disabled';
 } 
__p+='>\n      <span class="u-iBlock CDB-Checkbox-face"></span>\n    </div>\n    <span class="';
 if (help) { 
__p+=' js-help is-underlined';
 } 
__p+='" ';
 if (help) { 
__p+=' data-tooltip="'+
((__t=( help ))==null?'':_.escape(__t))+
'"';
 } 
__p+=' >'+
((__t=( title ))==null?'':_.escape(__t))+
'</span>\n  </div>\n</div>\n';
}
return __p;
};
