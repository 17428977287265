var _ = require('underscore');
module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<ul class="Publish-optionsList u-flex u-justifySpace js-list"></ul>\n';
 if (!isPublished) { 
__p+='\n<div class="Share-info">\n  <div>\n    <h2 class="CDB-Text CDB-Size-large u-secondaryTextColor u-bSpace is-light">'+
((__t=( _t('components.modals.publish.share.unpublished-header') ))==null?'':_.escape(__t))+
'</h2>\n    <p class="CDB-Text CDB-Size-medium is-light">'+
((__t=( _t('components.modals.publish.share.unpublished-subheader') ))==null?'':_.escape(__t))+
'</p>\n  </div>\n</div>\n';
 } 
__p+='\n';
}
return __p;
};
