var _ = require('underscore');
module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<div class="u-flex u-alignCenter Modal-basemapContainer">\n  <h3 class="CDB-Text CDB-Size-large u-mainTextColor Modal-titleBasemap">'+
((__t=( _t('components.modals.add-basemap.nasa.select') ))==null?'':_.escape(__t))+
'</h3>\n  <div class="CDB-Text u-flex u-alignCenter">\n    <div class="Form-rowData Form-rowData--short Form-rowData--alignLeft">\n      <div class="Form-rowData Form-rowData--full">\n        <div class="RadioButton js-day">\n          <button type="button" class="RadioButton-input ';
 if (layerType === 'day') { 
__p+='is-checked';
 } 
__p+='"></button>\n          <label class="Metadata-label Metadata-label--auto CDB-Text CDB-Size-small is-semibold u-upperCase u-ellipsis" for="nasa-type-day">'+
((__t=( _t('components.modals.add-basemap.nasa.day') ))==null?'':_.escape(__t))+
'</label>\n        </div>\n      </div>\n      <div class="Form-rowData Form-rowData--full">\n        <div class="RadioButton js-night">\n          <button type="button" class="RadioButton-input ';
 if (layerType === 'night') { 
__p+='is-checked';
 } 
__p+='"/></button>\n          <label class="Metadata-label Metadata-label--auto CDB-Text CDB-Size-small is-semibold u-upperCase u-ellipsis" for="nasa-type-night">'+
((__t=( _t('components.modals.add-basemap.nasa.night') ))==null?'':_.escape(__t))+
'</label>\n        </div>\n      </div>\n    </div>\n    <div class="Form-rowData Form-rowData--short">\n      <div class="js-datePicker" data-title="'+
((__t=( _t('components.modals.add-basemap.nasa.cant-select') ))==null?'':_.escape(__t))+
'"></div>\n    </div>\n  </div>\n</div>\n';
}
return __p;
};
