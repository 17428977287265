var _ = require('underscore');
module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<h2 class="CDB-Text CDB-Size-huge is-light u-secondaryTextColor">\n  '+
((__t=( _t('editor.legend.no-geometry-data') ))==null?'':_.escape(__t))+
'\n</h2>\n';
}
return __p;
};
