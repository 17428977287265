var _ = require('underscore');
module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<p class="CDB-Text Onboarding-headerDescription">\n  '+
((__t=( _t('analyses-onboarding.new-columns-created') ))==null?'':_.escape(__t))+
'\n</p>\n\n<ul class="Onboarding-list">\n  <li class="Onboarding-listItem">\n    <div class="Onboarding-listItemValue">the_geom</div>\n    <p class="CDB-Text Onboarding-description">'+
((__t=( _t('analyses-onboarding.centroid.the-geom') ))==null?'':_.escape(__t))+
'</p>\n  </li>\n  <li class="Onboarding-listItem">\n    <div class="Onboarding-listItemValue">value</div>\n    <p class="CDB-Text Onboarding-description">\n      ';
 if (aggregation) { 
__p+='\n      '+
((__t=( _t('analyses-onboarding.centroid.aggregated-value') ))==null?'':_.escape(__t))+
'\n      ';
 } else { 
__p+='\n      '+
((__t=( _t('analyses-onboarding.centroid.non-aggregated-value') ))==null?'':_.escape(__t))+
'\n      ';
 } 
__p+='\n    </p>\n  </li>\n  ';
 if (category_column) { 
__p+='\n  <li class="Onboarding-listItem">\n    <div class="Onboarding-listItemValue">category</div>\n    <p class="CDB-Text Onboarding-description">'+
((__t=( _t('analyses-onboarding.centroid.category') ))==null?'':_.escape(__t))+
'</p>\n  </li>\n  ';
 } 
__p+='\n</ul>\n\n<p class="CDB-Text Onboarding-description">\n  '+
((__t=( _t('analyses-onboarding.centroid.description') ))==null?'':_.escape(__t))+
'\n</p>\n';
}
return __p;
};
