var _ = require('underscore');
module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='';
 if (headerTitle) { 
__p+='\n<div class="CDB-Box-modalHeader">\n  <ul class="CDB-Box-modalHeaderItem CDB-Box-modalHeaderItem--block CDB-Box-modalHeaderItem--paddingHorizontal">\n    <li class="CDB-ListDecoration-item CDB-ListDecoration-itemPadding--vertical CDB-Text CDB-Size-medium u-secondaryTextColor">\n      <button class="u-actionTextColor js-back u-rSpace">\n        <i class="CDB-IconFont CDB-IconFont-arrowPrev Size-large"></i>\n      </button>\n      '+
((__t=( headerTitle))==null?'':_.escape(__t))+
'\n    </li>\n  </ul>\n</div>\n';
 } 
__p+='\n<div class="js-content"></div>\n';
}
return __p;
};
