var _ = require('underscore');
module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<div class="ServiceList-itemExt u-ellipsLongText CDB-Text CDB-Size-medium u-rSpace--xl">\n  '+
((__t=( ext || '?' ))==null?'':_.escape(__t))+
'\n</div>\n<div class="ServiceList-itemInfo u-flex u-alignCenter u-justifySpace">\n  <div class="ServiceList-itemInfoTitle">\n    <h6 class="CDB-Text CDB-Size-large u-bSpace u-ellipsis" >'+
((__t=( title ))==null?'':_.escape(__t))+
'</h6>\n    <p class="CDB-Text CDB-Size-medium u-altTextColor u-ellipsis">'+
((__t=( description ))==null?'':_.escape(__t))+
'</p>\n  </div>\n  <div class="ServiceList-itemActions">\n    <button class="CDB-Button CDB-Button--secondary js-choose">\n      <span class="CDB-Button-Text CDB-Text is-semibold CDB-Size-small u-upperCase">'+
((__t=( _t('components.modals.add-layer.imports.service-import.choose') ))==null?'':_.escape(__t))+
'</span>\n    </button>\n  </div>\n</div>\n';
}
return __p;
};
