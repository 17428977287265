var _ = require('underscore');
module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='';
 if (isUpgradeable) { 
__p+='\n'+
((__t=( _t('components.background-importer.background-import-limit.hurry', { upgradeUrl: upgradeUrl }) ))==null?'':_.escape(__t))+
'\n';
 } else { 
__p+='\n'+
((__t=( _t('components.background-importer.background-import-limit.one-file', { importQuota: importQuota }) ))==null?'':_.escape(__t))+
'\n';
 } 
__p+='\n';
}
return __p;
};
