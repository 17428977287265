var _ = require('underscore');
module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<div class="u-tSpace-xl CDB-Text u-flex js-name-field">\n  <label class="Metadata-label CDB-Text CDB-Size-medium is-semibold u-upperCase u-ellipsis">'+
((__t=( _t('components.modals.maps-metadata.form.name') ))==null?'':_.escape(__t))+
'</label>\n</div>\n\n<div class="u-tSpace-xl CDB-Text u-flex">\n  <label class="Metadata-label CDB-Text CDB-Size-medium is-semibold u-upperCase u-ellipsis">'+
((__t=( _t('components.modals.maps-metadata.form.description') ))==null?'':_.escape(__t))+
'</label>\n  <div class="u-grow">\n    <div class="js-description-field u-bSpace"></div>\n    <div class="Markdown">\n      <span class="Markdown-tooltip js-markdown" data-tooltip="<em>_italics_</em><br/><b>*bold*</b><br/>[link](http://link.com)">\n        <span class="Markdown-icon">\n          <i class="Markdown-icon-text">M</i>\n          <i class="Markdown-icon-char">￬</i>\n        </span>\n        '+
((__t=( _t('components.modals.maps-metadata.form.markdown') ))==null?'':_.escape(__t))+
'\n      </span>\n    </div>\n  </div>\n\n</div>\n\n<div class="u-tSpace-xl CDB-Text u-flex u-alignCenter js-tags-field">\n  <label class="Metadata-label CDB-Text CDB-Size-medium is-semibold u-upperCase u-ellipsis">'+
((__t=( _t('components.modals.maps-metadata.form.tags') ))==null?'':_.escape(__t))+
'</label>\n</div>';
}
return __p;
};
