var _ = require('underscore');
module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='';
 if (state !== 'list' ) { 
__p+='\n  <h3 class="CDB-Text CDB-Size-large u-mainTextColor u-secondaryTextColor u-bSpace--m">\n    ';
 if (state === 'selected') { 
__p+='\n      '+
((__t=( _t('components.modals.add-layer.imports.mailchimp.campaign-selected', { brand: 'MailChimp' }) ))==null?'':_.escape(__t))+
'\n    ';
 } else { 
__p+='\n      '+
((__t=( _t('components.modals.add-layer.imports.mailchimp.map-campaign', { brand: 'MailChimp' }) ))==null?'':_.escape(__t))+
'\n    ';
 } 
__p+='\n  </h3>\n  <p class="CDB-Text CDB-Size-medium u-altTextColor ';
 if (state === "error") { 
__p+='ImportPanel-headerDescription--negative';
 } 
__p+='">\n    ';
 if (state === "idle") { 
__p+='\n      '+
((__t=( _t('components.modals.add-layer.imports.mailchimp.state-idle', { brand: 'MailChimp' }) ))==null?'':_.escape(__t))+
'\n    ';
 } 
__p+='\n    ';
 if (state === "error") { 
__p+='\n      '+
((__t=( _t('components.modals.add-layer.imports.mailchimp.state-error', { brand: 'MailChimp' }) ))==null?'':_.escape(__t))+
'\n    ';
 } 
__p+='\n    ';
 if (state === "token") { 
__p+='\n      '+
((__t=( _t('components.modals.add-layer.imports.mailchimp.state-token', { brand: 'MailChimp' }) ))==null?'':_.escape(__t))+
'\n    ';
 } 
__p+='\n    ';
 if (state === "oauth") { 
__p+='\n      '+
((__t=( _t('components.modals.add-layer.imports.mailchimp.state-oauth', { brand: 'MailChimp' }) ))==null?'':_.escape(__t))+
'\n    ';
 } 
__p+='\n    ';
 if (state === "retrieving") { 
__p+='\n      '+
((__t=( _t('components.modals.add-layer.imports.mailchimp.state-retrieving', { brand: 'MailChimp' }) ))==null?'':_.escape(__t))+
'\n    ';
 } 
__p+='\n    ';
 if (state === "selected") { 
__p+='\n      '+
((__t=( _t('components.modals.add-layer.imports.mailchimp.state-selected', { brand: 'MailChimp' }) ))==null?'':_.escape(__t))+
'\n    ';
 } 
__p+='\n  </p>\n  ';
 if (state === "selected") { 
__p+='\n    <button class="NavButton NavButton--back ImportPanel-headerButton js-back">\n      <i class="CDB-IconFont CDB-IconFont-arrowPrev"></i>\n    </button>\n  ';
 } 
__p+='\n';
 } 
__p+='\n';
}
return __p;
};
