var _ = require('underscore');
module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<div class="CDB-ListDecoration-itemLink u-flex u-justifySpace u-alignCenter ';
 if (isSelected) { 
__p+=' is-selected ';
 } 
__p+='">\n  <span class="RampItem-text CDB-Text u-ellipsis u-actionTextColor" title="'+
((__t=( name ))==null?'':_.escape(__t))+
'">'+
((__t=( name ))==null?'':_.escape(__t))+
'</span>\n\n  <div class=\'RampItem-secondaryContainer\'>\n    <div class="ColorBar RampItem-bar js-colorPicker" style="background-color: '+
((__t=( val ))==null?'':_.escape(__t))+
';"></div>\n\n    ';
 if (imageEnabled) { 
__p+='\n      <div class=\'RampItem-img\'>\n        ';
 if (image) { 
__p+='\n          <div class=\'js-image-container u-flex\'></div>\n        ';
 } else { 
__p+='\n          <button class="CDB-Text u-actionTextColor js-assetPicker">'+
((__t=( _t('form-components.editors.fill.input-color.img') ))==null?'':__t)+
'</button>\n        ';
 } 
__p+='\n      </div>\n    ';
 } 
__p+='\n  </div>\n</div>\n';
}
return __p;
};
