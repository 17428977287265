var _ = require('underscore');
module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<div class="Onboarding-fake"></div>\n<div class="Onboarding-contentWrapper">\n  <div class="Onboarding-body">\n    <p class="CDB-Text Onboarding-headerTitle">'+
((__t=( _t('analyses-onboarding.' + type + '.title') ))==null?'':_.escape(__t))+
'</p>\n    <p class="CDB-Text Onboarding-headerText">'+
((__t=( _t('analyses-onboarding.finished') ))==null?'':_.escape(__t))+
'</p>\n\n    <div class="js-content"></div>\n\n    <div class="Onboarding-footer">\n      <div class="Onboarding-footerButtons">\n        <button class="CDB-Button CDB-Button--secondary CDB-Button--white CDB-Button--big Onboarding-footer--marginRight js-close">\n          <span class="CDB-Button-Text CDB-Text u-upperCase is-semibold CDB-Size-medium">'+
((__t=( _t('analyses-onboarding.done') ))==null?'':_.escape(__t))+
'</span>\n        </button>\n\n        <button class="CDB-Button CDB-Button--primary CDB-Button--big js-style">\n          <span class="CDB-Button-Text CDB-Text u-upperCase is-semibold CDB-Size-medium">'+
((__t=( _t('analyses-onboarding.style-analysis') ))==null?'':_.escape(__t))+
'</span>\n        </button>\n      </div>\n\n      <div class="u-iBlock">\n        <input class="CDB-Checkbox js-forget" type="checkbox" id="forget-me" name="forget-me" value="true">\n        <span class="u-iBlock CDB-Checkbox-face"></span>\n        <label for="forget-me" class="Onboarding-forgetLabel Checkbox-label CDB-Text CDB-Size-small u-altTextColor u-lSpace">'+
((__t=( _t('analyses-onboarding.never-show-message') ))==null?'':_.escape(__t))+
'</label>\n      </div>\n    </div>\n  </div>\n</div>\n\n';
}
return __p;
};
