var _ = require('underscore');
module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<button class="CDB-Button CDB-Button--primary js-save ';
 if (isDisabled) { 
__p+='is-disabled';
 } 
__p+='">\n  <span class="CDB-Button-Text CDB-Text is-semibold CDB-Size-small u-upperCase">\n    '+
((__t=( label ))==null?'':_.escape(__t))+
'\n  </span>\n</button>';
}
return __p;
};
