var _ = require('underscore');
module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<div class="Dialog-header ErrorDetails-header">\n  <div class="Dialog-headerIcon Dialog-headerIcon--alert">\n    <i class="CDB-IconFont CDB-IconFont-cloud"></i>\n  </div>\n  <p class="Dialog-headerTitle--warning">\n    '+
((__t=( _t('components.background-importer.partial-import-details.unable-to-import-as-layers') ))==null?'':_.escape(__t))+
'\n  </p>\n  <p class="Dialog-headerText">\n    '+
((__t=( _t('components.background-importer.partial-import-details.find-connected-datasets') ))==null?'':_.escape(__t))+
'</br>\n    '+
((__t=( _t('components.background-importer.partial-import-details.upgrade-your-account', { userMaxLayers: userMaxLayers }) ))==null?'':_.escape(__t))+
'</br>\n  </p>\n</div>\n';
 if (maxTablesPerImport) { 
__p+='\n  <div class="Dialog-body ErrorDetails-body">\n    <ul class="ErrorDetails-list">\n      <li class="ErrorDetails-item">\n        <div class="ErrorDetails-itemStep">!</div>\n        <div class="ErrorDetails-itemText">\n          '+
((__t=( _t('components.background-importer.partial-import-details.too-many-datasets', { maxTablesPerImport: maxTablesPerImport }) ))==null?'':_.escape(__t))+
'</br>\n        </div>\n      </li>\n    </ul>\n  </div>\n';
 } 
__p+='\n<div class="Dialog-footer ErrorDetails-footer">\n  <button class="Button Button--secondary ErrorDetails-footerButton js-close">\n    <span>'+
((__t=( _t('components.background-importer.partial-import-details.continue-btn') ))==null?'':_.escape(__t))+
'</span>\n  </button>\n</div>\n';
}
return __p;
};
