var _ = require('underscore');
module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<i class="CDB-IconFont CDB-IconFont-heartFill LikesIndicator-icon ';
 if ((typeof size != "undefined") && size === 'big') { 
__p+='LikesIndicator-icon--big Navmenu-icon';
 } 
__p+='"></i>\n';
 if (likes > 2 || show_count) { 
__p+='\n  <span class="CDB-Text CDB-Size-medium LikesIndicator-count">'+
((__t=( likes ))==null?'':_.escape(__t))+
'</span>\n  ';
 if (show_label && likes > 3) { 
__p+='\n    <span class="CDB-Text CDB-Size-medium LikesIndicator-label">'+
((__t=( _t('components.likes-pluralize', { smart_count: likes }) ))==null?'':_.escape(__t))+
'</span>\n  ';
 } 
__p+='\n';
 } 
__p+='\n';
}
return __p;
};
