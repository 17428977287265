var _ = require('underscore');
module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<div class="Dialog-header">\n  <div class="Dialog-headerIcon Dialog-headerIcon--twitter u-flex u-alignCenter u-justifyCenter">\n    <i class="CDB-IconFont CDB-IconFont-twitter"></i>\n  </div>\n  <h2 class="CDB-Text CDB-Size-large u-bSpace Dialog-headerIcon--twitter">\n    '+
((__t=( _t('components.background-importer.twitter-import-details.twitter-import-title') ))==null?'':_.escape(__t))+
'\n  </h2>\n  <h3 class="CDB-Text CDB-Size-medium u-altTextColor">\n    ';
 if (datasetTotalRows === 0) { 
__p+='\n      '+
((__t=( _t('components.background-importer.twitter-import-details.errors.no-results') ))==null?'':_.escape(__t))+
'\n    ';
 } else { 
__p+='\n      '+
((__t=( _t('components.background-importer.twitter-import-details.new-type-created', { datasetTotalRowsFormatted: datasetTotalRowsFormatted, tweetPlural: datasetTotalRows != 1 ? 's' : '' }) ))==null?'':__t)+
'\n    ';
 } 
__p+='\n  </h3>\n</div>\n\n<div class="Dialog-body ErrorDetails-body">\n  <ul class="Modal-containerList">\n    <li class="ErrorDetails-item">\n      <div class="ErrorDetails-itemIcon ErrorDetails-itemIcon--success CDB-Size-big u-flex u-alignCenter u-justifyCenter u-rSpace--xl">\n        <i class="CDB-IconFont CDB-IconFont-dollar"></i>\n      </div>\n      <div class="ErrorDetails-itemText">\n        <p class="CDB-Text CDB-Size-large u-secondaryTextColor">\n          ';
 if (tweetsCost > 0) { 
__p+='\n            '+
((__t=( _t('components.background-importer.twitter-import-details.tweet-cost.paid', { tweetsCostFormatted: tweetsCostFormatted }) ))==null?'':_.escape(__t))+
'\n          ';
 } else { 
__p+='\n            '+
((__t=( _t('components.background-importer.twitter-import-details.tweet-cost.free', { tweetsCostFormatted: tweetsCostFormatted }) ))==null?'':_.escape(__t))+
'\n          ';
 } 
__p+='\n        </p>\n        <p class="CDB-Text CDB-Size-medium u-altTextColor">\n          ';
 if (tweetsCost > 0 || availableTweets <= 0) { 
__p+='\n            '+
((__t=( _t('components.background-importer.twitter-import-details.no-more-credits', { blockPriceFormatted: blockPriceFormatted, blockSizeFormatted: blockSizeFormatted }) ))==null?'':_.escape(__t))+
'\n          ';
 } else { 
__p+='\n            ';
 if (availableTweets != 1) { 
__p+='\n              '+
((__t=( _t('components.background-importer.twitter-import-details.credits-left', { availableTweetsFormatted: availableTweetsFormatted }) ))==null?'':_.escape(__t))+
'\n            ';
 } else { 
__p+='\n              '+
((__t=( _t('components.background-importer.twitter-import-details.credit-left', { availableTweetsFormatted: availableTweetsFormatted }) ))==null?'':_.escape(__t))+
'\n            ';
 } 
__p+='\n          ';
 } 
__p+='\n        </p>\n      </div>\n    </li>\n  </ul>\n</div>\n\n<div class="Dialog-footer--simple u-inner">\n  <button class="CDB-Button CDB-Button--primary u-tSpace--m">\n    <span class="CDB-Button-Text CDB-Text is-semibold CDB-Size-medium u-upperCase js-close">\n      '+
((__t=( _t('components.background-importer.error-details.close') ))==null?'':_.escape(__t))+
'\n    </span>\n  </button>\n</div>\n';
}
return __p;
};
