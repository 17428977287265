var _ = require('underscore');
module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<button class="Table-paginatorButton Table-paginatorButton--prev\n  ';
 if (isPrevAvailable) { 
__p+='\n    js-prev\n  ';
 } 
__p+='\n">\n  <i class="CDB-Text is-semibold CDB-IconFont CDB-IconFont-lArrowLight CDB-Size-small\n  ';
 if (isPrevAvailable) { 
__p+='\n    u-actionTextColor\n  ';
 } else { 
__p+='\n    u-hintTextColor\n  ';
 } 
__p+='\n  "></i>\n</button>\n<p class="Table-paginatorText CDB-Text CDB-Size-small is-semibold u-upperCase">\n  ';
 if (!size) {
__p+='\n   <span class="u-mainTextColor">…</span>\n  ';
 } else { 
__p+='\n    <span class="u-mainTextColor">'+
((__t=( (page * 40) + 1 ))==null?'':_.escape(__t))+
'</span>\n    <span class="u-altTextColor u-lSpace u-rSpace">'+
((__t=( _t('components.table.rows.paginator.to') ))==null?'':_.escape(__t))+
'</span>\n    <span class="u-mainTextColor">'+
((__t=( (page * 40) + size ))==null?'':_.escape(__t))+
'</span>\n  ';
 } 
__p+='\n</p>\n<button class="Table-paginatorButton Table-paginatorButton--next\n  ';
 if (isNextAvailable) { 
__p+='\n    js-next\n  ';
 } 
__p+='\n">\n  <i class="CDB-Text is-semibold CDB-IconFont CDB-IconFont-rArrowLight CDB-Size-small\n  ';
 if (isNextAvailable) { 
__p+='\n    u-actionTextColor\n  ';
 } else { 
__p+='\n    u-hintTextColor\n  ';
 } 
__p+='\n  "></i>\n</button>\n';
}
return __p;
};
