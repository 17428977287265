var _ = require('underscore');
module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<div class="u-flex u-alignCenter Modal-basemapContainer">\n  <h3 class="CDB-Text CDB-Size-large u-mainTextColor Modal-titleBasemap">'+
((__t=( _t('components.modals.add-basemap.wms.insert') ))==null?'':_.escape(__t))+
'</h3>\n  <div class="CDB-Text u-flex u-alignCenter">\n    <label class="Metadata-label Metadata-label--auto  CDB-Text CDB-Size-small is-semibold u-upperCase u-ellipsis">'+
((__t=( _t('components.modals.add-basemap.xyz.enter') ))==null?'':_.escape(__t))+
'</label>\n    <div class="Form-rowData Form-rowData--longer">\n      <input type="text" class="has-icon CDB-InputText CDB-Text js-url" value="" placeholder="'+
((__t=( _t('components.modals.add-basemap.xyz.eg') ))==null?'':_.escape(__t))+
' http://openlayers.org/en/v3.5.0/examples/data/ogcsample.xml">\n      <i class="CDB-IconFont CDB-IconFont-dribbble Form-inputIcon js-idle"></i>\n      <i class="Spinner XYZPanel-inputIcon XYZPanel-inputIcon--loader Spinner--formIcon Form-inputIcon js-validating" style="display: none;"></i>\n      <div class="XYZPanel-error CDB-InfoTooltip CDB-InfoTooltip--left is-error CDB-Text CDB-Size-medium CDB-InfoTooltip-text js-error '+
((__t=( (layersFetched && layers.length === 0) ? 'is-visible' : '' ))==null?'':_.escape(__t))+
'">\n        ';
 if (layersFetched && layers.length === 0) { 
__p+='\n          '+
((__t=( _t('components.modals.add-basemap.wms.invalid') ))==null?'':_.escape(__t))+
'\n        ';
 } 
__p+='\n      </div>\n    </div>\n  </div>\n</div>\n';
}
return __p;
};
