var _ = require('underscore');
module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='';
 if (hasChanges) { 
__p+='\n<span class="CDB-Text CDB-Size-small u-rSpace--m u-altTextColor">'+
((__t=( _t('editor.unpublished-changes') ))==null?'':_.escape(__t))+
'</span>\n';
 } 
__p+='\n<button class="CDB-Button CDB-Button--primary js-button">\n  <span class="CDB-Button-Text CDB-Text is-semibold CDB-Size-small">'+
((__t=( _t('editor.button_share') ))==null?'':_.escape(__t))+
'</span>\n</button>';
}
return __p;
};
