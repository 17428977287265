var _ = require('underscore');
module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='';
 _.each(items, function(item, index) { 
__p+='\n  <li class="u-flex u-alignCenter '+
((__t=( (index === (items.length - 1)) ? '' : 'u-rSpace--xl' ))==null?'':_.escape(__t))+
'">\n    <input type="radio" class="CDB-Radio u-iBlock"\n        name="'+
((__t=( item.name ))==null?'':_.escape(__t))+
'" value="'+
((__t=( item.value ))==null?'':_.escape(__t))+
'" id="'+
((__t=( item.id ))==null?'':_.escape(__t))+
'"\n        ';
 if (item.selected) { 
__p+='\n          selected="selected"\n        ';
 } else if (item.disabled) { 
__p+='\n          disabled="disabled"\n        ';
 } 
__p+='\n      />\n    <span class="u-rSpace CDB-Radio-face"></span>\n      ';
 if (item.help) { 
__p+='\n        <span class="js-help is-underlined u-lSpace" data-tooltip="'+
((__t=( item.help ))==null?'':_.escape(__t))+
'">\n      ';
 } 
__p+='\n      '+
((__t=( item.label ))==null?'':_.escape(__t))+
'\n      ';
 if (item.help) { 
__p+='\n        </span>\n      ';
 } 
__p+='\n  </li>\n';
 }); 
__p+='\n';
}
return __p;
};
