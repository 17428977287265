var _ = require('underscore');
module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='';
 extraTweets = '<strong>$' + block_price/100 + '/' + block_size + ' ' + _t('components.modals.add-layer.imports.twitter.extra-tweets') + '</strong>' 
__p+='\n';
 if (value <= remaining) { 
__p+='\n  '+
((__t=( _t('components.modals.add-layer.imports.twitter.credits-left', {
    per: per,
    remainingFormatted: remainingFormatted
  }) ))==null?'':_.escape(__t))+
'\n';
 } else if (remaining <= 0 && !hardLimit) { 
__p+='\n  '+
((__t=( _t('components.modals.add-layer.imports.twitter.credits-consumed', {
    extraTweets: extraTweets
  }) ))==null?'':__t)+
'\n';
 } else { 
__p+='\n  '+
((__t=( _t('components.modals.add-layer.imports.twitter.credits-no-limit', {
    extraTweets: extraTweets
  }) ))==null?'':__t)+
'\n';
 } 
__p+='\n';
}
return __p;
};
