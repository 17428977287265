var _ = require('underscore');
module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='';
 if (isDone) { 
__p+='\n  <div class="HorizontalBlockList-item-actionBlock CDB-Text CDB-Size-small u-upperCase">\n    <span class="HorizontalBlockList-item-text">\n      '+
((__t=( nodeId ))==null?'':_.escape(__t))+
'\n    </span>\n    ';
 if (!isNew) { 
__p+='\n      <i class="CDB-IconFont CDB-IconFont-ray CDB-Size-medium HorizontalBlockList-item-icon"></i>\n    ';
 } 
__p+='\n  </div>\n';
 } else { 
__p+='\n  ';
 if (isSelected) { 
__p+='\n    <div class="CDB-LoaderIcon">\n      <svg class="CDB-LoaderIcon-spinner" viewBox="0 0 50 50">\n        <circle class="CDB-LoaderIcon-path" cx="25" cy="25" r="20" fill="none"></circle>\n      </svg>\n    </div>\n  ';
 } else { 
__p+='\n    <div class="CDB-LoaderIcon is-dark">\n      <svg class="CDB-LoaderIcon-spinner" viewBox="0 0 50 50">\n        <circle class="CDB-LoaderIcon-path" cx="25" cy="25" r="20" fill="none"></circle>\n      </svg>\n    </div>\n  ';
 } 
__p+='\n';
 } 
__p+='\n\n';
 if (hasError) { 
__p+='\n<div class="Editor-ListAnalysis-itemError"></div>\n';
 } 
__p+='\n';
}
return __p;
};
