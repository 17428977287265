var _ = require('underscore');
module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<p class="CDB-Text Onboarding-headerDescription">\n  '+
((__t=( _t('analyses-onboarding.new-columns-included-based-on-input') ))==null?'':_.escape(__t))+
'\n</p>\n\n<p class="CDB-Text Onboarding-description">\n  '+
((__t=( _t('analyses-onboarding.merge.description') ))==null?'':_.escape(__t))+
'\n</p>\n\n<p class="CDB-Text Onboarding-description">\n  <a href="https://carto.com/academy/courses/sql-postgis/joining-data/" class="Onboarding-readMore" target="_blank">'+
((__t=( _t('analyses-onboarding.read-more') ))==null?'':_.escape(__t))+
'</a>\n</p>\n';
}
return __p;
};
