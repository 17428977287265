var _ = require('underscore');
module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<div class="u-flex u-alignCenter Modal-basemapContainer">\n  <h3 class="CDB-Text CDB-Size-large u-mainTextColor Modal-titleBasemap">'+
((__t=( _t('components.modals.add-basemap.mapbox.insert') ))==null?'':_.escape(__t))+
'</h3>\n  <div class="CDB-Text u-flex u-alignCenter ">\n    <label class="Metadata-label Metadata-label--big CDB-Text CDB-Size-small is-semibold u-upperCase u-ellipsis">'+
((__t=( _t('components.modals.add-basemap.mapbox.enter') ))==null?'':_.escape(__t))+
'</label>\n    <div class="Form-rowData Form-rowData--longer">\n      <input type="text" class="CDB-InputText CDB-Text js-url" value="'+
((__t=( url ))==null?'':_.escape(__t))+
'" placeholder="'+
((__t=( _t('components.modals.add-basemap.xyz.eg') ))==null?'':_.escape(__t))+
' username.ab12cd3">\n    </div>\n  </div>\n  <div class="u-tSpace-xl CDB-Text u-flex u-alignCenter">\n    <label class="Metadata-label Metadata-label--big CDB-Text CDB-Size-small is-semibold u-upperCase u-ellipsis">'+
((__t=( _t('components.modals.add-basemap.mapbox.enter-token') ))==null?'':_.escape(__t))+
'</label>\n    <div class="Form-rowData Form-rowData--longer">\n      <input type="text" class="CDB-InputText CDB-Text js-access-token" value="'+
((__t=( accessToken ))==null?'':_.escape(__t))+
'" placeholder="'+
((__t=( _t('components.modals.add-basemap.xyz.eg') ))==null?'':_.escape(__t))+
' pk.bfg32ewdsadeyJ1Ijoi…">\n      <div class="XYZPanel-error CDB-InfoTooltip CDB-InfoTooltip--left is-error CDB-Text CDB-Size-medium CDB-InfoTooltip-text js-error '+
((__t=( lastErrorMsg ? 'is-visible' : '' ))==null?'':_.escape(__t))+
'">'+
((__t=( lastErrorMsg ))==null?'':_.escape(__t))+
'</div>\n    </div>\n  </div>\n</div>\n';
}
return __p;
};
