var _ = require('underscore');
module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<div class="Editor-boxModalContent js-assets">\n  <h2 class="CDB-Text CDB-Size-small u-bSpace--m u-upperCase">\n    '+
((__t=( _t('form-components.editors.fill.image.recently-title') ))==null?'':_.escape(__t))+
'\n  </h2>\n</div>\n<div class="CustomRamp-list">\n  <div class="CustomList-item CustomList-item--add">\n    <button class="CDB-ListDecoration-itemLink CDB-Text CDB-Size-medium u-actionTextColor js-show-collection" type="button">\n      '+
((__t=( _t('form-components.editors.fill.image.show-all') ))==null?'':_.escape(__t))+
'\n    </button>\n  </div>\n</div>\n';
}
return __p;
};
