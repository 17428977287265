var _ = require('underscore');
module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<div class="Analysis-animation ';
 if (enabled) { 
__p+='is-enabled';
 } 
__p+=' ';
 if (type) { 
__p+='is-'+
((__t=( type ))==null?'':_.escape(__t))+
'';
 } 
__p+=' js-animation u-flex u-alignCenter u-justifyCenter"></div>\n<div class="Analysis-info u-flex">\n  <div class="ModalBlockList-itemInput CDB-Size-large">\n    <input class="CDB-Radio" type="radio" value="true"\n        ';
 if (selected) { 
__p+='checked="checked"';
 } 
__p+='\n        ';
 if (!enabled) { 
__p+='disabled="disabled"';
 } 
__p+='\n      >\n    <span class="u-iBlock CDB-Radio-face"></span>\n  </div>\n  <div class="ModalBlockList-inner">\n    <div class="ModalBlockList-item-header u-bSpace">\n      <h2 class="ModalBlockList-item-headerTitle CDB-Text CDB-Size-large u-rSpace--m u-ellipsis" title="'+
((__t=( title ))==null?'':_.escape(__t))+
'">\n        '+
((__t=( title ))==null?'':_.escape(__t))+
'\n      </h2>\n    </div>\n    <div class="ModalBlockList-item-body">\n      <p class="CDB-Text CDB-Size-small u-altTextColor">\n        '+
((__t=( desc ))==null?'':_.escape(__t))+
'\n      </p>\n      <button class="CDB-Text CDB-Size-small u-tSpace-xl u-actionTextColor js-more">'+
((__t=( _t('components.modals.add-analysis.info-analysis') ))==null?'':_.escape(__t))+
'</button>\n    </div>\n  </div>\n</div>\n';
}
return __p;
};
