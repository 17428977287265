var _ = require('underscore');
module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<p class="CDB-Text CDB-Size-medium u-altTextColor Editor-dropDownInfoText">\n  '+
((__t=( _t('components.backbone-forms.operators.count-message') ))==null?'':_.escape(__t))+
'\n</p>\n';
}
return __p;
};
