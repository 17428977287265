var _ = require('underscore');
module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<div class="CDB-Text CDB-Size-small is-semibold u-rSpace--xl';
 if (disabled) { 
__p+=' is-disabled';
 } 
__p+='">\n  <label class="u-iBlock u-upperCase">'+
((__t=( labels[0] ))==null?'':_.escape(__t))+
'</label>\n  <input class="CDB-Toggle u-iBlock js-input" type="checkbox"\n    ';
 if (disabled) { 
__p+=' disabled';
 } 
__p+='\n    ';
 if (checked) { 
__p+=' checked ';
 } 
__p+=' >\n  <span class="u-iBlock CDB-ToggleFace"></span>\n  <label class="u-iBlock u-upperCase">'+
((__t=( labels[1] ))==null?'':_.escape(__t))+
'</label>\n</div>';
}
return __p;
};
