var _ = require('underscore');
module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<div class="LayoutIcon LayoutIcon--negative">\n  <i class="CDB-IconFont CDB-IconFont-cockroach"></i>\n</div>\n<h3 class="CDB-Text CDB-Size-large u-mainTextColor u-errorTextColor u-bSpace--m u-tSpace-xl">'+
((__t=( title ))==null?'':_.escape(__t))+
'</h3>\n<p class="CDB-Text CDB-Size-medium u-altTextColor">'+
((__t=( cdb.core.sanitize.html(desc) ))==null?'':__t)+
'</p>\n';
}
return __p;
};
