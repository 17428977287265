var _ = require('underscore');
module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<div class="DatasetSelected-item">\n  <div class="DatasetSelected-itemExt CDB-Text u-ellipsis">\n    '+
((__t=( ext || '?' ))==null?'':_.escape(__t))+
'\n  </div>\n  <div class="DatasetSelected-itemInfo u-ellipsis">\n    <h6 class="CDB-Text CDB-Size-large u-ellipsis" title="'+
((__t=( title ))==null?'':_.escape(__t))+
'">'+
((__t=( title ))==null?'':_.escape(__t))+
'</h6>\n    <p class="CDB-Text CDB-Size-small u-ellipsis u-altTextColor">'+
((__t=( description ))==null?'':_.escape(__t))+
'</p>\n  </div>\n</div>\n';
 if (importCanSync) { 
__p+='\n  <div class="DatasetSelected-sync">\n    <div class="DatasetSelected-syncOptions">\n      <label class="DatasetSelected-syncLabel CDB-Text CDB-Size-medium u-mainTextColor">\n        '+
((__t=( _t('components.modals.add-layer.imports.selected-state.sync-my-data') ))==null?'':_.escape(__t))+
'\n      </label>\n      <ul class="DatasetSelected-syncOptionsList">\n        <li class="DatasetSelected-syncOptionsItem">\n          <div class="RadioButton">\n            <button class="RadioButton-input js-interval-0 '+
((__t=( interval === 0 ? 'is-checked' : '' ))==null?'':_.escape(__t))+
'"></button>\n            <label class="CDB-Text CDB-Size-medium u-altTextColor u-lSpace">'+
((__t=( _t('components.modals.add-layer.imports.selected-state.never') ))==null?'':_.escape(__t))+
'</label>\n          </div>\n        </li>\n        <li class="DatasetSelected-syncOptionsItem">\n          <div class="RadioButton '+
((__t=( !userCanSync ? 'is-disabled' : '' ))==null?'':_.escape(__t))+
'">\n            <button class="RadioButton-input js-interval-1 '+
((__t=( interval === 3600 ? 'is-checked' : '' ))==null?'':_.escape(__t))+
'"></button>\n            <label class="CDB-Text CDB-Size-medium u-altTextColor u-lSpace">'+
((__t=( _t('components.modals.add-layer.imports.selected-state.every-hour') ))==null?'':_.escape(__t))+
'</label>\n          </div>\n        </li>\n        <li class="DatasetSelected-syncOptionsItem">\n          <div class="RadioButton '+
((__t=( !userCanSync ? 'is-disabled' : '' ))==null?'':_.escape(__t))+
'">\n            <button class="RadioButton-input js-interval-2 '+
((__t=( interval === 86400 ? 'is-checked' : '' ))==null?'':_.escape(__t))+
'"></button>\n            <label class="CDB-Text CDB-Size-medium u-altTextColor u-lSpace">'+
((__t=( _t('components.modals.add-layer.imports.selected-state.every-day') ))==null?'':_.escape(__t))+
'</label>\n          </div>\n        </li>\n        <li class="DatasetSelected-syncOptionsItem">\n          <div class="RadioButton '+
((__t=( !userCanSync ? 'is-disabled' : '' ))==null?'':_.escape(__t))+
'">\n            <button class="RadioButton-input js-interval-3 '+
((__t=( interval === 604800 ? 'is-checked' : '' ))==null?'':_.escape(__t))+
'"></button>\n            <label class="CDB-Text CDB-Size-medium u-altTextColor u-lSpace">'+
((__t=( _t('components.modals.add-layer.imports.selected-state.every-week') ))==null?'':_.escape(__t))+
'</label>\n          </div>\n        </li>\n        <li class="DatasetSelected-syncOptionsItem">\n          <div class="RadioButton '+
((__t=( !userCanSync ? 'is-disabled' : '' ))==null?'':_.escape(__t))+
'">\n            <button class="RadioButton-input js-interval-4 '+
((__t=( interval === 2592000 ? 'is-checked' : '' ))==null?'':_.escape(__t))+
'"></button>\n            <label class="CDB-Text CDB-Size-medium u-altTextColor u-lSpace">'+
((__t=( _t('components.modals.add-layer.imports.selected-state.every-month') ))==null?'':_.escape(__t))+
'</label>\n          </div>\n        </li>\n      </ul>\n    </div>\n    ';
 if (showUpgrade) { 
__p+='\n      <div class="Upgrade-info">\n        <p class="CDB-Text CDB-Size-medium u-ellipsis u-secondaryTextColor">\n         ';
 featuresLink = '<a href="https://carto.com/pricing">' + _t('components.modals.add-layer.imports.selected-state.more-features') + '</a>' 
__p+='\n          '+
((__t=( _t('components.modals.add-layer.imports.selected-state.upgrade-desc', { features: featuresLink }) ))==null?'':_.escape(__t))+
'\n        </p>\n        <div class="Upgrade-infoActions">\n          ';
 if (showTrial) { 
__p+='\n            <p class="CDB-Text CDB-Size-medium u-ellipsis is-semibold u-rSpace--xl">'+
((__t=( _t('components.modals.add-layer.imports.selected-state.free-trial', { days: 14 }) ))==null?'':_.escape(__t))+
'</p>\n          ';
 } 
__p+='\n          <a href="'+
((__t=( upgradeUrl ))==null?'':_.escape(__t))+
'" class="CDB-Button CDB-Button--secondary">\n            <span class="CDB-Button-Text CDB-Text is-semibold CDB-Size-medium u-upperCase">'+
((__t=( _t('components.modals.add-layer.imports.selected-state.upgrade') ))==null?'':_.escape(__t))+
'</span>\n          </a>\n        </div>\n      </div>\n    ';
 } 
__p+='\n  </div>\n';
 } 
__p+='\n';
}
return __p;
};
