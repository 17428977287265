var _ = require('underscore');
module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<div class="Modal">\n  <div class="Modal-header">\n    <div class="Modal-headerContainer">\n      <h2 class="CDB-Text CDB-Size-huge is-light u-bSpace">'+
((__t=( _t('components.modals.add-asset.modal-title') ))==null?'':_.escape(__t))+
'</h2>\n      <h3 class="CDB-Text CDB-Size-medium u-altTextColor"><button class="u-actionTextColor js-upload">'+
((__t=( _t('components.modals.add-asset.upload-asset') ))==null?'':_.escape(__t))+
'</button> '+
((__t=( _t('components.modals.add-asset.modal-desc') ))==null?'':_.escape(__t))+
'</h3>\n    </div>\n  </div>\n\n  <div class="Modal-container">\n    <div class="Modal-navigation">\n      <nav class="CDB-NavMenu u-inner">\n        <ul class="CDB-Size-medium CDB-Text is-semibold js-menu"></ul>\n      </nav>\n    </div>\n    <div class="Tab-paneContent Publish-modalContent Modal-inner Modal-inner--with-navigation js-content"></div>\n    </div>\n  </div>\n\n  <div class="Modal-footer">\n    <div class="Modal-footerContainer u-flex u-justifySpace">\n\n      <div class="CDB-Text CDB-Size-medium js-disclaimer">\n        ';
 if (disclaimer) { 
__p+='\n        '+
((__t=( disclaimer ))==null?'':__t)+
'\n        ';
 } 
__p+='\n      </div>\n\n      <button class="CDB-Button CDB-Button--primary is-disabled js-add">\n        <span class="CDB-Button-Text CDB-Text is-semibold CDB-Size-medium u-upperCase">'+
((__t=( _t('components.modals.add-asset.set-image') ))==null?'':_.escape(__t))+
'</span>\n      </button>\n    </div>\n  </div>\n</div>\n<form accept-charset="UTF-8" enctype="multipart/form-data" method="post">\n  <input type="file" accept="image/jpeg,image/jpg,image/gif,image/png,image/svg+xml" class="js-fileInput" style="position: absolute; clip: rect(0px 0px 0px 0px); opacity: 0;" multiple="multiple">\n</form>\n';
}
return __p;
};
