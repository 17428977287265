var _ = require('underscore');
module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<div class="WidgetList-option">\n  <input class="CDB-Checkbox js-checkbox" type="checkbox" ';
 if (isSelected) { 
__p+='checked="checked"';
 } 
__p+=' />\n  <span class="u-iBlock CDB-Checkbox-face"></span>\n</div>\n\n<div class="WidgetList-inner js-inner">\n  <h3 class="u-ellipsis CDB-Text CDB-Size-large u-bSpace--m">'+
((__t=( columnName ))==null?'':_.escape(__t))+
'</h3>\n  <ul class="js-histstats u-flex CDB-Text CDB-Size-small u-upperCase" style="display: none;">\n    <li class=\'u-rSpace\'></li>\n  </ul>\n  <div class="u-tSpace--m u-bSpace--m js-Histogram"></div>\n</div>\n';
}
return __p;
};
