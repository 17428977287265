var _ = require('underscore');
module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<div class="u-flex u-bSpace--xl">\n  <div class="Editor-HeaderNumeration CDB-Text is-semibold u-rSpace--m">1</div>\n  <div class="Editor-HeaderInfo-inner CDB-Text">\n    <div class="Editor-HeaderInfo-title u-bSpace--m">\n      <h2 class="CDB-Text CDB-HeaderInfo-titleText CDB-Size-large">'+
((__t=( _t('editor.legend.legend-form.type.title') ))==null?'':_.escape(__t))+
'</h2>\n    </div>\n    <div class="js-selector u-bSpace--xl">\n      <p class="CDB-Text u-upperCase CDB-FontSize-small u-altTextColor u-bSpace--m js-highlight">'+
((__t=( name ))==null?'':_.escape(__t))+
'</p>\n    </div>\n  </div>\n</div>\n';
}
return __p;
};
