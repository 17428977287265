var _ = require('underscore');
module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<div class="CDB-Box-modalHeader">\n  <ul class="CDB-Box-modalHeaderItem CDB-Box-modalHeaderItem--block CDB-Box-modalHeaderItem--paddingHorizontal">\n    <li class="InputColor-modalHeader CDB-ListDecoration-item CDB-ListDecoration-itemPadding--vertical CDB-Text CDB-Size-medium u-secondaryTextColor">\n      <div class="u-flex u-alignStart">\n        <button class="u-rSpace u-actionTextColor js-back">\n          <i class="CDB-IconFont CDB-IconFont-arrowPrev Size-large"></i>\n        </button>\n        '+
((__t=( attribute ))==null?'':_.escape(__t))+
'\n      </div>\n    </li>\n    <li class="CDB-ListDecoration-item CDB-ListDecoration-itemPadding--vertical CDB-Text CDB-Size-medium u-secondaryTextColor">\n      <ul class="u-flex u-justifySpace">\n        <li class="u-flex">\n          '+
((__t=( bins ))==null?'':_.escape(__t))+
' '+
((__t=( _t('form-components.editors.fill.input-ramp.buckets', { smart_count: bins }) ))==null?'':_.escape(__t))+
'\n          <button class="CDB-Shape u-lSpace js-bins">\n            <div class="CDB-Shape-threePoints is-horizontal is-blue is-small">\n              <div class="CDB-Shape-threePointsItem"></div>\n              <div class="CDB-Shape-threePointsItem"></div>\n              <div class="CDB-Shape-threePointsItem"></div>\n            </div>\n          </button>\n        </li>\n        <li class="u-flex">\n          '+
((__t=( _t('form-components.editors.fill.quantification.methods.' + quantification) ))==null?'':_.escape(__t))+
'\n          <button class="CDB-Shape u-lSpace js-quantification">\n            <div class="CDB-Shape-threePoints is-horizontal is-blue is-small">\n              <div class="CDB-Shape-threePointsItem"></div>\n              <div class="CDB-Shape-threePointsItem"></div>\n              <div class="CDB-Shape-threePointsItem"></div>\n            </div>\n          </button>\n        </li>\n      </ul>\n    </li>\n  </ul>\n</div>\n<div class="js-content"></div>\n';
}
return __p;
};
