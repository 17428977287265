var _ = require('underscore');
module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<div class="CDB-Text CDB-Fieldset u-tSpace--m">\n  <div class="CDB-Legend CDB-Legend--big u-ellipsis CDB-Text CDB-Size-medium u-rSpace--m">\n    <div class="Infowindow-listFieldsOrder CDB-Shape u-iblock u-malign">\n      <div class="CDB-Shape-rectsHandle is-small">\n        <div class="CDB-Shape-rectsHandleItem CDB-Shape-rectsHandleItem--grey is-first"></div>\n        <div class="CDB-Shape-rectsHandleItem CDB-Shape-rectsHandleItem--grey is-second"></div>\n        <div class="CDB-Shape-rectsHandleItem CDB-Shape-rectsHandleItem--grey is-third"></div>\n      </div>\n    </div>\n    <input class="CDB-Checkbox js-checkbox" type="checkbox" ';
 if (isSelected) { 
__p+='checked="checked"';
 } 
__p+='">\n    <span class="u-iBlock CDB-Checkbox-face u-rSpace"></span>\n    <label class="u-rSpace--m" title="'+
((__t=( name ))==null?'':_.escape(__t))+
'">'+
((__t=( name ))==null?'':_.escape(__t))+
'</label>\n  </div>\n  <input type="text" name="text" placeholder=\'"'+
((__t=( name ))==null?'':_.escape(__t))+
'"\' value="';
 if (!isSelected) { 
__p+=''+
((__t=( name ))==null?'':_.escape(__t))+
'';
 } else { 
__p+='';
 if (title) { 
__p+='';
 if (alternativeName) { 
__p+=''+
((__t=( alternativeName ))==null?'':_.escape(__t))+
'';
 } else { 
__p+=''+
((__t=( name ))==null?'':_.escape(__t))+
'';
 } 
__p+='';
 } 
__p+='';
 } 
__p+='" class="CDB-InputText js-input" ';
 if (!isSelected) { 
__p+='disabled';
 } 
__p+='>\n</div>\n';
}
return __p;
};
