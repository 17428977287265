var _ = require('underscore');
module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<div class="ImportPanel-header">\n  <div class="LayoutIcon u-bSpace--xl">\n    <i class="CDB-IconFont CDB-IconFont-gift"></i>\n  </div>\n  <h3 class="CDB-Text CDB-Size-large u-mainTextColor u-secondaryTextColor u-bSpace--m">'+
((__t=( _t('components.modals.add-layer.imports.twitter.fallback-title', { brand: 'Twitter' }) ))==null?'':_.escape(__t))+
'</h3>\n  <p class="CDB-Text CDB-Size-medium u-altTextColor u-bSpace--xl">\n    '+
((__t=( _t('components.modals.add-layer.imports.twitter.fallback-desc', { brand: 'Twitter' }) ))==null?'':_.escape(__t))+
'\n  </p>\n  <a href="mailto:sales@carto.com?subject='+
((__t=( _t('components.modals.add-layer.imports.demo-email-title', { name: 'Twitter' }) ))==null?'':_.escape(__t))+
'&body='+
((__t=( _t('components.modals.add-layer.imports.demo-email-desc', { name: 'Twitter' }) ))==null?'':_.escape(__t))+
'" class="CDB-Button CDB-Button--primary CDB-Button--medium">\n    <span class="CDB-Button-Text CDB-Text is-semibold CDB-Size-medium u-upperCase">ask for a demo</span>\n  </a>\n</div>\n';
}
return __p;
};
