var _ = require('underscore');
module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<div class="ColorBarWrapper--withInvertLink CDB-ListDecoration-itemLink CDB-ListDecoration-itemLink--double js-listItemLink ';
 if (isSelected) { 
__p+=' is-selected ';
 } 
__p+='">\n  <ul class="ColorBarContainer">\n    ';
 _.each(name, function (color) { 
__p+='\n    <li class="ColorBar ColorBar--spaceless" style="background-color: '+
((__t=( color ))==null?'':_.escape(__t))+
';"></li>\n    ';
 }); 
__p+='\n  </ul>\n</div>\n<button class="ColorBar-invertLink js-invert" data-event="invert"></button>\n\n';
}
return __p;
};
