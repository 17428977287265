var _ = require('underscore');
module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<svg width="306px" height="98px" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">\n  <g id="Mod-Analysis" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">\n    <g fill="#9DE0AD">\n      <text x="8" y="19" class="Text Text-LH" font-family="OpenSans, Open Sans" font-size="10" font-weight="normal">\n        '+
((__t=( _t('components.modals.add-analysis.option-types.moran-cluster.low-high') ))==null?'':_.escape(__t))+
'\n      </text>\n      <text x="227" y="19" class="Text Text-HH" font-family="OpenSans, Open Sans" font-size="10" font-weight="normal">\n        '+
((__t=( _t('components.modals.add-analysis.option-types.moran-cluster.high-high') ))==null?'':_.escape(__t))+
'\n      </text>\n      <text x="233" y="85" class="Text Text-HL" font-family="OpenSans, Open Sans" font-size="10" font-weight="normal">\n        '+
((__t=( _t('components.modals.add-analysis.option-types.moran-cluster.high-low') ))==null?'':_.escape(__t))+
'\n      </text>\n      <text x="10" y="85" class="Text Text-LL" font-family="OpenSans, Open Sans" font-size="10" font-weight="normal">\n        '+
((__t=( _t('components.modals.add-analysis.option-types.moran-cluster.low-low') ))==null?'':_.escape(__t))+
'\n      </text>\n    </g>\n    <g fill="#FFF">\n      <circle class="Dot Dot-04" fill-opacity="0.4" cx="106" cy="57" r="4"></circle>\n      <circle class="Dot Dot-04" fill-opacity="0.4" cx="137" cy="66" r="4"></circle>\n      <circle class="Dot Dot-04" fill-opacity="0.4" cx="79" cy="62" r="4"></circle>\n      <circle class="Dot Dot-06" fill-opacity="0.6" cx="193" cy="70" r="4"></circle>\n      <circle class="Dot Dot-06" fill-opacity="0.6" cx="175" cy="58" r="4"></circle>\n      <circle class="Dot Dot-08" fill-opacity="0.8" cx="129" cy="30" r="4"></circle>\n      <circle class="Dot Dot-08" fill-opacity="0.8" cx="145" cy="40" r="4"></circle>\n      <circle class="Dot Dot-1" cx="231" cy="36" r="4"></circle>\n      <circle class="Dot Dot-1" cx="197" cy="40" r="4"></circle>\n      <circle class="Dot Dot-1" cx="250" cy="38" r="4"></circle>\n      <circle class="Dot Dot-1" cx="167" cy="36" r="4"></circle>\n      <circle class="Dot Dot-1" cx="203" cy="20" r="4"></circle>\n    </g>\n    <g stroke="#9DE0AD">\n      <path d="M0,49 L304,49" class="Line Line-horizontal" stroke-dasharray="2,3"></path>\n      <path d="M152,0 L152,98" class="Line Line-vertical" stroke-dasharray="2,3"></path>\n    </g>\n  </g>\n</svg>\n';
}
return __p;
};
