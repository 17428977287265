var _ = require('underscore');
module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<div class="onboardingNotification Color-MainDark CDB-Text CDB-Size-medium u-flex u-justifyCenter">\n  <p>'+
((__t=( _t('builder-activated-notification.message') ))==null?'':__t)+
'</p>\n\n  <button class="onboardingNotification-closeButton js-close">\n    <div class="CDB-Shape-close is-large is-white"></div>\n  </button>\n</div>\n';
}
return __p;
};
