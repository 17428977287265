var _ = require('underscore');
module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<div class="Editor-HeaderInfo">\n  <div class="Editor-HeaderNumeration CDB-Text is-semibold u-rSpace--m">1</div>\n  <div class="Editor-HeaderInfo-inner CDB-Text">\n    <div class="Editor-HeaderInfo-title u-bSpace--m">\n      <h2 class="CDB-Text CDB-HeaderInfo-titleText CDB-Size-large">'+
((__t=( _t('editor.settings.preview.options.title') ))==null?'':_.escape(__t))+
'</h2>\n    </div>\n    <p class="CDB-Text u-upperCase CDB-Size-small u-altTextColor u-bSpace--xl">'+
((__t=( _t('editor.settings.preview.options.subtitle') ))==null?'':_.escape(__t))+
'</p>\n    <div class="u-flex u-justifySpace">\n      <div class="js-settings Options-form"></div>\n    </div>\n\n  </div>\n</div>\n';
}
return __p;
};
