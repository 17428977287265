var _ = require('underscore');
module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='';
 if (people > 0) { 
__p+='\n<div class="Share-user '+
((__t=( avatarClass ))==null?'':_.escape(__t))+
' u-rSpace u-iBlock" style="background-image: url('+
((__t=( avatar ))==null?'':_.escape(__t))+
')"></div>\n<span class="u-secondaryTextColor CDB-Text CDB-Size-small '+
((__t=( separationClass ))==null?'':_.escape(__t))+
'">+ '+
((__t=( people ))==null?'':_.escape(__t))+
'</span>\n';
 } else { 
__p+='\n  ';
 if (hasAction) { 
__p+='\n  <button class="CDB-Text CDB-Size-small u-upperCase u-actionTextColor js-action '+
((__t=( separationClass ))==null?'':_.escape(__t))+
'">'+
((__t=( _t('components.modals.publish.share.add-people') ))==null?'':_.escape(__t))+
'</button>\n  ';
 } 
__p+='\n';
 } 
__p+='\n';
}
return __p;
};
