var _ = require('underscore');
module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<div class="CDB-ListDecoration-itemLink u-flex u-justifySpace u-alignCenter u-actionTextColor ';
 if (isSelected) { 
__p+=' is-selected ';
 } 
__p+='">\n  '+
((__t=( _t('form-components.editors.fill.quantification.methods.' + name) ))==null?'':_.escape(__t))+
'\n</div>\n\n';
}
return __p;
};
