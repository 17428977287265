var _ = require('underscore');
module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<button type="button" class="CDB-ListDecoration-itemLink u-ellipsis u-actionTextColor" title="'+
((__t=( val ))==null?'':_.escape(__t))+
'">\n  ';
 if (typeof type != 'undefined' && type === 'node') { 
__p+='\n    <div class="u-flex">\n      <span\n        class="SelectorLayer-letter CDB-Text CDB-Size-small u-whiteTextColor u-rSpace u-upperCase"\n        style="background-color: '+
((__t=( color ))==null?'':_.escape(__t))+
';">'+
((__t=( val ))==null?'':_.escape(__t))+
'</span>\n      <p class="CDB-Text CDB-Size-medium u-ellipsis u-flex">\n        '+
((__t=( nodeTitle ))==null?'':_.escape(__t))+
'<span class="u-altTextColor u-lSpace u-ellipsis">'+
((__t=( layerName ))==null?'':_.escape(__t))+
'</span>\n      </p>\n    </div>\n  ';
 } else { 
__p+='\n    '+
((__t=( val ))==null?'':_.escape(__t))+
'\n  ';
 } 
__p+='\n</button>\n';
}
return __p;
};
