var _ = require('underscore');
module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<div class="u-inner">\n  <div class="Filters WMSSSelectLayer-Filter is-relative">\n    <div class="Filters-inner">\n      <div class="Filters-row">\n        <div class="Filters-group">\n          <div class="Filters-typeItem Filters-typeItem--searchEnabler">\n            <button class="Filters-searchLink CDB-Text is-semibold u-upperCase CDB-Size-medium js-search-link">\n              <i class="Filters-searchLinkIcon CDB-IconFont CDB-IconFont-lens"></i>'+
((__t=( _t('components.modals.add-layer.navigation.search') ))==null?'':_.escape(__t))+
'\n            </button>\n          </div>\n        </div>\n\n        <div class="Filters-typeItem Filters-typeItem--searchField">\n          <form class="Filters-searchForm js-search-form" action="#">\n            <input class="Filters-searchInput CDB-Text CDB-Size-medium js-search-input" type="text" value="'+
((__t=( searchQuery ))==null?'':_.escape(__t))+
'" placeholder="'+
((__t=( _t('components.modals.add-basemap.wms.placeholder', { layersFoundCount: layersFound.length, layersFoundCountPluralize: _t('components.modals.add-basemap.wms.tables-pluralize', { smart_count: layersFound.length }), layersAvailableCount: layersAvailableCount, layersAvailableCountPluralize: _t('components.modals.add-basemap.wms.tables-pluralize', { smart_count: layersAvailableCount }) }) ))==null?'':_.escape(__t))+
'" />\n            <button type="button" class="Filters-cleanSearch js-clean-search u-actionTextColor">\n              <i class="CDB-IconFont CDB-IconFont-close"></i>\n            </button>\n          </form>\n        </div>\n\n        <span class="Filters-separator"></span>\n      </div>\n    </div>\n  </div>\n\n  ';
 if (searchQuery && layersFound.length == 0) { 
__p+='\n    <div class="IntermediateInfo">\n      <div class="LayoutIcon">\n        <i class="CDB-IconFont CDB-IconFont-defaultUser"></i>\n      </div>\n      <h4 class="CDB-Text CDB-Size-large u-mainTextColor u-bSpace u-secondaryTextColor u-tSpace-xl">'+
((__t=( _t('components.modals.add-basemap.wms.oh-no') ))==null?'':_.escape(__t))+
'</h4>\n      <p class="CDB-Text CDB-Size-medium u-altTextColor">'+
((__t=( _t('components.modals.add-basemap.wms.unfortunately') ))==null?'':_.escape(__t))+
'</p>\n    </div>\n  ';
 } else { 
__p+='\n    <div class="js-layers"></div>\n  ';
 } 
__p+='\n</div>\n\n<button class="NavButton Dialog-backBtn js-back">\n  <i class="CDB-IconFont CDB-IconFont-arrowPrev"></i>\n</button>\n';
}
return __p;
};
