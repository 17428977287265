var _ = require('underscore');
module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<div class="CDB-Box-modalHeader">\n  <ul class="CDB-Box-modalHeaderItem CDB-Box-modalHeaderItem--block CDB-Box-modalHeaderItem--paddingHorizontal">\n    <li class="CDB-ListDecoration-item CDB-ListDecoration-itemDisplay--flex CDB-Text CDB-Size-medium u-secondaryTextColor">\n      <div class=\'CDB-ListDecoration-secondaryContainer\'>\n        <button class="u-rSpace u-actionTextColor js-back">\n          <i class="CDB-IconFont CDB-IconFont-arrowPrev Size-large"></i>\n        </button>\n        <span class="label js-label">'+
((__t=( label ))==null?'':_.escape(__t))+
'</span>\n      </div>\n\n      <div class=\'CDB-ListDecoration-secondaryContainer\'>\n        <nav class=\'CDB-NavMenu\'>\n          <ul class=\'CDB-NavMenu-Inner CDB-NavMenu-inner--no-margin js-menu\'>\n            <li class=\'CDB-NavMenu-item\'>\n              <div class=\'CDB-NavMenu-link CDB-ListDecoration-rampNav-item\'>\n                <button class="ColorBar CDB-ListDecoration-rampItemBar u-rSpace--xl js-colorPicker" style="background-color: '+
((__t=( color ))==null?'':__t)+
';" type="button"></button>\n              </div>\n            </li>\n\n            ';
 if (imageEnabled) { 
__p+='\n              <li class=\'CDB-NavMenu-item is-selected\'>\n                <div class=\'CDB-NavMenu-link CDB-ListDecoration-rampNav-item\'>\n                  ';
 if (image) { 
__p+='\n                    <div class=\'CDB-ListDecoration-rampImg js-image-container\'></div>\n                  ';
 } else { 
__p+='\n                    <span class="CDB-ListDecoration-rampImg CDB-Text js-assetPicker">'+
((__t=( _t('form-components.editors.fill.input-color.img') ))==null?'':__t)+
'</span>\n                  ';
 } 
__p+='\n                </div>\n              </li>\n            ';
 } 
__p+='\n          </ul>\n        </nav>\n      </div>\n    </li>\n  </ul>\n</div>\n';
}
return __p;
};
