var _ = require('underscore');
module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<ul class="CodeMirror-error">\n  <li class="CodeMirror-errorMessage u-lSpace--xl u-rSpace--xl">\n    '+
((__t=( _t('components.codemirror.syntax-error') ))==null?'':_.escape(__t))+
': <span>'+
((__t=( message ))==null?'':_.escape(__t))+
'</span>\n  </li>\n  <li class="CodeMirror-errorDocs">\n    <a href="https://carto.com/docs/carto-engine/sql-api/" target="_black">'+
((__t=( _t('components.codemirror.docs') ))==null?'':_.escape(__t))+
'</a>\n  </li>\n</ul>\n';
}
return __p;
};
