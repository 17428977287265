var _ = require('underscore');
module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<div class="u-flex">\n  <span class="SelectorLayer-letter CDB-Text CDB-Size-small u-whiteTextColor u-rSpace u-upperCase" style="background-color: '+
((__t=( color ))==null?'':_.escape(__t))+
';">'+
((__t=( layer_id ))==null?'':_.escape(__t))+
'</span>\n  <p class="CDB-Text CDB-Size-medium u-ellipsis u-flex" title="'+
((__t=( nodeTitle ))==null?'':_.escape(__t))+
' - '+
((__t=( layerName ))==null?'':_.escape(__t))+
'">\n    '+
((__t=( nodeTitle ))==null?'':_.escape(__t))+
' <span class="u-altTextColor u-lSpace u-ellipsis">'+
((__t=( layerName ))==null?'':_.escape(__t))+
'</span>\n  </p>\n</div>\n';
}
return __p;
};
