var _ = require('underscore');
module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<div class="\n    Table-headItemWrapper\n    '+
((__t=( name === 'cartodb_id' || (type === 'geometry' && geometry !== 'point') ? 'Table-headItemWrapper--short' : '' ))==null?'':_.escape(__t))+
'\n  ">\n  <div class="u-flex u-justifySpace">\n    <input class="Table-headItemName CDB-Text CDB-Size-medium is-semibold u-ellipsis js-attribute" value="'+
((__t=( name ))==null?'':_.escape(__t))+
'" title="'+
((__t=( name ))==null?'':_.escape(__t))+
'" readonly />\n\n    ';
 if (isOrderBy) { 
__p+='\n      <i class="CDB-Size CDB-Size-small CDB-IconFont CDB-IconFont-arrowNext\n        Table-columnSorted\n        Table-columnSorted--';
 if (sortBy === 'asc') {
__p+='asc';
 } else {
__p+='desc';
 } 
__p+='\n        u-rSpace u-altTextColor\n      "></i>\n    ';
 } 
__p+='\n\n    <button class="CDB-Shape-threePoints is-blue is-small js-options">\n      <div class="CDB-Shape-threePointsItem"></div>\n      <div class="CDB-Shape-threePointsItem"></div>\n      <div class="CDB-Shape-threePointsItem"></div>\n    </button>\n  </div>\n  <p class="CDB-Size-small Table-headItemInfo u-altTextColor">'+
((__t=( type ))==null?'':_.escape(__t))+
'</p>\n</div>\n';
}
return __p;
};
