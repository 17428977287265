var _ = require('underscore');
module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<div class="u-flex u-justifyCenter">\n  <div class="Modal-inner Modal-inner--grid u-flex u-justifyCenter">\n    <div class="Modal-icon">\n      <svg width="24px" height="25px" viewbox="521 436 24 25" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">\n        <path d="M524.5,440 L540.5,440 L540.5,460 L524.5,460 L524.5,440 Z M528.5,437 L536.5,437 L536.5,440 L528.5,440 L528.5,437 Z M522,440 L544,440 L522,440 Z M528.5,443.5 L528.5,455.5 L528.5,443.5 Z M532.5,443.5 L532.5,455.5 L532.5,443.5 Z M536.5,443.5 L536.5,455.5 L536.5,443.5 Z" id="Shape" stroke="#F19243" stroke-width="1" fill="none"/>\n      </svg>\n    </div>\n    <div>\n      <h2 class=" CDB-Text CDB-Size-huge is-light u-bSpace--xl">\n     '+
((__t=( _t('editor.maps.delete.title', { name: name }) ))==null?'':_.escape(__t))+
'</h2>\n      <p class="CDB-Text CDB-Size-large u-altTextColor">'+
((__t=( _t('editor.maps.delete.desc') ))==null?'':_.escape(__t))+
'</p>\n      <ul class="Modal-listActions u-flex u-alignCenter">\n        <li class="Modal-listActionsitem">\n          <button class="CDB-Button CDB-Button--secondary CDB-Button--big js-cancel">\n            <span class="CDB-Button-Text CDB-Text is-semibold CDB-Size-medium u-upperCase">\n              '+
((__t=( _t('editor.maps.delete.cancel') ))==null?'':_.escape(__t))+
'\n            </span>\n          </button>\n        </li>\n        <li class="Modal-listActionsitem">\n          <button class="CDB-Button CDB-Button--primary CDB-Button--big js-confirm">\n            <span class="CDB-Button-Text CDB-Text is-semibold CDB-Size-medium u-upperCase">\n              '+
((__t=( _t('editor.maps.delete.confirm') ))==null?'':_.escape(__t))+
'\n            </span>\n          </button>\n        </li>\n      </ul>\n    </div>\n  </div>\n</div>\n';
}
return __p;
};
