var _ = require('underscore');
module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='';
 if (type === 'primary') { 
__p+='\n  <button class="CDB-Button CDB-Button--primary CDB-Button--medium ';
 if (disabled) { 
__p+='is-disabled';
 } 
__p+=' js-'+
((__t=( action ))==null?'':_.escape(__t))+
' js-action">\n    <span class="CDB-Button-Text CDB-Text is-semibold u-upperCase CDB-Size-small">'+
((__t=( label ))==null?'':__t)+
'</span>\n  </button>\n';
 } else if (type === 'secondary') { 
__p+='\n  <button class="CDB-Button CDB-Button--secondary CDB-Button--small ';
 if (disabled) { 
__p+='is-disabled';
 } 
__p+=' js-'+
((__t=( action ))==null?'':_.escape(__t))+
' js-action">\n    <span class="CDB-Button-Text CDB-Text is-semibold u-upperCase CDB-Size-small">'+
((__t=( label ))==null?'':__t)+
'</span>\n  </button>\n';
 } else { 
__p+='\n  <button class="Infobox-buttonLink u-upperCase ';
 if (disabled) { 
__p+='is-disabled';
 } 
__p+=' js-'+
((__t=( action ))==null?'':_.escape(__t))+
' js-action CDB-Text is-semibold CDB-Size-small">\n    '+
((__t=( label ))==null?'':__t)+
'\n  </button>\n';
 } 
__p+='\n\n\n';
}
return __p;
};
