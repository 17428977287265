var _ = require('underscore');
module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<div class="Editor-HeaderInfoEditor">\n  <div class="Editor-HeaderInfo-inner Editor-HeaderInfo-inner--wide">\n    <div class="Editor-HeaderInfo-title u-bSpace--m js-context-menu">\n      <div class="Editor-HeaderInfo-titleText u-bSpace js-header">\n      </div>\n    </div>\n    <div class="u-bSpace--xl u-flex u-alignCenter">\n      <button class="u-rSpace--m u-actionTextColor js-privacy">\n        <i class="Tag Tag-fill Tag-fill--'+
((__t=( cssClass ))==null?'':_.escape(__t))+
' CDB-Text CDB-Size-small u-upperCase">'+
((__t=( privacy ))==null?'':_.escape(__t))+
'</i>\n      </button>\n      ';
 if (!isSimple) { 
__p+='\n      <div class="js-share-users"></div>\n      ';
 } 
__p+='\n      <p class="Editor-HeaderInfo-publishDate u-ellipsis CDB-Text CDB-Size-medium u-altTextColor">'+
((__t=( published ))==null?'':_.escape(__t))+
'</p>\n    </div>\n  </div>\n</div>\n';
}
return __p;
};
