var _ = require('underscore');
module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<div class="Editor-ListAnalysis-itemInfo u-rSpace--m CDB-Text is-semibold CDB-Size-small u-altTextColor">\n  '+
((__t=( id ))==null?'':_.escape(__t))+
'\n  <div class="CDB-Shape Editor-ListAnalysis-itemInfoIcon">\n    <ul class="CDB-Shape-Dataset is-small is-grey">\n      <li class="CDB-Shape-DatasetItem"></li>\n      <li class="CDB-Shape-DatasetItem"></li>\n    </ul>\n  </div>\n</div>\n<p class="CDB-Text CDB-Size-small u-secondaryTextColor Editor-ListAnalysis-itemInfoDataset u-ellipsis">\n  '+
((__t=( tableName ))==null?'':_.escape(__t))+
'\n</p>\n';
 if (isSync) { 
__p+='\n  <span class="Editor-ListAnalysis-itemInfoIcon">\n    <div class="u-flex u-alignCenter CDB-Text CDB-Size-small u-altTextColor SyncInfo-message--'+
((__t=( state ))==null?'':_.escape(__t))+
' js-sync" data-tooltip="';
 if (errorCode || errorMessage) { 
__p+=''+
((__t=( _t('dataset.sync.sync-failed') ))==null?'':_.escape(__t))+
'';
 } else { 
__p+=''+
((__t=( ranAt ))==null?'':_.escape(__t))+
'';
 } 
__p+='">\n      <i class="CDB-IconFont CDB-IconFont-wifi"></i>\n    </div>\n  </span>\n';
 } 
__p+='\n';
 if (customQueryApplied) { 
__p+='\n  <span class="Editor-ListAnalysis-itemInfoIcon Tag Tag--outline Tag-outline--dark CDB-Text CDB-Size-small">\n    SQL\n  </span>\n';
 } 
__p+='\n';
}
return __p;
};
