var _ = require('underscore');
module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<div class="ColorBar ColorBar--inline" style="background-color: '+
((__t=( color ))==null?'':_.escape(__t))+
';"></div> ';
 if (selectedChild) { 
__p+=' <span class="CDB-NavSubmenu-status js-NavSubmenu-status u-hintTextColor">'+
((__t=( selectedChild ))==null?'':_.escape(__t))+
'</span>';
 } 
__p+='\n';
}
return __p;
};
