var _ = require('underscore');
module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='';
 if (type === 'text') { 
__p+='\n'+
((__t=( label ))==null?'':_.escape(__t))+
'';
 if (selectedChild) { 
__p+=' <span class="CDB-NavSubmenu-status js-NavSubmenu-status u-hintTextColor">'+
((__t=( selectedChild ))==null?'':_.escape(__t))+
'</span>';
 } 
__p+='\n';
 } else if (kind === 'custom-marker') { 
__p+='\n<div class=\'Editor-categoryImagesTag CDB-Text CDB-FontSize-small u-altTextColor is-semibold u-upperCase\'>'+
((__t=( _t('form-components.editors.fill.input-color.img') ))==null?'':_.escape(__t))+
'</div>\n';
 } else { 
__p+='\n<div class="Tab-paneLabelImageContainer js-image-container"></div>\n';
 } 
__p+='\n';
}
return __p;
};
