var _ = require('underscore');
module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='';
 if (customRamp && customRamp.length) { 
__p+='\n<li class="CDB-ListDecoration-item CustomList-item CustomRamp js-customRampItem">\n  <div class="CustomRamp-listOptions CDB-ListDecoration-itemLink CDB-ListDecoration-itemLink--double js-customRamp js-listItemLink is-selected">\n    <ul class="ColorBarContainer">\n      ';
 _.each(customRamp, function (color) { 
__p+='\n      <li class="ColorBar ColorBar--spaceless" style="background-color: '+
((__t=( color ))==null?'':_.escape(__t))+
';"></li>\n      ';
 }); 
__p+='\n    </ul>\n  </div>\n  <button class="CDB-ListDecoration-itemLink CustomRamp-clear js-clear">\n    <div class="CDB-Shape">\n      <div class="CDB-Shape-close is-blue is-large"></div>\n    </div>\n  </button>\n</li>\n';
 } 
__p+='\n';
}
return __p;
};
