var _ = require('underscore');
module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<h2 class="CDB-Text CDB-Size-huge is-light u-secondaryTextColor">'+
((__t=( title ))==null?'':_.escape(__t))+
'</h2>\n<p class="CDB-Text u-tSpace--m CDB-Size-medium u-altTextColor">'+
((__t=( desc ))==null?'':__t)+
'</p>\n';
}
return __p;
};
