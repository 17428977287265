var _ = require('underscore');
module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<p class="CDB-Text Onboarding-headerDescription">\n  '+
((__t=( _t('analyses-onboarding.new-column-added') ))==null?'':_.escape(__t))+
'\n</p>\n\n<ul class="Onboarding-list">\n  <li class="Onboarding-listItem">\n    <div class="Onboarding-listItemValue">cluster_no</div>\n    <p class="CDB-Text Onboarding-description">'+
((__t=( _t('analyses-onboarding.kmeans.cluster-no', { clusters: clusters }) ))==null?'':_.escape(__t))+
'</p>\n  </li>\n</ul>\n\n<p class="CDB-Text Onboarding-description">\n  '+
((__t=( _t('analyses-onboarding.kmeans.description') ))==null?'':_.escape(__t))+
'\n</p>\n';
}
return __p;
};
