var _ = require('underscore');
module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<div class="BuilderOnboarding-pad"></div>\n<div class="BuilderOnboarding-pad2"></div>\n<div class="BuilderOnboarding-contentWrapper is-step0 js-step">\n  <div class="BuilderOnboarding-body">\n    <div class="BuilderOnboarding-header is-step0">\n      <p class="CDB-Text Onboarding-headerTitle">'+
((__t=( _t('builder-onboarding.title')))==null?'':_.escape(__t))+
'</p>\n      <p class="CDB-Text Onboarding-headerText">'+
((__t=( name ))==null?'':_.escape(__t))+
'</p>\n\n      <p class="CDB-Text BuilderOnboarding-description">\n        '+
((__t=( _t('builder-onboarding.description')))==null?'':_.escape(__t))+
'\n      </p>\n    </div>\n\n    <div class="BuilderOnboarding-footer is-step0">\n      <div class="BuilderOnboarding-footerButtons">\n        <button class="CDB-Button CDB-Button--secondary CDB-Button--white CDB-Button--big Onboarding-footer--marginRight js-start">\n          <span class="CDB-Button-Text CDB-Text u-upperCase is-semibold CDB-Size-medium">'+
((__t=( _t('builder-onboarding.take-tour')))==null?'':_.escape(__t))+
'</span>\n        </button>\n\n        <button class="CDB-Button CDB-Button--primary CDB-Button--big js-close">\n          <span class="CDB-Button-Text CDB-Text u-upperCase is-semibold CDB-Size-medium">'+
((__t=( _t('builder-onboarding.edit-map')))==null?'':_.escape(__t))+
'</span>\n        </button>\n      </div>\n\n      <div class="u-iBlock is-step1">\n        <input class="CDB-Checkbox js-forget" type="checkbox" id="forget-me" name="forget-me" value="true">\n        <span class="u-iBlock CDB-Checkbox-face"></span>\n        <label for="forget-me" class="BuilderOnboarding-forgetLabel Checkbox-label CDB-Text CDB-Size-small u-altTextColor u-lSpace">'+
((__t=( _t('analyses-onboarding.never-show-message') ))==null?'':_.escape(__t))+
'</label>\n      </div>\n    </div>\n\n    <div class="BuilderOnboarding-step is-step1">\n      <p class="CDB-Text Onboarding-headerTitle">1/4</p>\n      <p class="CDB-Text Onboarding-headerText">'+
((__t=( _t('builder-onboarding.toolbar.title')))==null?'':_.escape(__t))+
'</p>\n      <p class="CDB-Text BuilderOnboarding-description">\n        '+
((__t=( _t('builder-onboarding.toolbar.description')))==null?'':_.escape(__t))+
'\n      </p>\n    </div>\n\n    <div class="BuilderOnboarding-step is-step2">\n      <p class="CDB-Text Onboarding-headerTitle">2/4</p>\n      <p class="CDB-Text Onboarding-headerText">'+
((__t=( _t('builder-onboarding.configurator.title')))==null?'':_.escape(__t))+
'</p>\n      <p class="CDB-Text BuilderOnboarding-description">\n        '+
((__t=( _t('builder-onboarding.configurator.description')))==null?'':_.escape(__t))+
'\n      </p>\n    </div>\n\n    <div class="BuilderOnboarding-step is-step3">\n      <p class="CDB-Text Onboarding-headerTitle">3/4</p>\n      <p class="CDB-Text Onboarding-headerText">'+
((__t=( _t('builder-onboarding.map.title')))==null?'':_.escape(__t))+
'</p>\n      <p class="CDB-Text BuilderOnboarding-description">\n        '+
((__t=( _t('builder-onboarding.map.description')))==null?'':_.escape(__t))+
'\n      </p>\n    </div>\n\n    <div class="BuilderOnboarding-step is-step4">\n      <p class="CDB-Text Onboarding-headerTitle">4/4</p>\n      <p class="CDB-Text Onboarding-headerText">'+
((__t=( _t('builder-onboarding.widgets.title')))==null?'':_.escape(__t))+
'</p>\n      <p class="CDB-Text BuilderOnboarding-description">\n        '+
((__t=( _t('builder-onboarding.widgets.description')))==null?'':_.escape(__t))+
'\n      </p>\n    </div>\n\n    <div class="BuilderOnboarding-footer is-step1 is-step2 is-step3">\n      <div class="BuilderOnboarding-footerButtons">\n        <button class="CDB-Button CDB-Button--secondary CDB-Button--white CDB-Button--big Onboarding-footer--marginRight js-close">\n          <span class="CDB-Button-Text CDB-Text u-upperCase is-semibold CDB-Size-medium">'+
((__t=( _t('builder-onboarding.skip')))==null?'':_.escape(__t))+
'</span>\n        </button>\n\n        <button class="CDB-Button CDB-Button--primary CDB-Button--big js-next">\n          <span class="CDB-Button-Text CDB-Text u-upperCase is-semibold CDB-Size-medium">'+
((__t=( _t('builder-onboarding.next')))==null?'':_.escape(__t))+
'</span>\n        </button>\n      </div>\n    </div>\n\n    <div class="BuilderOnboarding-footer is-step4">\n      <div class="BuilderOnboarding-footerButtons">\n        <button class="CDB-Button CDB-Button--primary CDB-Button--big js-close">\n          <span class="CDB-Button-Text CDB-Text u-upperCase is-semibold CDB-Size-medium">'+
((__t=( _t('builder-onboarding.edit-map')))==null?'':_.escape(__t))+
'</span>\n        </button>\n      </div>\n    </div>\n  </div>\n</div>\n\n<div class="BuilderOnboarding-pad3"></div>\n<div class="BuilderOnboarding-pad4"></div>\n<div class="BuilderOnboarding-pad5"></div>\n<div class="BuilderOnboarding-shadow"></div>\n';
}
return __p;
};
