var _ = require('underscore');
module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<div class="CodeMirror-editor">\n  <textarea class="js-editor">'+
((__t=( content ))==null?'':_.escape(__t))+
'</textarea>\n</div>\n<div class="CodeMirror-console js-console">\n  '+
((__t=( tip ))==null?'':_.escape(__t))+
'\n  <div class="js-console-error"></div>\n</div>';
}
return __p;
};
