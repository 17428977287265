var _ = require('underscore');
module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<div class="IntermediateInfo">\n  <div class="LayoutIcon LayoutIcon--negative">\n    <i class="CDB-IconFont CDB-IconFont-cockroach"></i>\n  </div>\n  <h4 class="CDB-Text CDB-Size-large u-mainTextColor u-secondaryTextColor u-bSpace--m">\n    '+
((__t=( _t('components.modals.add-layer.datasets.error.title') ))==null?'':_.escape(__t))+
'\n  </h4>\n  <p class="CDB-Text CDB-Size-medium u-altTextColor">'+
((__t=( _t('components.modals.add-layer.datasets.error.desc') ))==null?'':_.escape(__t))+
' <a class="js-mail-link" href="mailto:support@carto.com">support@carto.com</a>.</p>\n</div>\n';
}
return __p;
};
