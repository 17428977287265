var _ = require('underscore');
module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<button class="CDB-Button u-upperCase js-back">\n  <span class="CDB-Button-Text CDB-Text is-semibold CDB-Size-small u-actionTextColor">'+
((__t=( _t('editor.edit-feature.cancel') ))==null?'':_.escape(__t))+
'</span>\n</button>\n';
}
return __p;
};
