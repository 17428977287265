var _ = require('underscore');
module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<label class="CDB-Text CDB-Size CDB-Size-small u-secondaryTextColor">'+
((__t=( quotaMessage ))==null?'':_.escape(__t))+
'</label>\n<div class="Infobox-quotaBar Progress u-tSpace">\n  <div class="Infobox-quotaBarProgress Progress-bar Progress-bar--'+
((__t=( progressState ))==null?'':_.escape(__t))+
'"\n    style="width: '+
((__t=( quotaPer ))==null?'':_.escape(__t))+
'%"\n  ></div>\n</div>\n';
}
return __p;
};
