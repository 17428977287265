var _ = require('underscore');
module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<div class="Notifier-item Notifier-item--'+
((__t=( status ))==null?'':_.escape(__t))+
'">\n\n  ';
 if (status === 'loading') { 
__p+='\n  <div class="Notifier-icon CDB-LoaderIcon is-blue js-theme u-rSpace--m">\n    <svg class="CDB-LoaderIcon-spinner" viewBox="0 0 50 50">\n      <circle class="CDB-LoaderIcon-path" cx="25" cy="25" r="20" fill="none"></circle>\n    </svg>\n  </div>\n  ';
 } 
__p+='\n\n  ';
 if (status === 'success') { 
__p+='\n  <div class="Notifier-icon CDB-Shape u-rSpace--m">\n    <div class="CDB-Shape-CircleItem CDB-Shape-CircleItem--fill is-green">\n      <div class="CDB-Shape-tick is-medium is-white"></div>\n    </div>\n  </div>\n  ';
 } 
__p+='\n\n  ';
 if (status === 'error' || status === 'warning') { 
__p+='\n  <div class="Notifier-icon CDB-Shape u-rSpace--m">\n    <div class="CDB-Shape-CircleItem CDB-Shape-CircleItem--fill is-red">\n      <div class="CDB-Shape-close is-medium is-white"></div>\n    </div>\n  </div>\n  ';
 } 
__p+='\n\n  <div class="Notifier-info">\n    <p class="CDB-Text CDB-Size-medium">'+
((__t=( info ))==null?'':__t)+
' ';
 if (isActionable) { 
__p+=' <span class="js-actionButton"></span> ';
 } 
__p+='</p>\n  </div>\n\n  ';
 if (isClosable) { 
__p+='\n    <div class="Notifier-actions js-closeButton"></div>\n  ';
 } 
__p+='\n</div>\n';
}
return __p;
};
