var _ = require('underscore');
module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<button class="Publish-modalButton CDB-Button CDB-Button--primary CDB-Button--small u-rSpace js-button ';
 if (isDisabled) {
__p+='is-disabled';
 } 
__p+='">\n  <div class="CDB-Button-Text CDB-Text is-semibold CDB-Size-small u-upperCase u-flex">\n    ';
 if (isLoading) { 
__p+='\n      <div class="CDB-LoaderIcon CDB-LoaderIcon--small u-iBlock">\n        <svg class="CDB-LoaderIcon-spinner" viewBox="0 0 50 50">\n          <circle class="CDB-LoaderIcon-path" cx="25" cy="25" r="20" fill="none"></circle>\n        </svg>\n      </div>\n      ';
 if (isPublished) { 
__p+='\n      '+
((__t=( _t('components.modals.publish.updating-btn') ))==null?'':_.escape(__t))+
'\n      ';
 } else { 
__p+='\n      '+
((__t=( _t('components.modals.publish.publishing-btn') ))==null?'':_.escape(__t))+
'\n      ';
 } 
__p+='\n    ';
 } else { 
__p+='\n      ';
 if (isPublished) { 
__p+='\n      '+
((__t=( _t('components.modals.publish.update-btn') ))==null?'':_.escape(__t))+
'\n      ';
 } else { 
__p+='\n      '+
((__t=( _t('components.modals.publish.publish-btn') ))==null?'':_.escape(__t))+
'\n      ';
 } 
__p+='\n    ';
 } 
__p+='\n  </div>\n</button> '+
((__t=( publishedOn ))==null?'':_.escape(__t))+
'';
}
return __p;
};
