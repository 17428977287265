var _ = require('underscore');
module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<div class="u-flex js-content"></div>\n';
 if (people > 0) { 
__p+='\n  <div class="u-secondaryTextColor CDB-Text CDB-Size-small u-lSpace">+ '+
((__t=( people ))==null?'':_.escape(__t))+
'</div>\n';
 } 
__p+='';
}
return __p;
};
