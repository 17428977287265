var _ = require('underscore');
module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='';
 if (type === 'icon') { 
__p+='\n<div class="AssetItem-icon js-asset"><img height="24" src="'+
((__t=( public_url ))==null?'':_.escape(__t))+
'" alt="'+
((__t=( name ))==null?'':_.escape(__t))+
'" crossOrigin="anonymous" /></div>\n';
 } else { 
__p+='\n<div class="AssetItem-label CDB-Text CDB-Size-small u-altTextColor u-upperCase js-asset" title="'+
((__t=( name ))==null?'':_.escape(__t))+
'">\n  '+
((__t=( name ))==null?'':_.escape(__t))+
'\n</div>\n';
 } 
__p+='\n';
}
return __p;
};
