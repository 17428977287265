var _ = require('underscore');
module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<form class="Form js-form">\n  <div class="Form-row">\n    <div class="Form-rowLabel">\n      <label class="CDB-Text CDB-Size-medium">'+
((__t=( _t('components.modals.add-layer.imports.form-import.title') ))==null?'':_.escape(__t))+
'</label>\n    </div>\n    <div class="Form-rowData Form-rowData--longer">\n      <input type="text" class="CDB-Text CDB-Size-medium Form-input Form-input--longer has-submit js-textInput" value="" placeholder="'+
((__t=( _t('components.modals.add-layer.imports.arcgis.input-placeholder', { brand: 'ArcGIS Server&trade;' }) ))==null?'':__t)+
'" />\n      <button type="submit" class="CDB-Text CDB-Size-small Form-inputSubmit u-upperCase u-actionTextColor Form-inputSubmit">\n        <span>'+
((__t=( _t('components.modals.add-layer.imports.form-import.submit') ))==null?'':_.escape(__t))+
'</span>\n      </button>\n      <div class="Form-inputError CDB-Text">'+
((__t=( _t('components.modals.add-layer.imports.form-import.error-desc') ))==null?'':_.escape(__t))+
'</div>\n    </div>\n  </div>\n  <div class="Form-row">\n    <div class="Form-rowLabel"></div>\n    <div class="Form-rowData Form-rowData--longer">\n      <p class="CDB-Text CDB-Size-small Form-rowInfoText--centered Form-rowInfoText--block u-altTextColor">\n        '+
((__t=( _t('components.modals.add-layer.imports.form-import.format') ))==null?'':_.escape(__t))+
': http://&#60;host&#62;/arcgis/rest/services/&#60;folder&#62;/&#60;serviceName&#62;/&#60;serviceType&#62;<br/>\n        '+
((__t=( _t('components.modals.add-layer.imports.arcgis.url-desc') ))==null?'':_.escape(__t))+
'\n      </p>\n    </div>\n  </div>\n</form>\n';
}
return __p;
};
