var _ = require('underscore');
module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<div class="Dialog-header ErrorDetails-header">\n  <div class="Dialog-headerIcon Dialog-headerIcon--alert">\n    <i class="CDB-IconFont CDB-IconFont-cloud"></i>\n  </div>\n  <p class="Dialog-headerTitle--warning">\n    '+
((__t=( _t('components.background-importer.connector-warning-details.too-many-rows') ))==null?'':_.escape(__t))+
'\n  </p>\n  <p class="Dialog-headerText">\n    '+
((__t=( _t('components.background-importer.connector-warning-details.unable-to-import-all-rows', { maxRowsPerConnectorImport: maxRowsPerConnectorImport}) ))==null?'':_.escape(__t))+
'<br />\n  </p>\n</div>\n<div class="Dialog-footer ErrorDetails-footer">\n  <button class="Button Button--secondary ErrorDetails-footerButton u-upperCase js-close">\n    <span>'+
((__t=( _t('components.background-importer.connector-warning-details.continue-btn') ))==null?'':_.escape(__t))+
'</span>\n  </button>\n</div>\n';
}
return __p;
};
