var InfowindowDefinitionModel = require('./infowindow-definition-model');


module.exports = InfowindowDefinitionModel.extend({

  defaults: {
    vertical_offset: 0,
    horizontal_offset: 0,
    position: 'top|center',
    template_name: '',
    template: ''
  },

  TEMPLATES: {
    tooltip_dark: "<div class=\"CDB-Tooltip CDB-Tooltip--isDark\">\n  <ul class=\"CDB-Tooltip-list\">\n    {{#fields}}\n      <li class=\"CDB-Tooltip-listItem\">\n        {{#title}}\n          <h3 class=\"CDB-Tooltip-listTitle\">{{{ title }}}</h3>\n        {{/title}}\n        <h4 class=\"CDB-Tooltip-listText\">{{{ value }}}</h4>\n      </li>\n    {{/fields}}\n  </ul>\n</div>\n",
    tooltip_light: "<div class=\"CDB-Tooltip CDB-Tooltip--isLight\">\n  <ul class=\"CDB-Tooltip-list\">\n    {{#fields}}\n      <li class=\"CDB-Tooltip-listItem\">\n        {{#title}}\n          <h3 class=\"CDB-Tooltip-listTitle\">{{{ title }}}</h3>\n        {{/title}}\n        <h4 class=\"CDB-Tooltip-listText\">{{{ value }}}</h4>\n      </li>\n    {{/fields}}\n  </ul>\n</div>\n",
    custom_tooltip_dark: "<div class=\"CDB-Tooltip CDB-Tooltip--isDark\">\n  <ul class=\"CDB-Tooltip-list\">\n    {{#content.fields}}\n      <li class=\"CDB-Tooltip-listItem\">\n        {{#title}}\n          <h3 class=\"CDB-Tooltip-listTitle\">{{{ name }}}</h3>\n        {{/title}}\n        <h4 class=\"CDB-Tooltip-listText\">{{=<% %>=}}{{<%={{ }}=%>{{{ name }}}{{=<% %>=}}}}<%={{ }}=%></h4>\n      </li>\n    {{/content.fields}}\n  </ul>\n</div>\n",
    custom_tooltip_light: "<div class=\"CDB-Tooltip CDB-Tooltip--isLight\">\n  <ul class=\"CDB-Tooltip-list\">\n    {{#content.fields}}\n      <li class=\"CDB-Tooltip-listItem\">\n        {{#title}}\n          <h3 class=\"CDB-Tooltip-listTitle\">{{{ name }}}</h3>\n        {{/title}}\n        <h4 class=\"CDB-Tooltip-listText\">{{=<% %>=}}{{<%={{ }}=%>{{{ name }}}{{=<% %>=}}}}<%={{ }}=%></h4>\n      </li>\n    {{/content.fields}}\n  </ul>\n</div>\n"
  },

  setDefault: function () {
    this.setTemplate(this.defaults.template_name);
  }

});
