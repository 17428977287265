var _ = require('underscore');
module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<span class="CDB-Text CDB-Size-medium u-altTextColor Pagination-label">\n  Page '+
((__t=( currentPage ))==null?'':_.escape(__t))+
' of '+
((__t=( pagesCount ))==null?'':_.escape(__t))+
'\n</span>\n<ul class="Pagination-list CDB-Text CDB-Size-medium">\n  ';
 m.pagesToDisplay().forEach(function(page) { 
__p+='\n    ';
 if (page > 0) { 
__p+='\n      <li class="Pagination-listItem '+
((__t=( m.isCurrentPage(page) ? 'is-current' : '' ))==null?'':_.escape(__t))+
'">\n        <button class="Pagination-listItemInner Pagination-listItemInner--link u-actionTextColor js-listItem" data-page="'+
((__t=( page ))==null?'':_.escape(__t))+
'">'+
((__t=( page ))==null?'':_.escape(__t))+
'</button>\n      </li>\n    ';
 } else { 
__p+='\n      <li class="Pagination-listItem Pagination-listItem">\n        <span class="Pagination-listItemInner Pagination-listItemInner--more">&hellip;</span>\n      </li>\n    ';
 } 
__p+='\n  ';
 }) 
__p+='\n</ul>\n';
}
return __p;
};
