var _ = require('underscore');
module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<i class="CDB-IconFont CDB-IconFont-'+
((__t=( icon ))==null?'':_.escape(__t))+
' EditorMenu-navigationIcon"></i>\n';
}
return __p;
};
