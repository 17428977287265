var _ = require('underscore');
module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<h4 class="CDB-Text CDB-Size-large u-mainTextColor u-secondaryTextColor u-bSpace--m u-tSpace-xl">\n  '+
((__t=( _t('components.modals.add-layer.datasets.no-datasets.title') ))==null?'':_.escape(__t))+
'\n</h4>\n<p class="CDB-Text CDB-Size-medium u-altTextColor">\n  ';
 var connectDatasetHTML = '<button class="Button--link js-connect">' + _t('components.modals.add-layer.datasets.no-datasets.connect-datasets') + '</button>'; 
__p+='\n  ';
 var searchHTML = '<strong>' + _t('components.modals.add-layer.datasets.no-datasets.search') + '</strong>'; 
__p+='\n  '+
((__t=( _t('components.modals.add-layer.datasets.no-datasets.desc', {
      connectDataset: connectDatasetHTML,
      search: searchHTML
    }) ))==null?'':_.escape(__t))+
'\n</p>\n<div class="NoDatasets-illustration"></div>\n';
}
return __p;
};
