var _ = require('underscore');
module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<button class="js-dates DatePicker-dates DatePicker-dates--singleDate js-DatePicker-dates has-icon ';
 if (readOnly) { 
__p+=' is-disabled ';
 } 
__p+='">\n  <strong class="js-date-str">'+
((__t=( date ))==null?'':_.escape(__t))+
'</strong>\n  <i class="CDB-IconFont CDB-IconFont-calendar DatePicker-datesIcon"></i>\n</button>\n<div class="DatePicker-simpleDropdown datepickerHidden js-DatePicker-simpleDropdown">\n  <div class="js-calendar DatePicker-calendar DatePicker-calendar--simple"></div>\n</div>\n';
}
return __p;
};
