var _ = require('underscore');
module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<div class="Dialog-header">\n  <div class="Dialog-headerIcon Dialog-headerIcon--negative u-flex u-alignCenter u-justifyCenter">\n    <i class="CDB-IconFont CDB-IconFont-barometer"></i>\n  </div>\n  <h2 class="CDB-Text CDB-Size-large u-bSpace u-errorTextColor">\n    '+
((__t=( _t('components.background-importer.upgrade-errors.' + errorCode + '.title') ))==null?'':_.escape(__t))+
'\n  </h2>\n  <h3 class="CDB-Text CDB-Size-medium u-secondaryTextColor">\n    '+
((__t=( _t('components.background-importer.upgrade-errors.' + errorCode + '.description') ))==null?'':_.escape(__t))+
'\n  </h3>\n</div>\n\n<div class="Dialog-body ErrorDetails-body">\n  <ul class="Modal-containerList">\n    <li class="ErrorDetails-item">\n      <div class="ErrorDetails-itemIcon ErrorDetails-itemIcon--success CDB-Size-big u-flex u-alignCenter u-justifyCenter u-rSpace--xl">\n        <i class="CDB-IconFont CDB-IconFont-rocket"></i>\n      </div>\n      <div class="ErrorDetails-itemText">\n        <p class="CDB-Text CDB-Size-medium">\n          '+
((__t=( _t('components.background-importer.upgrade-errors.' + errorCode + '.info') ))==null?'':_.escape(__t))+
'\n          ';
 if (showTrial) { 
__p+='\n            <br/>\n            <a href="'+
((__t=( upgradeUrl ))==null?'':__t)+
'">'+
((__t=( _t('components.background-importer.free-trial', { days: 14 }) ))==null?'':_.escape(__t))+
'</a>\n          ';
 } 
__p+='\n        </p>\n      </div>\n    </li>\n  </ul>\n</div>\n\n<div class="Dialog-footer--simple u-inner">\n  <a href="'+
((__t=( upgradeUrl ))==null?'':_.escape(__t))+
'" class="CDB-Button CDB-Button--primary u-tSpace--m">\n    <span class="CDB-Button-Text CDB-Text is-semibold CDB-Size-medium u-upperCase">\n      '+
((__t=( _t('components.background-importer.upgrade-errors.upgrade') ))==null?'':_.escape(__t))+
'\n    </span>\n  </a>\n</div>\n';
}
return __p;
};
