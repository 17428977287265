var _ = require('underscore');
module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='';
 if (hasSlider) { 
__p+='\n  <li class="CDB-OptionInput-item CDB-OptionInput-item--noSeparator">\n    <div class="UISlider js-slider"></div>\n  </li>\n';
 } 
__p+='\n<li class="CDB-OptionInput-item">\n  <input type="text" class="CDB-InputText ';
 if (isFormatted) { 
__p+='is-number';
 } 
__p+=' ';
 if (isDisabled) { 
__p+='is-disabled';
 } 
__p+=' js-input" ';
 if (isDisabled) { 
__p+='readonly';
 } 
__p+=' value="'+
((__t=( value ))==null?'':_.escape(__t))+
'" placeholder="'+
((__t=( placeholder ))==null?'':_.escape(__t))+
'" />\n</li>\n';
}
return __p;
};
