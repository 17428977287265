var _ = require('underscore');
module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<div class="u-flex Editor-checkerInput CDB-Text CDB-Size-medium u-rSpace--xl">\n  <input class="CDB-Checkbox js-check" type="checkbox" name="" value="" ';
 if (isChecked) { 
__p+='checked';
 } 
__p+='>\n  <span class="CDB-Checkbox-face u-rSpace--m"></span>\n  <label class="CDB-Text CDB-Size-small u-ellipsis u-upperCase is-semibold u-flex u-alignCenter Editor-formLabel">\n    <span class="u-ellipsis ';
 if (help) { 
__p+=' js-help is-underlined';
 } 
__p+='" ';
 if (help) { 
__p+=' data-tooltip="'+
((__t=( help ))==null?'':_.escape(__t))+
'"';
 } 
__p+='  title="'+
((__t=( label ))==null?'':_.escape(__t))+
'">'+
((__t=( label ))==null?'':_.escape(__t))+
'</span>\n  </label>\n</div>\n<div class="Editor-checkerComponent js-editor"></div>\n';
}
return __p;
};
