var _ = require('underscore');
module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<button type="button" class="CDB-InputText\n  ';
 if (!value) { 
__p+=' is-empty ';
 } 
__p+='\n  u-txt-left js-input">'+
((__t=( value ? value : 'null' ))==null?'':_.escape(__t))+
'</button>\n';
}
return __p;
};
