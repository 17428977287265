var _ = require('underscore');
module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<div class="ConnectDataset-header">\n  <h2 class="CDB-Text CDB-Size-medium u-altTextColor">\n    '+
((__t=( _t('components.modals.add-layer.imports.service-import.found-in', {
      size: size,
      pluralize: pluralize,
      title: title
    }) ))==null?'':_.escape(__t))+
'\n  </h2>\n</div>\n<div class="ServiceList-body">\n  ';
 if (size > 0) { 
__p+='\n    <ul class="ServiceList-items"></ul>\n  ';
 } else { 
__p+='\n    <div class="IntermediateInfo ServiceList-empty">\n      <div class="LayoutIcon">\n        <i class="CDB-IconFont CDB-IconFont-lens"></i>\n      </div>\n      <h4 class="CDB-Text CDB-Size-large u-mainTextColor u-secondaryTextColor u-bSpace--m">\n        '+
((__t=( _t('components.modals.add-layer.imports.service-import.no-results-title') ))==null?'':_.escape(__t))+
'\n      </h4>\n      <p class="CDB-Text CDB-Size-medium u-altTextColor">\n        '+
((__t=( _t('components.modals.add-layer.imports.service-import.no-results-desc') ))==null?'':_.escape(__t))+
'\n      </p>\n    </div>\n  ';
 } 
__p+='\n</div>\n';
}
return __p;
};
