var _ = require('underscore');
module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<h3 class="CDB-Text CDB-Size-large u-mainTextColor u-secondaryTextColor u-bSpace--m">\n  ';
 if (state === 'selected') { 
__p+='\n    '+
((__t=( _t('components.modals.add-layer.imports.header-import.file-selected') ))==null?'':_.escape(__t))+
'\n  ';
 } else { 
__p+='\n    '+
((__t=( _t('components.modals.add-layer.imports.header-import.upload-file-url', { smart_count: fileEnabled ? 1 : 0 }) ))==null?'':_.escape(__t))+
'\n  ';
 } 
__p+='\n</h3>\n<p class="CDB-Text CDB-Size-medium u-altTextColor">\n  ';
 if (state !== "selected") { 
__p+='\n    ';
 fileEnabledText = _t('components.modals.add-layer.imports.header-import.select-a-file') +
      ' <a href="https://carto.com/docs/carto-engine/import-api/importing-geospatial-data/#supported-geospatial-data-formats">' +
      _t('components.modals.add-layer.imports.header-import.see-all-formats') +
      '</a>'
    
__p+='\n    '+
((__t=( _t('components.modals.add-layer.imports.header-import.paste-url', {
      fileEnabled: fileEnabled ? fileEnabledText : ''
    }) ))==null?'':__t)+
'\n  ';
 } 
__p+='\n  ';
 if (state === "selected") { 
__p+='\n    ';
 if (acceptSync) { 
__p+='\n      '+
((__t=( _t('components.modals.add-layer.imports.header-import.sync-enabled') ))==null?'':_.escape(__t))+
'\n    ';
 } else { 
__p+='\n      '+
((__t=( _t('components.modals.add-layer.imports.header-import.sync-disabled') ))==null?'':_.escape(__t))+
'\n    ';
 } 
__p+='\n  ';
 } 
__p+='\n</p>\n';
 if (state === "selected") { 
__p+='\n  <button class="NavButton NavButton--back ImportPanel-headerButton js-back">\n    <i class="CDB-IconFont CDB-IconFont-arrowPrev"></i>\n  </button>\n';
 } 
__p+='\n';
}
return __p;
};
