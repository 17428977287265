var _ = require('underscore');
module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<div class="Editor-HeaderInfo">\n  <div class="Editor-HeaderNumeration CDB-Text is-semibold u-rSpace--m">1</div>\n  <div class="Editor-HeaderInfo-inner CDB-Text js-content">\n    <div class="Editor-HeaderInfo-title u-bSpace--m">\n      <h2 class="CDB-Text CDB-HeaderInfo-titleText CDB-Size-large">\n        '+
((__t=( _t('editor.layers.analysis-form.workflow') ))==null?'':_.escape(__t))+
'\n        <span class="CDB-Text CDB-HeaderInfo-titleText CDB-Size-large u-altTextColor">('+
((__t=( layerAnalysisCount ))==null?'':_.escape(__t))+
')</span>\n      </h2>\n    </div>\n    <p class="CDB-Text u-upperCase CDB-FontSize-small u-altTextColor u-bSpace--xl">\n      '+
((__t=( _t('editor.layers.layer.analysis') ))==null?'':_.escape(__t))+
' '+
((__t=( selectedNodeId ))==null?'':_.escape(__t))+
'\n      ';
 if (canDelete) { 
__p+='\n        <button class="js-delete u-actionTextColor u-upperCase">'+
((__t=( deleteLabel ))==null?'':_.escape(__t))+
'</button>\n      ';
 } 
__p+='\n    </p>\n  </div>\n</div>\n';
}
return __p;
};
