var _ = require('underscore');
module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<div class="CDB-Box-modalHeader">\n  <ul class="CDB-Box-modalHeaderItem CDB-Box-modalHeaderItem--block CDB-Box-modalHeaderItem--paddingHorizontal">\n    <li class="CDB-ListDecoration-item CDB-ListDecoration-itemDisplay--flex CDB-Text CDB-Size-medium u-secondaryTextColor">\n      <div class=\'CDB-ListDecoration-secondaryContainer\'>\n        <button class="u-rSpace u-actionTextColor js-back" type="button">\n          <i class="CDB-IconFont CDB-IconFont-arrowPrev Size-large"></i>\n        </button>\n        <span class="label js-label">'+
((__t=( label ))==null?'':_.escape(__t))+
'</span>\n      </div>\n\n      ';
 if (isCategorized && imageEnabled) { 
__p+='\n        <div class=\'CDB-ListDecoration-secondaryContainer\'>\n          <nav class=\'CDB-NavMenu\'>\n            <ul class=\'CDB-NavMenu-Inner CDB-NavMenu-inner--no-margin js-menu\'>\n              <li class=\'CDB-NavMenu-item is-selected\'>\n                <div class=\'CDB-NavMenu-link CDB-ListDecoration-rampNav-item\'>\n                  <button class=\'ColorBar CDB-ListDecoration-rampItemBar u-rSpace--xl js-colorPicker\' type="button" style="background-color: '+
((__t=( color ))==null?'':__t)+
';"></button>\n                </div>\n              </li>\n              <li class=\'CDB-NavMenu-item\'>\n                <div class=\'CDB-NavMenu-link CDB-ListDecoration-rampNav-item\'>\n                  ';
 if (image) { 
__p+='\n                    <button class="CDB-ListDecoration-rampImg js-image-container" type="button"></button>\n                  ';
 } else { 
__p+='\n                    <button class="CDB-ListDecoration-rampImg CDB-Text u-actionTextColor js-assetPicker" type="button">'+
((__t=( _t('form-components.editors.fill.input-color.img') ))==null?'':__t)+
'</button>\n                  ';
 } 
__p+='\n                </div>\n              </li>\n            </ul>\n          </nav>\n        </div>\n      ';
 } 
__p+='\n    </li>\n  </ul>\n</div>\n\n<div class="CDB-Box-modalHeader">\n  <ul class="CDB-Box-modalHeaderItem CDB-Box-modalHeaderItem--block CDB-Box-modalHeaderItem--paddingHorizontal">\n    <li class="CDB-ListDecoration-item CDB-ListDecoration-itemPadding--vertical CDB-Text CDB-Size-medium u-secondaryTextColor">\n      <ul class="ColorBarContainer ColorBarContainer--rampEditing">\n        ';
 _.each(ramp, function (color, i) { 
__p+='\n        <li class="ColorBar is-link ColorBar--spaceless js-color'+
((__t=( i === index ? ' is-selected' : '' ))==null?'':_.escape(__t))+
'" data-label="'+
((__t=( color.title ))==null?'':_.escape(__t))+
'" data-color="'+
((__t=( color.color ))==null?'':_.escape(__t))+
'" style="background-color: '+
((__t=( color.color ))==null?'':_.escape(__t))+
';"></li>\n        ';
 }); 
__p+='\n      </ul>\n      <div class="OpacityEditor">\n        <div class="OpacityEditor-slider js-slider"></div>\n        <div class="OpacityEditor-inputWrapper">\n          <input type="text" class="CDB-InputText ColorPicker-input js-a" value="'+
((__t=( opacity ))==null?'':_.escape(__t))+
'">\n        </div>\n      </div>\n    </li>\n  </ul>\n</div>\n';
}
return __p;
};
