var _ = require('underscore');
module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<div class="DatasetsList-itemCategory is--'+
((__t=( isRaster ? 'raster' : geometryType ))==null?'':_.escape(__t))+
'Dataset">\n  <i data-title="Public dataset" class="CDB-IconFont CDB-IconFont-book DatasetsList-itemStatus '+
((__t=( canImportDataset ? 'is-public' : 'is-banned' ))==null?'':_.escape(__t))+
'"></i>\n</div>\n<div class="ModalDataset-itemInfo">\n  <div class="ModalDataset-itemInfoTitle">\n    <h3 class="CDB-Text CDB-Size-large u-bSpace u-ellipsis">'+
((__t=( title ))==null?'':_.escape(__t))+
'</h3>\n    ';
 if (description && description.length > 0) { 
__p+='\n      <p class="u-ellipsis CDB-Text CDB-Size-medium u-altTextColor" title="'+
((__t=( description ))==null?'':_.escape(__t))+
'">'+
((__t=( description ))==null?'':_.escape(__t))+
'</p>\n    ';
 } else { 
__p+='\n      <span class="NoResults CDB-Text CDB-Size-medium">'+
((__t=( _t('components.modals.add-layer.datasets.item.no-description')))==null?'':_.escape(__t))+
'</span>\n    ';
 } 
__p+='\n  </div>\n  <div>\n    <ul class="DatasetsList-itemMeta CDB-Text CDB-Size-small u-altTextColor">\n      ';
 if (rowCount) { 
__p+='\n        <li class="RowsIndicator">\n          '+
((__t=( rowCountFormatted ))==null?'':_.escape(__t))+
' '+
((__t=( _t('components.modals.add-layer.datasets.item.rows-pluralize', { smart_count: rowCount }) ))==null?'':_.escape(__t))+
'\n        </li>\n      ';
 } 
__p+='\n      ';
 if (datasetSize) { 
__p+='\n        <li class="SizeIndicator">\n          '+
((__t=( datasetSize ))==null?'':_.escape(__t))+
'\n        </li>\n      ';
 } 
__p+='\n      <li class="DatasetsList-itemTimeDiff DefaultTimeDiff CDB-Text CDB-Size-small u-altTextColor">\n        '+
((__t=( timeDiff ))==null?'':_.escape(__t))+
' <span class="DatasetsList-itemSource js-source">';
 if (source) { 
__p+=''+
((__t=( _t('components.modals.add-layer.datasets.item.from') ))==null?'':_.escape(__t))+
' '+
((__t=( cdb.core.sanitize.html(source) ))==null?'':__t)+
'';
 } 
__p+='</span>\n      </li>\n    </ul>\n    <div class="DatasetsList-itemMeta DatasetsList-itemTags">\n      ';
 if (tagsCount > 0) { 
__p+='\n        <div class="DefaultTags CDB-Text CDB-Size-small">\n          ';
 for (var i = 0, l = Math.min(maxTagsToShow, tags.length); i < l; ++i) { 
__p+='\n            <button class="CDB-Text CDB-Size-small u-upperCase DefaultTags-item js-tag-link u-actionTextColor" value="'+
((__t=( tags[i] ))==null?'':_.escape(__t))+
'">'+
((__t=( tags[i] ))==null?'':_.escape(__t))+
'</button>';
 if (i !== (l-1)) { 
__p+='';
 } 
__p+='\n          ';
 } 
__p+='\n          ';
 if (tagsCount > maxTagsToShow) { 
__p+='\n            '+
((__t=( _t('components.modals.add-layer.datasets.item.tags-more', { tagsCount: tagsCount - maxTagsToShow }) ))==null?'':_.escape(__t))+
'\n          ';
 } 
__p+='\n        </div>\n      ';
 } else { 
__p+='\n        <span class="NoResults CDB-Text CDB-Size-small u-altTextColor">'+
((__t=( _t('components.modals.add-layer.datasets.item.no-tags') ))==null?'':_.escape(__t))+
'</span>\n      ';
 } 
__p+='\n    </div>\n  </div>\n</div>\n';
}
return __p;
};
