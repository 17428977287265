var _ = require('underscore');
module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<div class="u-grow  LegendItems-container">\n  <div data-items class="u-bSpace--xl">\n\n  </div>\n  <div class="u-flex u-justifyEnd" style="width: 100%;"> <!-- We need a fucking u-full helper -->\n    <button class="CDB-Text CDB-Size-small u-actionTextColor u-upperCase" type="button" data-action="add">\n      '+
((__t=( _t('editor.legend.legend-form.add') ))==null?'':_.escape(__t))+
'\n    </button>\n  </div>\n</div>';
}
return __p;
};
