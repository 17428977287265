var _ = require('underscore');
module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<div class="WidgetList-option">\n  <input class="CDB-Radio u-iBlock js-radio" type="radio" ';
 if (isSelected) { 
__p+='checked="checked"';
 } 
__p+=' />\n  <span class="u-iBlock CDB-Radio-face"></span>\n</div>\n\n<div class="WidgetList-inner js-inner">\n  <h3 class="WidgetList-title CDB-Text CDB-Size-large u-bSpace--xl">'+
((__t=( _t('components.modals.add-widgets.time-series-no-option-title') ))==null?'':_.escape(__t))+
'</h3>\n  <p class="CDB-Text CDB-Size-small u-altTextColor">'+
((__t=( _t('components.modals.add-widgets.time-series-no-option-desc') ))==null?'':_.escape(__t))+
'</p>\n</div>\n';
}
return __p;
};
