var _ = require('underscore');
module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<div class="Publish-modalHeader">\n  <div class="u-inner">\n    <div class="CDB-Text CDB-Size-huge is-light u-ellipsis u-bSpace--m">'+
((__t=( name ))==null?'':_.escape(__t))+
'</div>\n    <ul class="u-flex u-alignCenter">\n      <li class="js-dropdown u-rSpace--xl"></li>\n      ';
 if (hasShareStats) { 
__p+='\n      <li class="js-share-users">\n      </li>\n      ';
 } 
__p+='\n      <li class="CDB-Text CDB-Size-medium u-altTextColor js-update">\n      </li>\n  </div>\n</div>\n\n<div class="Publish-modalBody js-panes\n';
 if (isSimple) { 
__p+='is-simple';
 } 
__p+='\n">\n  ';
 if (isSimple) { 
__p+='\n    <div class="Publish-modalShadow"></div>\n  ';
 } 
__p+='\n\n</div>\n\n<div class="Dialog-footer Dialog-footer--expanded CreateDialog-footer Publish-modalFooter">\n  <div>\n    <div class="CreateDialog-footerShadow"></div>\n    <div class="CreateDialog-footerLine"></div>\n    <div class="CreateDialog-footerInner ">\n      <div class="CreateDialog-footerInfo"></div>\n      <div class="CreateDialog-footerActions js-footerActions u-flex u-justifySpace u-grow">\n        <div class="js-upgrade"></div>\n        <button class="CDB-Button CDB-Button--secondary js-done">\n          <span class="CDB-Button-Text CDB-Text is-semibold CDB-Size-medium u-upperCase">'+
((__t=( _t('components.modals.publish.done-btn') ))==null?'':_.escape(__t))+
'</span>\n        </button>\n      </div>\n    </div>\n  </div>\n</div>';
}
return __p;
};
