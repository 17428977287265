var _ = require('underscore');
module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<div class="u-flex u-alignCenter">\n  <button class="js-undo">\n    <i class="CDB-IconFont CDB-IconFont-undo Size-large u-actionTextColor js-theme ';
 if (!canUndo) { 
__p+='is-disabled';
 } 
__p+='"></i>\n  </button>\n  <button class="u-lSpace--xl js-redo">\n    <i class="CDB-IconFont CDB-IconFont-redo Size-large u-actionTextColor js-theme ';
 if (!canRedo) { 
__p+='is-disabled';
 } 
__p+='"></i>\n  </button>\n  ';
 if (canClear) { 
__p+='\n  <button class="u-lSpace--xl CDB-Button CDB-Button--secondary CDB-Button--white js-clear">\n    <span class="CDB-Button-Text CDB-Text is-semibold CDB-Size-small">'+
((__t=( _t('components.undo-redo.clear') ))==null?'':_.escape(__t))+
'</span>\n  </button>\n  ';
 } 
__p+='\n  ';
 if (canApply) { 
__p+='\n  <button class="u-lSpace--xl CDB-Button CDB-Button--primary js-apply">\n    <span class="CDB-Button-Text CDB-Text is-semibold CDB-Size-small">'+
((__t=( _t('components.undo-redo.apply') ))==null?'':_.escape(__t))+
'</span>\n  </button>\n  ';
 } 
__p+='\n</div>\n';
}
return __p;
};
