var _ = require('underscore');
module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<ul class="Editor-dropdownCalculations CDB-Text is-semibold">\n  <li class="Editor-dropdownCalculationsElement CDB-Fieldset">\n    <input class="CDB-Radio" type="radio" name="operator" value="count" ';
 if (operator === 'count') { 
__p+='checked';
 } 
__p+='>\n    <span class="u-iBlock CDB-Radio-face"></span>\n    <label class="u-iBlock u-lSpace">'+
((__t=( _t('operators.count') ))==null?'':_.escape(__t))+
'</label>\n  </li>\n  <li class="Editor-dropdownCalculationsElement CDB-Fieldset">\n    <input class="CDB-Radio" type="radio" name="operator" value="sum" ';
 if (operator === 'sum') { 
__p+='checked';
 } 
__p+='>\n    <span class="u-iBlock CDB-Radio-face"></span>\n    <label class="u-iBlock u-lSpace">'+
((__t=( _t('operators.sum') ))==null?'':_.escape(__t))+
'</label>\n  </li>\n  <li class="Editor-dropdownCalculationsElement CDB-Fieldset">\n    <input class="CDB-Radio" type="radio" name="operator" value="avg" ';
 if (operator === 'avg') { 
__p+='checked';
 } 
__p+='>\n    <span class="u-iBlock CDB-Radio-face"></span>\n    <label class="u-iBlock u-lSpace">'+
((__t=( _t('operators.avg') ))==null?'':_.escape(__t))+
'</label>\n  </li>\n  <li class="Editor-dropdownCalculationsElement CDB-Fieldset">\n    <input class="CDB-Radio" type="radio" name="operator" value="max" ';
 if (operator === 'max') { 
__p+='checked';
 } 
__p+='>\n    <span class="u-iBlock CDB-Radio-face"></span>\n    <label class="u-iBlock u-lSpace">'+
((__t=( _t('operators.max') ))==null?'':_.escape(__t))+
'</label>\n  </li>\n  <li class="Editor-dropdownCalculationsElement CDB-Fieldset">\n    <input class="CDB-Radio" type="radio" name="operator" value="min" ';
 if (operator === 'min') { 
__p+='checked';
 } 
__p+='>\n    <span class="u-iBlock CDB-Radio-face"></span>\n    <label class="u-iBlock u-lSpace">'+
((__t=( _t('operators.min') ))==null?'':_.escape(__t))+
'</label>\n  </li>\n</ul>\n<div class="js-list"></div>\n';
}
return __p;
};
