var _ = require('underscore');
module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<span class="Filters-separator"></span>\n\n<div class="Filters-row">\n  <div class="Filters-group">\n    <div class="Filters-typeItem Filters-typeItem--searchEnabler js-search-enabler">\n      <button class="Filters-searchLink CDB-Text is-semibold u-upperCase CDB-Size-medium js-search-link">\n        <i class="Filters-searchLinkIcon CDB-IconFont CDB-IconFont-lens"></i>'+
((__t=( _t('components.modals.add-layer.navigation.search') ))==null?'':_.escape(__t))+
'\n      </button>\n    </div>\n\n    <ul class="Filters-group js-links-list">\n      <li class="Filters-typeItem js-filter-type">\n        <button class="Filters-typeLink js-typeItem u-actionTextColor CDB-Text is-semibold u-upperCase CDB-Size-medium js-connect '+
((__t=( listingType === 'import' ? 'is-selected' : '' ))==null?'':_.escape(__t))+
' '+
((__t=( !canCreateDataset ? 'is-disabled' : '' ))==null?'':_.escape(__t))+
'">\n          '+
((__t=( _t('components.modals.add-layer.navigation.connect-dataset') ))==null?'':_.escape(__t))+
'\n        </button>\n      </li>\n      ';
 if (showDatasets) { 
__p+='\n        <li class="Filters-typeItem js-filter-type">\n          <button class="Filters-typeLink js-typeItem u-actionTextColor CDB-Text is-semibold u-upperCase CDB-Size-medium js-datasets '+
((__t=( listingType === 'datasets' && shared !== 'only' && !library ? 'is-selected' : '' ))==null?'':_.escape(__t))+
'">\n            ';
 if (totalItems) { 
__p+='\n              <strong>'+
((__t=( totalItems ))==null?'':_.escape(__t))+
'</strong>\n            ';
 } 
__p+='\n            '+
((__t=( _t('components.modals.add-layer.navigation.dataset-pluralize', { smart_count: totalItems }) ))==null?'':_.escape(__t))+
'\n          </button>\n        </li>\n        ';
 if (isInsideOrg) { 
__p+='\n          <li class="Filters-typeItem js-filter-type">\n            <button class="Filters-typeLink js-typeItem u-actionTextColor CDB-Text is-semibold u-upperCase CDB-Size-medium js-shared '+
((__t=( listingType === 'datasets' && shared === "only" ? 'is-selected' : '' ))==null?'':_.escape(__t))+
'">\n              ';
 if (totalShared) { 
__p+='\n                <strong>'+
((__t=( totalShared ))==null?'':_.escape(__t))+
'</strong>\n              ';
 } 
__p+='\n              '+
((__t=( _t('components.modals.add-layer.navigation.shared-with-you') ))==null?'':_.escape(__t))+
'\n            </button>\n          </li>\n        ';
 } 
__p+='\n      ';
 } 
__p+='\n      <li class="Filters-typeItem js-filter-type">\n        <button class="Filters-typeLink js-typeItem u-actionTextColor CDB-Text is-semibold u-upperCase CDB-Size-medium js-library '+
((__t=( listingType === 'datasets' && library ? 'is-selected' : '' ))==null?'':_.escape(__t))+
'">\n          '+
((__t=( _t('components.modals.add-layer.navigation.data-library') ))==null?'':_.escape(__t))+
'\n        </button>\n      </li>\n    </ul>\n  </div>\n\n  <div class="Filters-typeItem Filters-typeItem--searchField js-search-field">\n    <form class="Filters-searchForm js-search-form">\n      <input class="Filters-searchInput CDB-Text CDB-Size-medium js-search-input" type="text" value="'+
((__t=( ( tag && (':' + tag) ) || q ))==null?'':_.escape(__t))+
'" placeholder="'+
((__t=( _t('components.modals.add-layer.navigation.search-placeholder') ))==null?'':_.escape(__t))+
'" />\n      ';
 if (tag || q) { 
__p+='\n        <button type="button" class="Filters-cleanSearch js-clean-search u-actionTextColor">\n          <i class="CDB-IconFont CDB-IconFont-close"></i>\n        </button>\n      ';
 } 
__p+='\n    </form>\n  </div>\n\n  <div class="Filters-addLayer js-order-list">\n    <button class="Filters-typeLink js-typeItem u-actionTextColor CDB-Text is-semibold u-upperCase CDB-Size-medium js-create-empty '+
((__t=( listingType === 'scratch' ? 'is-selected' : '' ))==null?'':_.escape(__t))+
' '+
((__t=( !canCreateDataset ? 'is-disabled' : '' ))==null?'':_.escape(__t))+
'">\n      '+
((__t=( _t('components.modals.add-layer.navigation.create-empty-' + createModelType) ))==null?'':_.escape(__t))+
'\n    </button>\n  </div>\n</div>\n';
}
return __p;
};
