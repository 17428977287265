var _ = require('underscore');
module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<div class="Privacy-dropdown">\n  <button class="Privacy-dropdownTrigger Tag Tag-fill Tag-fill--'+
((__t=( cssClass ))==null?'':_.escape(__t))+
' CDB-Text CDB-Size-small u-upperCase js-toggle">\n    ';
 if (isLoading) { 
__p+='\n      <div class="CDB-LoaderIcon CDB-LoaderIcon--small u-flex">\n        <svg class="CDB-LoaderIcon-spinner" viewBox="0 0 50 50">\n          <circle class="CDB-LoaderIcon-path" cx="25" cy="25" r="20" fill="none"></circle>\n        </svg>\n      </div>\n    ';
 } else { 
__p+='\n      '+
((__t=( privacy ))==null?'':_.escape(__t))+
'\n    ';
 } 
__p+='\n  </button>\n\n  <div class="js-dialog"></div>\n</div>\n';
}
return __p;
};
