var _ = require('underscore');
module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<form>\n  <div class="Editor-HeaderInfo Editor-HeaderInfo--noMargin">\n    <div class="Editor-HeaderNumeration CDB-Text is-semibold u-rSpace--m">3</div>\n    <div class="Editor-HeaderInfo-inner CDB-Text" data-fields="'+
((__t=( parametersDataFields ))==null?'':__t)+
'">\n      <div class="Editor-HeaderInfo-title u-bSpace--m">\n        <h2 class="CDB-Text CDB-HeaderInfo-titleText CDB-Size-large">'+
((__t=( _t('analyses.connect-with-lines.title') ))==null?'':_.escape(__t))+
'</h2>\n      </div>\n      <p class="CDB-Text u-upperCase CDB-FontSize-small u-altTextColor u-bSpace--xl">'+
((__t=( _t('editor.layers.analysis-form.define-reference-and-target') ))==null?'':_.escape(__t))+
'</p>\n    </div>\n  </div>\n\n  ';
 if (type == 'line-sequential') { 
__p+='\n    <div class="Editor-HeaderInfo">\n      <div class="Editor-HeaderPad u-rSpace--m"></div>\n        <div class="Editor-checker" data-fields="category_column"></div>\n      </div>\n    </div>\n  ';
 } 
__p+='\n\n  ';
 if (type == 'line-source-to-target') { 
__p+='\n    <div class="Editor-HeaderInfo">\n      <div class="Editor-HeaderPad u-rSpace--m"></div>\n      <div class="Editor-HeaderInfo-inner CDB-Text">\n\n        <div class="Editor-checker Editor-checker--slim u-flex u-alignCenter" data-fields="group"></div>\n\n        ';
 if (group) { 
__p+='\n          <div class="Editor-formInner--nested">\n            <div class="CDB-Text Editor-formInner Editor-formInner--noMargin">\n              <p class="CDB-Legend u-upperCase u-iBlock CDB-Text is-semibold CDB-Size-small u-rSpace--m">'+
((__t=( _t('editor.layers.analysis-form.source-col') ))==null?'':_.escape(__t))+
'</p>\n              <div class="Editor-formInput" data-editors="source_column"></div>\n            </div>\n\n            <div class="CDB-Text Editor-formInner">\n              <p class="CDB-Legend u-upperCase u-iBlock CDB-Text is-semibold CDB-Size-small u-rSpace--m">'+
((__t=( _t('editor.layers.analysis-form.target-col') ))==null?'':_.escape(__t))+
'</p>\n              <div class="Editor-formInput" data-editors="target_source_column"></div>\n            </div>\n          </div>\n        ';
 } 
__p+='\n      </div>\n    </div>\n  ';
 } 
__p+='\n</form>\n';
}
return __p;
};
