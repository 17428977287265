module.exports={
  "number": {
    "quantifications": {
      "items": ["quantiles", "jenks", "equal", "headtails"],
      "defaultIndex": 0
    },
    "bins": {
      "items": ["2", "3", "4", "5", "6", "7"],
      "defaultIndex": 3
    }
  },
  "color": {
    "quantifications": {
      "items": ["jenks", "equal", "headtails", "quantiles", "category"],
      "defaultIndex": 0
    }
  },
  "color-ramps": {
    "quantifications": {
      "items": ["quantiles", "jenks", "equal", "headtails", "category"],
      "defaultIndex": 0
    },
    "bins": {
      "items": ["2", "3", "4", "5", "6", "7"],
      "defaultIndex": 3
    }
  }
}
