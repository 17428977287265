var _ = require('underscore');
module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<button class=\'CDB-Text u-actionTextColor js-fix-sql\'>'+
((__t=( label ))==null?'':_.escape(__t))+
'</button>';
}
return __p;
};
