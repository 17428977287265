var _ = require('underscore');
module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='';
 if (type === 'vertical') { 
__p+='\n<div class="ScrollView-shadow ScrollView-shadow--top js-topShadow"></div>\n<div class="ScrollView-shadow ScrollView-shadow--bottom js-bottomShadow"></div>\n';
 } else { 
__p+='\n<div class="Carousel-shadow Carousel-shadow--left js-leftShadow"></div>\n<div class="Carousel-shadow Carousel-shadow--right js-rightShadow"></div>\n';
 } 
__p+='\n<div class="ScrollView-wrapper js-wrapper">\n  <div class="ScrollView-content js-content"></div>\n</div>\n';
}
return __p;
};
