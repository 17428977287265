var _ = require('underscore');
module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<li class="HorizontalBlockList-item is-add">\n  <div class="HorizontalBlockList-item-actionBlock js-add-analysis" data-layer-id="'+
((__t=( layerId ))==null?'':_.escape(__t))+
'">\n    <div class="CDB-Shape">\n      <div class="CDB-Shape-add is-blue is-small"></div>\n    </div>\n  </div>\n</li>\n';
}
return __p;
};
