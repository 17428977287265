var _ = require('underscore');
module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<div class="Dialog-header">\n  <div class="Dialog-headerIcon Dialog-headerIcon--neutral">\n    <i class="CDB-IconFont CDB-IconFont-download"></i>\n  </div>\n  <h2 class="CDB-Text CDB-Size-large u-bSpace">\n    '+
((__t=( _t('editor.maps.export.download.title') ))==null?'':_.escape(__t))+
'\n  </h2>\n  <h3 class="CDB-Text CDB-Size-medium u-altTextColor">\n    '+
((__t=( _t('editor.maps.export.download.tip') ))==null?'':_.escape(__t))+
'</br>\n    '+
((__t=( _t('editor.maps.export.download.desc') ))==null?'':_.escape(__t))+
'\n  </h3>\n</div>\n<div class="Dialog-footer--simple u-inner">\n  <button class="CDB-Button CDB-Button--primary u-tSpace--m js-download">\n    <span class="CDB-Button-Text CDB-Text is-semibold CDB-Size-medium u-upperCase">\n      '+
((__t=( _t('editor.maps.export.download.confirm') ))==null?'':_.escape(__t))+
'\n    </span>\n  </button>\n</div>\n';
}
return __p;
};
