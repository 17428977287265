var _ = require('underscore');
module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<h3 class="CDB-Text CDB-Size-large u-mainTextColor u-secondaryTextColor u-bSpace--m">\n  ';
 if (state === 'selected') { 
__p+='\n    '+
((__t=( _t('components.modals.add-layer.imports.header-import.type-selected', { brand: 'ArcGIS<sup>&trade;</sup>' }) ))==null?'':__t)+
'\n  ';
 } else { 
__p+='\n    '+
((__t=( _t('components.modals.add-layer.imports.header-import.type-import', { brand: 'ArcGIS<sup>&trade;</sup>' }) ))==null?'':__t)+
'\n  ';
 } 
__p+='\n</h3>\n<p class="CDB-Text CDB-Size-medium u-altTextColor">\n  ';
 if (state !== "selected") { 
__p+='\n    '+
((__t=( _t('components.modals.add-layer.imports.arcgis.import-data', { brand: 'ArcGIS<sup>&trade;</sup>' }) ))==null?'':__t)+
'\n  ';
 } else { 
__p+='\n    '+
((__t=( _t('components.modals.add-layer.imports.arcgis.sync-options') ))==null?'':_.escape(__t))+
'\n  ';
 } 
__p+='\n</p>\n';
 if (state === "selected") { 
__p+='\n  <button class="NavButton NavButton--back ImportPanel-headerButton js-back">\n    <i class="CDB-IconFont CDB-IconFont-arrowPrev"></i>\n  </button>\n';
 } 
__p+='\n';
}
return __p;
};
