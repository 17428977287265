var _ = require('underscore');
module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<div class="Editor-HeaderInfoEditor">\n  <div class="u-rSpace--xl u-actionTextColor js-back Editor-HeaderInfoEditorShape">\n    <button>\n      <i class="CDB-IconFont CDB-IconFont-arrowPrev Size-large"></i>\n    </button>\n  </div>\n  <div class="Editor-HeaderInfo-inner">\n    <div class="Editor-HeaderInfo-title js-context-menu">\n      <div class="Editor-HeaderInfo-titleText js-header"></div>\n    </div>\n\n    <div class="u-flex">\n      <span class="SelectorLayer-letter CDB-Text CDB-Size-small u-whiteTextColor u-rSpace u-upperCase"\n        style="background-color: '+
((__t=( sourceColor ))==null?'':_.escape(__t))+
';">'+
((__t=( source ))==null?'':_.escape(__t))+
'</span>\n      <p class="CDB-Text CDB-Size-medium u-altTextColor u-ellipsis">'+
((__t=( layerName ))==null?'':_.escape(__t))+
'</p>\n    </div>\n  </div>\n</div>\n';
}
return __p;
};
