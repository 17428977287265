var _ = require('underscore');
module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<p class="CDB-Text Onboarding-headerDescription">\n  '+
((__t=( _t('analyses-onboarding.new-columns-included') ))==null?'':_.escape(__t))+
'\n</p>\n\n<ul class="Onboarding-list">\n  <li class="Onboarding-listItem">\n    <div class="Onboarding-listItemValue">trend</div>\n    <p class="CDB-Text Onboarding-description">'+
((__t=( _t('analyses-onboarding.spatial-markov-trend.trend') ))==null?'':_.escape(__t))+
'</p>\n  </li>\n  <li class="Onboarding-listItem">\n    <div class="Onboarding-listItemValue">trend_up</div>\n    <p class="CDB-Text Onboarding-description">'+
((__t=( _t('analyses-onboarding.spatial-markov-trend.trend_up') ))==null?'':_.escape(__t))+
'</p>\n  </li>\n  <li class="Onboarding-listItem">\n    <div class="Onboarding-listItemValue">trend_down</div>\n    <p class="CDB-Text Onboarding-description">'+
((__t=( _t('analyses-onboarding.spatial-markov-trend.trend_down') ))==null?'':_.escape(__t))+
'</p>\n  </li>\n  <li class="Onboarding-listItem">\n    <div class="Onboarding-listItemValue">volatility</div>\n    <p class="CDB-Text Onboarding-description">'+
((__t=( _t('analyses-onboarding.spatial-markov-trend.volatility') ))==null?'':_.escape(__t))+
'</p>\n  </li>\n</ul>\n\n<p class="CDB-Text Onboarding-description">\n  '+
((__t=( _t('analyses-onboarding.spatial-markov-trend.description') ))==null?'':_.escape(__t))+
'\n</p>\n\n<p class="CDB-Text Onboarding-description">\n  <a href="https://github.com/CartoDB/crankshaft/blob/develop/doc/04_markov.md" class="Onboarding-readMore" target="_blank">'+
((__t=( _t('analyses-onboarding.read-more') ))==null?'':_.escape(__t))+
'</a>\n</p>\n';
}
return __p;
};
