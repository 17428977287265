var _ = require('underscore');
module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<div class="u-flex u-alignCenter Modal-basemapContainer">\n  <h3 class="CDB-Text CDB-Size-large u-mainTextColor Modal-titleBasemap">'+
((__t=( _t('components.modals.add-basemap.xyz.insert') ))==null?'':_.escape(__t))+
'</h3>\n  <div class="CDB-Text u-flex u-alignCenter">\n    <label class="Metadata-label Metadata-label--auto  CDB-Text CDB-Size-small is-semibold u-upperCase u-ellipsis">'+
((__t=( _t('components.modals.add-basemap.xyz.enter') ))==null?'':_.escape(__t))+
'</label>\n    <div class="Form-rowData Form-rowData--longer">\n      <input type="text" class="has-icon CDB-InputText CDB-Text js-url" value="" placeholder="E.g. https://{s}.carto.com/foobar/{z}/{x}/{y}.png">\n      <i class="Spinner XYZPanel-inputIcon XYZPanel-inputIcon--loader Spinner--formIcon Form-inputIcon js-validating" style="display: none;"></i>\n      <div class="Checkbox XYZPanel-inputCheckbox js-tms" data-title="Inverts Y axis numbering for tiles">\n        <button class="Checkbox-input u-rSpace--m"></button>\n        <label class="CDB-Text CDB-Size-small is-semibold u-upperCase">'+
((__t=( _t('components.modals.add-basemap.xyz.tms') ))==null?'':_.escape(__t))+
'</label>\n      </div>\n      <div class="XYZPanel-error CDB-InfoTooltip CDB-InfoTooltip--left is-error CDB-Text CDB-Size-medium CDB-InfoTooltip-text js-error"></div>\n    </div>\n  </div>\n</div>\n';
}
return __p;
};
