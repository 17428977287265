var _ = require('underscore');
module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<div class="u-flex u-justifyCenter">\n  <div class="Modal-inner Modal-inner--grid u-flex u-justifyCenter">\n    <div class="Modal-icon">\n      <svg width="26" height="26" viewBox="0 0 26 26" xmlns="http://www.w3.org/2000/svg">\n        <path d="M1 25l2.262-6.787 16.59-16.59c.832-.83 2.185-.83 3.016 0l1.508 1.51c.83.83.83 2.182 0 3.015l-16.59 16.59L1 25zM21.36 9.165L16.835 4.64l4.525 4.525zM7.787 22.738l-4.525-4.525 4.525 4.525z" stroke="#F19243" fill="none" fill-rule="evenodd"/>\n      </svg>\n    </div>\n    <div>\n      <h2 class=" CDB-Text CDB-Size-huge is-light u-bSpace--m">'+
((__t=( _t('components.modals.edit-feature.confirmation.title') ))==null?'':_.escape(__t))+
'</h2>\n      <p class="CDB-Text CDB-Size-medium u-altTextColor">'+
((__t=( _t('components.modals.edit-feature.confirmation.desc') ))==null?'':_.escape(__t))+
'</p>\n      <ul class="Modal-listActions u-flex u-alignCenter">\n        <li class="Modal-listActionsitem">\n          <button class="CDB-Button CDB-Button--secondary CDB-Button--big js-cancel">\n            <span class="CDB-Button-Text CDB-Text is-semibold CDB-Size-medium u-upperCase">\n              '+
((__t=( _t('components.modals.edit-feature.confirmation.cancel') ))==null?'':_.escape(__t))+
'\n            </span>\n          </button>\n        </li>\n        <li class="Modal-listActionsitem">\n          <button class="CDB-Button CDB-Button--primary CDB-Button--big js-confirm">\n            <span class="CDB-Button-Text CDB-Text is-semibold CDB-Size-medium u-upperCase">'+
((__t=( _t('components.modals.edit-feature.confirmation.continue') ))==null?'':_.escape(__t))+
'</span>\n          </button>\n        </li>\n      </ul>\n    </div>\n  </div>\n</div>\n';
}
return __p;
};
