var _ = require('underscore');
module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<i class=\'TabIcon is-'+
((__t=( name ))==null?'':_.escape(__t))+
'\'></i>\n<span>'+
((__t=( cdb.core.sanitize.html(title || name) ))==null?'':__t)+
'</span>\n';
}
return __p;
};
