var _ = require('underscore');
module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<div class="ImportPanel-header">\n  <h3 class="CDB-Text CDB-Size-large u-mainTextColor u-secondaryTextColor u-bSpace--m">'+
((__t=( _t('components.modals.add-layer.imports.form-import.title') ))==null?'':_.escape(__t))+
'</h3>\n  <p class="ImportPanel-headerDescription">'+
((__t=( _t('components.modals.add-layer.imports.form-import.desc') ))==null?'':_.escape(__t))+
'</p>\n</div>\n<div class="ImportPanel-body">\n  <div class="ImportPanel-bodyWrapper">\n    <div class="ImportPanel-state ImportPanel-form is-idle"></div>\n    <div class="ImportPanel-state is-selected DatasetSelected"></div>\n  </div>\n</div>\n';
}
return __p;
};
