var _ = require('underscore');
module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<div class="Infobox '+
((__t=( type ))==null?'':_.escape(__t))+
'">\n  <h2 class="CDB-Text is-semibold CDB-Size-small u-bSpace--m u-upperCase">'+
((__t=( title ))==null?'':_.escape(__t))+
'</h2>\n  <div class="CDB-Text CDB-Size-medium u-bSpace--xl u-flex">\n    ';
 if (isLoading) { 
__p+='\n      <div class="CDB-LoaderIcon is-dark u-rSpace--m">\n        <svg class="CDB-LoaderIcon-spinner" viewBox="0 0 50 50">\n          <circle class="CDB-LoaderIcon-path" cx="25" cy="25" r="20" fill="none"></circle>\n        </svg>\n      </div>\n    ';
 } 
__p+='\n    <div>\n      '+
((__t=( body ))==null?'':__t)+
'\n    </div>\n  </div>\n\n  <div class="u-flex u-justifySpace u-alignCenter">\n    ';
 if (hasQuota) { 
__p+='\n      <div class="Infobox-quota js-quota"></div>\n    ';
 } 
__p+='\n    ';
 if (hasButtons) { 
__p+='\n      <ul class="Infobox-buttons">\n        <li class="Infobox-button js-leftPosition"></li>\n        <li class="Infobox-button Infobox-button--right js-rightPosition"></li>\n      </ul>\n    ';
 } 
__p+='\n  </div>\n</div>\n';
}
return __p;
};
