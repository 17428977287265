var _ = require('underscore');
module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<span data-action="'+
((__t=( button ))==null?'':_.escape(__t))+
'" class="CDB-Text is-semibold u-lSpace u-actionTextColor u-upperCase">'+
((__t=( button ))==null?'':_.escape(__t))+
'</span>';
}
return __p;
};
