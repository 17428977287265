var _ = require('underscore');
module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<p class="CDB-Text CDB-Size-medium u-altTextColor">\n  "'+
((__t=( quote ))==null?'':__t)+
'"\n</p>\n';
 if (author) { 
__p+='\n  <p class="CDB-Text CDB-Size-medium u-altTextColor u-tSpace"><i>– '+
((__t=( author ))==null?'':_.escape(__t))+
'</i></p>\n';
 } 
__p+='\n';
}
return __p;
};
