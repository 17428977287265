var _ = require('underscore');
module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='';
 if (typeof isLoading != 'undefined' && isLoading) { 
__p+='\n  <div class="u-flex">\n    <div class="CDB-LoaderIcon CDB-LoaderIcon--small is-dark">\n      <svg class="CDB-LoaderIcon-spinner" viewBox="0 0 50 50">\n        <circle class="CDB-LoaderIcon-path" cx="25" cy="25" r="20" fill="none"></circle>\n      </svg>\n    </div>\n    <span class="u-lSpace u-secondaryTextColor">'+
((__t=( _t('components.backbone-forms.select.loading') ))==null?'':_.escape(__t))+
'</span>\n  </div>\n';
 } else { 
__p+='\n  ';
 if (typeof type != 'undefined' && type === 'node') { 
__p+='\n    <div class="u-flex">\n      <span\n        class="SelectorLayer-letter CDB-Text CDB-Size-small u-whiteTextColor u-rSpace u-upperCase"\n        style="background-color: '+
((__t=( color ))==null?'':_.escape(__t))+
';">'+
((__t=( val ))==null?'':_.escape(__t))+
'</span>\n        <p class="CDB-Text CDB-Size-medium u-ellipsis u-flex" title="'+
((__t=( nodeTitle ))==null?'':_.escape(__t))+
' - '+
((__t=( layerName ))==null?'':_.escape(__t))+
'">\n          '+
((__t=( nodeTitle ))==null?'':_.escape(__t))+
' <span class="u-altTextColor u-lSpace u-ellipsis">'+
((__t=( layerName ))==null?'':_.escape(__t))+
'</span>\n        </p>\n    </div>\n  ';
 } else { 
__p+='\n    '+
((__t=( label ))==null?'':_.escape(__t))+
'\n  ';
 } 
__p+='\n';
 } 
__p+='\n';
}
return __p;
};
