var _ = require('underscore');
module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<div class="Editor-ListAnalysis-itemInfo u-rSpace--m CDB-Text is-semibold CDB-Size-small u-upperCase" style="background: '+
((__t=( bgColor ))==null?'':_.escape(__t))+
'; color: #fff">\n  ';
 if (isDone) { 
__p+='\n    <span class="CDB-Text u-rSpace">\n      '+
((__t=( id ))==null?'':_.escape(__t))+
'\n    </span>\n    <i class="CDB-IconFont CDB-IconFont-ray CDB-Size-medium"></i>\n  ';
 } else { 
__p+='\n    <div class="CDB-LoaderIcon">\n      <svg class="CDB-LoaderIcon-spinner" viewBox="0 0 50 50">\n        <circle class="CDB-LoaderIcon-path" cx="25" cy="25" r="20" fill="none"></circle>\n      </svg>\n    </div>\n  ';
 } 
__p+='\n</div>\n<p class="Editor-ListAnalysis-title CDB-Text CDB-Size-small u-secondaryTextColor u-ellipsis" title="'+
((__t=( title ))==null?'':_.escape(__t))+
'">'+
((__t=( title ))==null?'':_.escape(__t))+
'</p>\n\n';
 if (hasError) { 
__p+='\n<div class="Editor-ListAnalysis-itemError"></div>\n';
 } 
__p+='\n';
}
return __p;
};
