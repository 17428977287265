var _ = require('underscore');
module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<button type="button" class="CDB-ListDecoration-itemLink\n  ';
 if (isSelected) { 
__p+=' is-selected ';
 } 
__p+=' ';
 if (isDestructive) { 
__p+='  u-alertTextColor ';
 } else { 
__p+=' u-actionTextColor ';
 } 
__p+='"\n  title="'+
((__t=( nodeTitle ))==null?'':_.escape(__t))+
' - '+
((__t=( layerName ))==null?'':_.escape(__t))+
'">\n  <div class="u-flex">\n    <span class="SelectorLayer-letter CDB-Text CDB-Size-small u-whiteTextColor u-rSpace u-upperCase" style="background-color: '+
((__t=( color ))==null?'':_.escape(__t))+
';">'+
((__t=( layer_id ))==null?'':_.escape(__t))+
'</span>\n    <p class="CDB-Text CDB-Size-medium u-ellipsis u-flex">\n      '+
((__t=( nodeTitle ))==null?'':_.escape(__t))+
' <span class="u-altTextColor u-lSpace u-ellipsis">'+
((__t=( layerName ))==null?'':_.escape(__t))+
'</span>\n    </p>\n  </div>\n</button>\n';
}
return __p;
};
