var _ = require('underscore');
module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<div class="BlockList-media u-rSpace--m js-widgetIcon">\n</div>\n<div class="BlockList-inner u-ellipsis">\n  <div class="BlockList-title u-bSpace js-context-menu">\n    <div class="BlockList-titleText js-header"></div>\n  </div>\n  <div class="u-flex">\n    <span\n      class="SelectorLayer-letter CDB-Text CDB-Size-small u-whiteTextColor u-rSpace u-upperCase"\n      style="background-color: '+
((__t=( sourceColor ))==null?'':_.escape(__t))+
';">'+
((__t=( source ))==null?'':_.escape(__t))+
'</span>\n    <p class="CDB-Text CDB-Size-medium u-altTextColor u-ellipsis">'+
((__t=( layerName ))==null?'':_.escape(__t))+
'</p>\n  </div>\n</div>\n';
}
return __p;
};
