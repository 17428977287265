var _ = require('underscore');
module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<span class=\'CDB-Text is-semibold\'>'+
((__t=( progress ))==null?'':_.escape(__t))+
'% '+
((__t=( state ))==null?'':_.escape(__t))+
'</span> '+
((__t=( name ))==null?'':_.escape(__t))+
' ';
 if (service && service != "twitter_search") { 
__p+=' '+
((__t=( _t('components.background-importer.background-importer-item.from') ))==null?'':_.escape(__t))+
' '+
((__t=( service ))==null?'':_.escape(__t))+
' ';
 } 
__p+='';
}
return __p;
};
