var _ = require('underscore');
module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<h2 class="CDB-Text CDB-Size-huge is-light u-secondaryTextColor">'+
((__t=( _t('editor.widgets.widgets-form.placeholder-text') ))==null?'':_.escape(__t))+
'</h2>\n<div class="BlockList-item is-dashed no-hover is-space u-tSpace-xl">\n  <div class="WidgetPlaceHolder">\n    <div class="WidgetPlaceHolder-media"></div>\n    <div class="WidgetPlaceHolder-txt">\n        <h2 class="WidgetPlaceHolder-title"></h2>\n        <p class="WidgetPlaceHolder-text"></p>\n    </div>\n  </div>\n  <button class="CDB-Button CDB-Button--primary js-add">\n    <span class="CDB-Button-Text CDB-Text is-semibold CDB-Size-small u-upperCase">'+
((__t=( _t('editor.widgets.widgets-view.add_widget') ))==null?'':_.escape(__t))+
'</span>\n  </button>\n</div>\n';
}
return __p;
};
