var _ = require('underscore');
module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<div class="IntermediateInfo">\n  <div class="LayoutIcon">\n    <i class="CDB-IconFont '+
((__t=( icon ))==null?'':_.escape(__t))+
'"></i>\n  </div>\n  <h4 class="CDB-Text CDB-Size-large u-mainTextColor u-secondaryTextColor u-bSpace--m u-tSpace-xl">'+
((__t=( title ))==null?'':_.escape(__t))+
'</h4>\n  <p class="CDB-Text CDB-Size-medium u-altTextColor">\n    '+
((__t=( desc ))==null?'':_.escape(__t))+
'\n  </p>\n</div>\n';
}
return __p;
};
