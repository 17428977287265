var _ = require('underscore');
module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<div class="CDB-Box-modalHeader">\n  <ul class="CDB-Box-modalHeaderItem CDB-Box-modalHeaderItem--block CDB-Box-modalHeaderItem--paddingHorizontal">\n    <li class="CDB-ListDecoration-item CDB-ListDecoration-itemPadding--vertical CDB-Text CDB-Size-medium u-secondaryTextColor u-flex u-alignCenter">\n      <ul class="ColorBarContainer">\n        ';
 _.each(range, function (color) { 
__p+='\n          <li class="ColorBar ColorBar--spaceless" style="background-color: '+
((__t=( color ))==null?'':_.escape(__t))+
';"></li>\n        ';
 }); 
__p+='\n      </ul>\n    </li>\n  </ul>\n</div>\n<div class="js-content"></div>\n';
}
return __p;
};
