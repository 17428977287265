var _ = require('underscore');
module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<div class="Modal">\n  <div class="Modal-header">\n    <div class="Modal-headerContainer">\n      <h2 class="CDB-Text CDB-Size-huge is-light u-mainTextColor u-bSpace">'+
((__t=( _t('components.modals.add-widgets.modal-title') ))==null?'':_.escape(__t))+
'</h2>\n      <h3 class="CDB-Text CDB-Size-medium u-altTextColor">'+
((__t=( _t('components.modals.add-widgets.modal-desc') ))==null?'':_.escape(__t))+
'</h3>\n    </div>\n  </div>\n  <div class="Modal-container js-body">\n    \n  </div>\n  <div class="Modal-footer">\n    <div class="Modal-footerContainer u-flex u-justifyEnd">\n      <button class="CDB-Button CDB-Button--primary is-disabled js-continue">\n        <span class="CDB-Button-Text CDB-Text is-semibold CDB-Size-medium u-upperCase">'+
((__t=( _t('components.modals.add-widgets.continue-btn') ))==null?'':_.escape(__t))+
'</span>\n      </button>\n    </div>\n  </div>\n</div>\n';
}
return __p;
};
