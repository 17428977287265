var _ = require('underscore');
module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<div class="CDB-Text Editor-formInner">\n  ';
 if (title) { 
__p+='\n    <label class="CDB-Legend ';
 if (editorType){ 
__p+=' CDB-Legend--'+
((__t=( editorType ))==null?'':_.escape(__t))+
'';
 } 
__p+=' u-upperCase CDB-Text is-semibold CDB-Size-small u-rSpace--m" for="'+
((__t=( editorId ))==null?'':_.escape(__t))+
'" title="'+
((__t=( title ))==null?'':_.escape(__t))+
'">\n      <div class="u-ellipsis ';
 if (help) { 
__p+='Editor-formHelp';
 } 
__p+='">\n        <span class="';
 if (help) { 
__p+=' js-help is-underlined';
 } 
__p+='" ';
 if (help) { 
__p+=' data-tooltip="'+
((__t=( help ))==null?'':_.escape(__t))+
'"';
 } 
__p+=' >'+
((__t=( title ))==null?'':_.escape(__t))+
'</span>\n      </div>\n    </label>\n  ';
 } 
__p+='\n  <div class="Editor-formInput u-flex u-alignCenter" data-editor>\n    ';
 if (isCopyButtonEnabled) { 
__p+='\n      <button type="button" class="Share-copy CDB-Button CDB-Button--small js-copy" data-clipboard-target="#'+
((__t=( editorId ))==null?'':_.escape(__t))+
'">\n        <span class="CDB-Button-Text CDB-Text CDB-Size-small u-actionTextColor">'+
((__t=( _t('components.backbone-forms.copy-button') ))==null?'':_.escape(__t))+
'</span>\n      </button>\n    ';
 } 
__p+='\n  </div>\n</div>\n';
}
return __p;
};
