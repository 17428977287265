var _ = require('underscore');
module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<h2 class="CDB-Text CDB-Size-huge is-light u-secondaryTextColor">'+
((__t=( _t('editor.style.style-form.properties.placeholder-text') ))==null?'':_.escape(__t))+
'</h2>\n';
}
return __p;
};
