var _ = require('underscore');
module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<div class="Modal-navigation">\n  <ul class="Modal-navigationInner CDB-Text is-semibold CDB-Size-medium js-tabs">\n    ';
 model.get('tabs').each(function(tab) { 
__p+='\n      <li class="CDB-NavMenu-item '+
((__t=( model.get('currentTab') === tab.get('name') ? 'is-selected' : '' ))==null?'':_.escape(__t))+
'">\n        <button data-name="'+
((__t=( tab.get('name') ))==null?'':_.escape(__t))+
'" class="CDB-NavMenu-link u-upperCase">\n          '+
((__t=( tab.get('label') ))==null?'':_.escape(__t))+
'\n        </button>\n      </li>\n    ';
 }) 
__p+='\n  </ul>\n</div>\n<div class="Modal-inner Modal-inner--with-navigation js-tab-content"></div>\n';
}
return __p;
};
