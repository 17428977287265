var _ = require('underscore');
module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<div class="Dialog-header">\n  <div class="Dialog-headerIcon Dialog-headerIcon--negative u-flex u-alignCenter u-justifyCenter">\n    <i class="CDB-IconFont CDB-IconFont-cloud"></i>\n  </div>\n  <h2 class="CDB-Text CDB-Size-large u-bSpace u-errorTextColor">\n    '+
((__t=( title ))==null?'':_.escape(__t))+
' ';
 if (errorCode) { 
__p+='('+
((__t=( errorCode ))==null?'':_.escape(__t))+
')';
 } 
__p+='\n  </h2>\n  <h3 class="CDB-Text CDB-Size-medium u-secondaryTextColor">\n    ';
 if (itemQueueId) { 
__p+='\n    '+
((__t=( _t('components.background-importer.error-details.dont-panic') ))==null?'':_.escape(__t))+
'\n    ';
 } else { 
__p+='\n    '+
((__t=( _t('components.background-importer.error-details.check-errors') ))==null?'':_.escape(__t))+
'\n    ';
 } 
__p+='\n  </h3>\n</div>\n\n<div class="Dialog-body ErrorDetails-body">\n  <ul class="Modal-containerList">\n    ';
 if (httpResponseCode) { 
__p+='\n      <li class="ErrorDetails-item">\n        <div class="ErrorDetails-itemStep CDB-Text CDB-Size-medium is-semibold u-flex u-alignCenter u-justifyCenter">1</div>\n        <div class="ErrorDetails-itemText">\n          <p class="CDB-Text CDB-Size-medium">\n            '+
((__t=( _t('components.background-importer.error-details.remote-server-code', { httpResponseCode: httpResponseCode}) ))==null?'':__t)+
' '+
((__t=( httpResponseCodeMessage ))==null?'':_.escape(__t))+
'\n          </p>\n        </div>\n      </li>\n      <li class="ErrorDetails-item">\n        <div class="ErrorDetails-itemStep CDB-Text CDB-Size-medium is-semibold u-flex u-alignCenter u-justifyCenter">2</div>\n        <div class="ErrorDetails-itemText">\n          <p class="CDB-Text CDB-Size-medium">\n            '+
((__t=( _t('components.background-importer.error-details.check-url') ))==null?'':_.escape(__t))+
':<br/>\n          </p>\n          <span class=\'CDB-Text CDB-Size-medium ErrorDetails-itemTextStrong\'><a href="'+
((__t=( originalUrl ))==null?'':_.escape(__t))+
'">'+
((__t=( originalUrl ))==null?'':_.escape(__t))+
'</a></span>\n        </div>\n      </li>\n    ';
 } else { 
__p+='\n      <li class="ErrorDetails-item">\n        <div class="ErrorDetails-itemStep CDB-Text CDB-Size-medium is-semibold u-flex u-alignCenter u-justifyCenter">1</div>\n        <div class="ErrorDetails-itemText">\n          <p class="CDB-Text CDB-Size-medium">\n            ';
 if (text) { 
__p+='\n            '+
((__t=( cdb.core.sanitize.html(text) ))==null?'':__t)+
'\n            ';
 } else { 
__p+='\n            '+
((__t=( _t('components.background-importer.error-details.unknown-error') ))==null?'':_.escape(__t))+
'\n            ';
 } 
__p+='\n          </p>\n        </div>\n      </li>\n    ';
 } 
__p+='\n    ';
 if (itemQueueId) { 
__p+='\n      <li class="ErrorDetails-item">\n        <div class="ErrorDetails-itemStep CDB-Text CDB-Size-medium is-semibold u-flex u-alignCenter u-justifyCenter">!</div>\n        <div class="ErrorDetails-itemText">\n          <p class="CDB-Text CDB-Size-medium">\n            '+
((__t=( _t('components.background-importer.error-details.send-us-the-error-code') ))==null?'':__t)+
':<br/>\n          </p>\n          <span class="CDB-Text CDB-Size-medium ErrorDetails-itemTextStrong">'+
((__t=( itemQueueId ))==null?'':_.escape(__t))+
'</span>\n        </div>\n      </li>\n    ';
 } 
__p+='\n  </ul>\n</div>\n\n\n<div class="Dialog-footer--simple u-inner">\n  <button class="CDB-Button CDB-Button--error u-tSpace--m js-close">\n    <span class="CDB-Button-Text CDB-Text is-semibold CDB-Size-medium u-upperCase">\n      '+
((__t=( _t('components.background-importer.error-details.close') ))==null?'':_.escape(__t))+
'\n    </span>\n  </button>\n</div>';
}
return __p;
};
