var _ = require('underscore');
module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<div class="AssetItem-label CDB-Text CDB-Size-big ';
 if (type === 'icon') { 
__p+='u-mainTextColor';
 } else { 
__p+='u-actionTextColor';
 } 
__p+=' u-upperCase js-asset" title="'+
((__t=( name ))==null?'':_.escape(__t))+
'">\n  ';
 if (type === 'icon') { 
__p+='\n    <img height="'+
((__t=( height ))==null?'':_.escape(__t))+
'" src="'+
((__t=( public_url ))==null?'':_.escape(__t))+
'" alt="'+
((__t=( name ))==null?'':_.escape(__t))+
'" />\n  ';
 } else { 
__p+='\n    '+
((__t=( name ))==null?'':_.escape(__t))+
'\n  ';
 } 
__p+='\n</div>\n';
}
return __p;
};
