var _ = require('underscore');
module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+=''+
((__t=( _t('components.background-importer.background-importer-item.error-connecting', { name: name }) ))==null?'':__t)+
' ';
 if (service) { 
__p+=' '+
((__t=( _t('components.background-importer.background-importer-item.from') ))==null?'':_.escape(__t))+
' '+
((__t=( service ))==null?'':_.escape(__t))+
' ';
 } 
__p+='';
}
return __p;
};
