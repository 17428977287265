var _ = require('underscore');
module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<div class="u-iBlock CDB-Text CDB-Size-medium u-rSpace--xl">\n  <input class="CDB-Radio" type="radio" name="value" value="true" ';
 if (value === 'true') { 
__p+='checked';
 } 
__p+='>\n  <span class="u-iBlock CDB-Radio-face"></span>\n  <label class="u-iBlock u-lSpace">True</label>\n</div>\n<div class="u-iBlock CDB-Text CDB-Size-medium u-rSpace--xl">\n  <input class="CDB-Radio" type="radio" name="value" value="false" ';
 if (value === 'false') { 
__p+='checked';
 } 
__p+='>\n  <span class="u-iBlock CDB-Radio-face"></span>\n  <label class="u-iBlock u-lSpace">False</label>\n</div>\n<div class="u-iBlock CDB-Text CDB-Size-medium u-rSpace--xl">\n  <input class="CDB-Radio" type="radio" name="value" value="null" ';
 if (value === 'null') { 
__p+='checked';
 } 
__p+='>\n  <span class="u-iBlock CDB-Radio-face"></span>\n  <label class="u-iBlock u-lSpace">Null</label>\n</div>\n';
}
return __p;
};
