var _ = require('underscore');
module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<div class="EditorMenu js-editorMenu">\n  <a class="EditorMenu-logo" href="'+
((__t=( url ))==null?'':_.escape(__t))+
'">\n    <svg width="32px" height="32px" viewBox="762 -58 32 32" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">\n      <g class="imago" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd" transform="translate(762.000000, -58.000000)">\n        <circle class="Halo" fill="#FFFFFF" opacity="0.2" cx="16" cy="16" r="16"></circle>\n        <circle class="point" fill="#FFFFFF" cx="16" cy="16" r="5"></circle>\n        <g class="arrow" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd" transform="translate(11.000000, 16.000000)">\n          <rect id="Rectangle-32" fill="#1181FB" x="0" y="0" width="10" height="1"></rect>\n          <rect class="arrowTop" fill="#1181FB" x="0" y="0" width="6" height="1"></rect>\n          <rect class="arrowBottom" fill="#1181FB" x="0" y="0" width="6" height="1"></rect>\n        </g>\n      </g>\n    </svg>\n  </a>\n  <ul class="EditorMenu-navigation js-menu"></ul>\n</div>\n<div class="Editor-panelWrapper">\n  <div class="js-content"></div>\n</div>\n';
}
return __p;
};
