var _ = require('underscore');
module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='';
 if (importState !== "twitter") { 
__p+='\n  <div class="u-iBlock CDB-Text CDB-Size-medium u-rSpace--xl js-toggle">\n    <input class="CDB-Checkbox" type="checkbox" '+
((__t=( isGuessingEnabled ? 'checked' : '' ))==null?'':_.escape(__t))+
'>\n    <span class="u-iBlock CDB-Checkbox-face"></span>\n    <label class="u-iBlock u-lSpace">'+
((__t=( _t('components.modals.add-layer.footer.guessing-desc') ))==null?'':_.escape(__t))+
'</label>\n  </div>\n';
 } else if (!customHosted) { 
__p+='\n  <span class="CDB-Text CDB-Size-medium">\n    '+
((__t=( _t('components.modals.add-layer.footer.twitter-desc') ))==null?'':_.escape(__t))+
'\n    <a href="mailto:support@carto.com">'+
((__t=( _t('components.modals.add-layer.footer.contact-team') ))==null?'':_.escape(__t))+
'</a>\n  </span>\n';
 } 
__p+='\n';
}
return __p;
};
