var _ = require('underscore');
module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<div class="StatsList-item">\n  <div class="u-rSpace--m">\n    <input class="CDB-Checkbox js-checkbox" type="checkbox" ';
 if (isSelected) { 
__p+='checked="checked"';
 } 
__p+=' />\n    <span class="u-iBlock CDB-Checkbox-face"></span>\n  </div>\n\n  <div class="WidgetList-inner js-inner">\n    <div class="StatsList-header u-flex u-justifySpace u-alignCenter u-bSpace--m">\n      <h3 class="u-ellipsis CDB-Text CDB-Size-medium">'+
((__t=( _t('editor.data.stats.add-widget') ))==null?'':_.escape(__t))+
'</h3>\n      ';
 if (isSelected) { 
__p+='\n      <button class="StatsList-style CDB-Text CDB-Size-small js-style u-actionTextColor">\n        <div class="StatsList-arrow CDB-Shape-Arrow is-blue u-iBlock  u-rSpace--m"></div> '+
((__t=( _t('editor.data.stats.edit') ))==null?'':_.escape(__t))+
'\n      </button>\n      ';
 } 
__p+='\n    </div>\n    <div class="u-flex u-alignCenter u-bSpace">\n      <h2 class="js-title u-ellipsis CDB-Text CDB-Size-large u-rSpace--m">'+
((__t=( column ))==null?'':_.escape(__t))+
'</h2>\n      <div class="StatsList-tag Tag Tag--outline Tag-outline--grey CDB-Text CDB-Size-small u-upperCase">'+
((__t=( type ))==null?'':_.escape(__t))+
'</div>\n    </div>\n    <div class="js-stat"></div>\n  </div>\n</div>\n';
}
return __p;
};
